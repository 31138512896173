<template>
  <div class="page-layout">
    <header-client-view></header-client-view>

    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <div class="container withdrawal-wrapper">
        <div class="moneybox">
          <!--          <h1 class="moneybox__header text-center">Wypłać środki</h1>-->
          <h1 class="diet-configuration__header text-uppercase text-center">
            Wypłać środki
          </h1>

          <div
            class="moneybox__paragraph moneybox__withdrawal-container white-background p-4"
          >
            <p>Wypłacone środki trafią na konto, z którego zostały wpłacone</p>

            <p>
              Do wypłaty max.: <b>{{ walletVal }} zł</b>
            </p>

            <form class="row withdrawal-form" @submit.prevent="pay">
              <div>
                <div class="row form-line">
                  <div class="custom-input__wrapper">
                    <input
                      type="text"
                      :class="[
                        'form-control',
                        value !== '' ? 'hide-placeholder' : '',
                        validateInput ? '' : 'insufficient-funds'
                      ]"
                      pattern="^\d+\.{0,1}\d{0,2}$"
                      v-model="value"
                      @input="validateNumber"
                    />
                    <span class="custom-input__placeholder">0</span>
                    <span class="custom-input__error-msg"
                      >Nie posiadasz wystarczających środków</span
                    >
                  </div>
                  <div class="pln">
                    <span>zł</span>
                  </div>
                </div>
              </div>
              <div class="moneybox__withdrawal-btn-wrapper">
                <button
                  :disabled="value === '' || !validateInput"
                  class="btn btn-custom"
                  type="submit"
                >
                  WYPŁAĆ
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '@/components/HeaderClientView'
import FooterClientView from '@/components/FooterClientView'
import * as actionTypes from '@/store/actions_types'
import * as ActionTypes from '@/store/actions_types'
import * as RouteCollection from '@/router/route_collection'
import MarginMixin from '../../../helpers/mixins'

export default {
  components: { HeaderClientView, FooterClientView },
  mixins: [MarginMixin],
  data() {
    return {
      value: ''
    }
  },
  watch: {
    value: function (val) {
      if (val.charAt(0) === '0' || val.charAt(0) === '.') {
        this.value = val.slice(1)
      }

      let fractionalPart = this.value.split('.')[1]

      if (fractionalPart.length > 2) {
        this.value = val.slice(0, -1)
      }
    }
  },
  computed: {
    client() {
      return this.$store.getters.getClient
    },
    walletVal() {
      return this.$store.getters.jwtDecoded.wallet_value || 0
    },
    validateInput() {
      return this.value <= this.walletVal
    }
  },
  async mounted() {
    await this.$store.dispatch(ActionTypes.FETCH_CLIENT_DATA)
  },
  methods: {
    pay() {
      this.$store
        .dispatch(actionTypes.FETCH_MONEYBOX_WITHDRAWAL, {
          accountNumber: '000000',
          firstName: this.client.firstName,
          lastName: this.client.lastName,
          value: parseFloat(this.value)
        })
        .then(this.showSuccessMessage)
        .catch(this.showFailureMessage)
    },
    showSuccessMessage() {
      window.dataLayer.push({
        event: 'moneybox',
        eventCategory: 'Moneybox',
        eventAction: 'Withdrawal',
        eventLabel: this.value
      })

      this.$swal({
        type: 'success',
        customClass: 'modal__replenishment modal__withdrawal',
        title: 'SUKCES',
        showCloseButton: true,
        text: 'Środki dotrą na Twoje konto najpóźniej w ciągu 7 dni roboczych.',
        confirmButtonText: 'ZAMKNIJ'
      }).then(() => {
        this.$router.push({ name: RouteCollection.UserMoneyboxIndex })
      })
    },
    showFailureMessage() {
      window.dataLayer.push({
        event: 'moneybox',
        eventCategory: 'Moneybox',
        eventAction: 'Withdrawal_fail'
      })

      this.$swal({
        type: 'success',
        customClass: 'modal__replenishment modal__withdrawal failure',
        title: 'PRZEPRASZAMY',
        showCloseButton: true,
        html:
          'W tym momencie nie możemy wykonać tej czynności. <span>Spróbuj ponownie później.</span>',
        confirmButtonText: 'ZAMKNIJ'
      })
    },
    validateNumber(event) {
      const pattern = /^\d+\.{0,1}\d{0,2}$/
      if (!pattern.test(event.target.value)) {
        this.value = this.value.slice(0, -1)
      }
    }
  }
}
</script>
