import axios from '../../interceptor'
import * as types from '../../store/mutations_types'
import store from '../../store'
import * as RouteCollection from '../../router/route_collection'
import router from '../../router'
import routes from './routes'
import auth from './auth'
import sentry from '../../sentry'

export default {
  create() {
    store.commit(types.ORDER_CHANGE_ERRORS, [])

    axios
      .post(routes.createOrder, {
        data: JSON.stringify(store.getters.getConfigurator)
      })
      .then(response => {
        store.commit(types.ORDER_CHANGE_LAST_ORDER, response.data)
        auth.refresh()

        if (response.data.paymentStatus.systemValue === 'ORDER_PAID') {
          router.push({ name: RouteCollection.OrderHistory })
        } else {
          router.push({
            name: RouteCollection.OrderConfirm,
            params: { id: response.data.id }
          })
        }
      })
      .catch(error => {
        sentry.captureException(error)

        switch (error.response.status) {
          case 422:
            store.commit(types.ORDER_CHANGE_ERRORS, error.response.data.errors)
            router.push({ name: RouteCollection.OrderCreate })
            break

          default:
            store.commit(types.AUTH_ERROR_CHANGE, 'Wystąpił jakiś błąd')
        }
      })
  },
  fetchOrder(id) {
    return axios.get(routes.fetchOrder.replace(':id', id))
  },
  fetchOrderPaymentDetails(id) {
    return axios.get(routes.fetchOrderPaymentDetails.replace(':id', id))
  },
  fetchDiets(page) {
    return axios.get(routes.fetchDiets + '?page=' + page)
  },
  fetchOrders(page) {
    return axios.get(routes.fetchOrders + '?page=' + page)
  },
  cancelOrder(id) {
    return axios.patch(routes.cancelOrderById(id))
  }
}
