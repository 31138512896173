import dayjs from 'dayjs'

export default {
  myData:
    process.env.VUE_APP_API_URL + '/frontend/secure/client/info-about-me.json',
  updateMyData:
    process.env.VUE_APP_API_URL + '/frontend/secure/client/update-my-data.json',
  updateMarketingAgreement:
    process.env.VUE_APP_API_URL +
    '/frontend/secure/client/update-marketing-agreement.json',
  updateNeedFvAndNip:
    process.env.VUE_APP_API_URL +
    '/frontend/secure/client/update-fv-and-nip.json',
  changePassword:
    process.env.VUE_APP_API_URL +
    '/frontend/secure/client/update-my-password.json',
  configurator: process.env.VUE_APP_API_URL + '/frontend/configurator.json',
  calendar: (dietId, date) =>
    process.env.VUE_APP_API_URL +
    '/frontend/secure/calendar/' +
    dietId +
    '/' +
    date +
    '.json',
  calendarCheckDate: (date, length) =>
    process.env.VUE_APP_API_URL +
    `/frontend/secure/calendar-check-date/${date}/${length}.json`,
  bagDetails: bagId =>
    process.env.VUE_APP_API_URL +
    '/frontend/secure/bag/details/' +
    bagId +
    '.json',
  fetchBadges: mealIds => {
    const url = new URL('v5/meals', process.env.VUE_APP_API_URL)
    url.searchParams.append('id__in', mealIds.join(','))
    url.searchParams.append('expansions__in', 'meal_badge_id')
    return url.href
  },
  bagUpdate: bagId =>
    process.env.VUE_APP_API_URL + '/frontend/secure/bag/update/' + bagId,
  bagRating: bagId =>
    process.env.VUE_APP_API_URL +
    '/frontend/secure/bag/rate/' +
    bagId +
    '.json',
  changeBagDeliveryTime: (bagId, date) =>
    process.env.VUE_APP_API_URL +
    '/frontend/secure/bag/change-delivery-time/' +
    bagId +
    '/' +
    date +
    '.json',
  firstDeliveryDate:
    process.env.VUE_APP_API_URL + '/frontend/secure/first-delivery-config.json',
  checkDiscountCode:
    process.env.VUE_APP_API_URL + '/frontend/check-discount-code.json',
  checkDiscountCodeAvailability:
    process.env.VUE_APP_API_URL + '/frontend/check-discount-code',
  dietPrice: process.env.VUE_APP_API_URL + '/frontend/diet-price.json',
  selectedPackage: bagId =>
    process.env.VUE_APP_API_URL + `/frontend/secure/diet/${bagId}/package`,
  mealsPrice: date =>
    process.env.VUE_APP_API_URL + '/frontend/secure/diet/prices/' + date,
  addMealsToDietDay:
    process.env.VUE_APP_API_URL +
    '/frontend/secure/order/checkout/added-elements',
  registration: process.env.VUE_APP_API_URL + '/frontend/registration.json',
  facebook: process.env.VUE_APP_API_URL + '/frontend/facebook.json',
  moneyboxHistory:
    process.env.VUE_APP_API_URL +
    '/frontend/secure/client/moneybox-history.json',
  fetchMoneyboxHistory:
    process.env.VUE_APP_API_URL +
    '/v4/secure/client/fetch-moneybox-history.json',
  moneyboxWithdrawal:
    process.env.VUE_APP_API_URL + '/frontend/wallet/withdrawal',
  moneyboxReplenishment:
    process.env.VUE_APP_API_URL + '/frontend/wallet/payment',
  tagsComingSource:
    process.env.VUE_APP_API_URL + '/frontend/tags/coming-source.json',
  login: process.env.VUE_APP_API_URL + '/api/login_check',
  config: process.env.VUE_APP_API_URL + '/frontend/config',
  refreshTocken: process.env.VUE_APP_API_URL + '/api/token/refresh',
  createOrder: process.env.VUE_APP_API_URL + '/frontend/secure/order/create',
  cancelOrderById: id =>
    process.env.VUE_APP_API_URL +
    '/frontend/secure/order/' +
    id +
    '/cancelation.json',
  setOrderPaymentType: id =>
    process.env.VUE_APP_API_URL +
    '/frontend/secure/order/' +
    id +
    '/payment/type.json',
  fetchOrder: process.env.VUE_APP_API_URL + '/frontend/secure/order/:id',
  fetchOrderPaymentDetails:
    process.env.VUE_APP_API_URL + '/frontend/secure/order/:id/payment/details',
  fetchOrders:
    process.env.VUE_APP_API_URL + '/frontend/secure/order/fetch-history',
  fetchDiets:
    process.env.VUE_APP_API_URL + '/frontend/secure/diet/fetch-history',
  remindPassword: process.env.VUE_APP_API_URL + '/frontend/remind-password',
  tokenChangePassword:
    process.env.VUE_APP_API_URL + '/frontend/change-password',
  allergenReport: (brand, dateFrom) =>
    process.env.VUE_APP_API_URL +
    '/frontend/secure/allergen-report/' +
    brand +
    '/' +
    dateFrom,
  deactivate: process.env.VUE_APP_API_URL + '/v3/account/deactivate',
  addressCheck: process.env.VUE_APP_API_URL + '/v3/addresses/check',
  addressPackageLeavingOptions:
    process.env.VUE_APP_API_URL + '/v3/address/package-leaving-options',
  fetchAddresses: process.env.VUE_APP_API_URL + '/v3/addresses',
  fetchAddressPackageLeavingOptions:
    process.env.VUE_APP_API_URL + '/v3/address/package-leaving-options',
  fetchAddressUnderTheDoorTypeOptions:
    process.env.VUE_APP_API_URL + '/v3/dictionary/under_the_door_type',
  deleteAddress: id => process.env.VUE_APP_API_URL + `/v3/addresses/${id}`,
  createAddress: process.env.VUE_APP_API_URL + `/v3/addresses`,
  updateAddress: id => process.env.VUE_APP_API_URL + `/v3/addresses/${id}`,
  addressHasPlannedDeliveries: id =>
    process.env.VUE_APP_API_URL + `/v3/addresses/has-planned-deliveries/${id}`,
  bagDelivery: bag => process.env.VUE_APP_API_URL + `/v3/bag/${bag}/delivery`,
  updateAddressForPlannedDeliveries: id => {
    const today = new dayjs(new Date()).format('YYYY-MM-DD')

    return process.env.VUE_APP_API_URL + `/v3/addresses/${id}/${today}/true`
  },
  updateAddressForDateAndDiet: (address, date, diet) => {
    return (
      process.env.VUE_APP_API_URL +
      `/v3/addresses/${address}/date/${date}/diet/${diet}`
    )
  }
}
