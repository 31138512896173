<template>
  <div
    class="plg-ni_menu_planner_user_form"
    v-if="typeof bagDetails === 'object' && bagDetails !== null"
  >
    <form class="calendar_meals">
      <div class="mb-8">
        <h2 class="header-2">{{ deliveryDay }}</h2>
        <p class="text-center">{{ deliveryDate }}</p>
      </div>

      <template v-if="day !== null && !$store.getters.isLoadingDayDetails">
        <day-expired-form v-if="isExpiredDay"></day-expired-form>

        <day-rate-form
          v-else-if="canRate"
          :day="day"
          :bag-datails="bagDetails"
        ></day-rate-form>

        <day-select-menu-form
          v-else
          :day="day"
          :show-change-delivery-date="showChangeDeliveryDate"
          :bag-datails="bagDetails"
          :diet-variant="selectedDietDefaultVariantName"
          @showChangeDeliveryDateUpdated="showChangeDeliveryDate = $event"
        ></day-select-menu-form>
      </template>

      <template v-else>
        <h4 class="labelSmall">Proszę czekać...</h4>
      </template>
    </form>

    <menu-planner-address
      :key="JSON.stringify(bagDetails.address)"
      v-if="!showChangeDeliveryDate && !isExpiredDay"
      :address="bagDetails.address"
      :deliveryDate="deliveryDate"
      :bag="bagDetails.bag.id"
      :selectedDiet="selectedDiet"
      :isDeliveryChangePossible="isDeliveryChangePossible"
      :deliveryDay="deliveryDay"
    ></menu-planner-address>
  </div>
</template>

<script>
import MenuPlannerAddress from '../components/MenuPlannerAddress'
import DayExpiredForm from './MenuPlanner/DayExpiredForm'
import DaySelectMenuForm from './MenuPlanner/DaySelectMenuForm'
import DayRateForm from './MenuPlanner/DayRateForm'

export default {
  components: {
    MenuPlannerAddress,
    DayExpiredForm,
    DaySelectMenuForm,
    DayRateForm
  },
  name: 'MenuPlannerForm',
  props: {
    selectedDiet: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showChangeDeliveryDate: false
    }
  },
  computed: {
    deliveryDate: function () {
      return this.$date(this.bagDetails.deliveryDate).format('YYYY-MM-DD')
    },
    day: function () {
      return this.$store.getters.getSelectedDay
    },
    bag: function () {
      return this.$store.getters.getSelectedBag
    },
    deliveryDay: function () {
      return this.$date(this.bagDetails.deliveryDate).format('dddd')
    },
    bagDetails: function () {
      return this.$store.getters.getBagDetails
    },
    isExpiredDay: function () {
      return this.day.isRated === true || !this.bagDetails.canVisibleMeals
    },
    canRate: function () {
      return (
        this.bagDetails.canRate &&
        this.dateGt(this.bagDetails.canRateTo) &&
        !this.canChangeMenu
      )
    },
    canChangeMenu: function () {
      return (
        this.bagDetails.canChangeMenu &&
        this.dateGt(this.bagDetails.canChangeMenuTo)
      )
    },
    selectedDietDefaultVariantName() {
      return this.selectedDiet ? this.selectedDiet.defaultVariant.name : null
    },
    isDeliveryChangePossible() {
      return (
        this.bagDetails !== null &&
        typeof this.bagDetails !== 'undefined' &&
        this.bagDetails.canChangeDeliveryTime.possible
      )
    }
  },
  methods: {
    dateGt(a, b = this.$date().unix()) {
      return this.$date(a).unix() > b
    }
  }
}
</script>

<style>
.change-delivery-date-picker .vdp-datepicker__calendar {
  width: 100%;
}

.change-delivery-date-picker span.cell {
  margin: 1px;
  width: 48px;
}

.change-delivery-date-picker span.day-header {
  font-weight: bold;
}

.change-delivery-date-picker span.day {
  border: 1px solid #424242;
}

.change-delivery-date-picker span.cell.disabled,
.change-delivery-date-picker span.cell.blank {
  opacity: 0.35;
}
</style>
