<template>
  <div class="component-dialog" v-if="value">
    <div class="component-dialog-background">
      <div class="component-dialog-content">
        <div>
          <i
            class="fa fa-close component-dialog-close"
            aria-hidden="true"
            @click="close"
          />
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentDialog',
  props: {
    value: {
      type: Boolean
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>
.component-dialog-close {
  position: absolute;
  top: 34px;
  right: 30px;
  cursor: pointer;
  z-index: 10001;
}
.component-dialog {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.component-dialog-background {
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
}
.component-dialog-content > div {
  position: relative;
}
.component-dialog-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  overflow-y: auto;
}
@media (max-width: 992px) {
  .component-dialog-content .container {
    width: 100vw;
  }
}
</style>
