<template>
  <div class="page-layout">
    <header-client-view></header-client-view>

    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <div class="container">
        <OrderStep @next="save" />
        <!--        <header-h1 :title="$t('Moje dane')"></header-h1>-->

        <section
          class="prices container mobile_fix white-background p-4 mt-8"
          id="prices"
        >
          <form
            method="POST"
            accept-charset="UTF-8"
            class="plg-ni_order_register_userData-form"
            @submit="save"
          >
            <!-- data -->
            <div class="row">
              <div class="col-md-6 m-b-20">
                <label class="labelInput">Imię: *</label>
                <input
                  title="Imię"
                  class="form-control"
                  name="first_name"
                  type="text"
                  v-model="userData.firstName"
                />
                <p
                  class="subTitle text-danger plg-ni_order_register_userData-first_name-error"
                >
                  {{ getErrors('firstName') }}
                </p>
              </div>
              <div class="col-md-6 m-b-20">
                <label class="labelInput">Nazwisko: *</label>
                <input
                  title="Imię"
                  class="form-control"
                  name="last_name"
                  type="text"
                  v-model="userData.lastName"
                />
                <p
                  class="subTitle text-danger plg-ni_order_register_userData-first_name-error"
                >
                  {{ getErrors('lastName') }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 m-b-20">
                <label class="labelInput">Telefon: *</label>
                <div class="telephone-input__wrapper">
                  <input
                    class="telephone-input__country-code"
                    name="countryCode"
                    type="text"
                    v-model="countryCallingCode"
                    v-mask="'+(##)'"
                  />
                  <input
                    title="Telefon"
                    id="client_phone"
                    class="telephone-input__phone"
                    name="phone"
                    type="text"
                    v-model="phone"
                    v-mask="'###-###-###'"
                  />
                </div>
                <p
                  class="subTitle text-danger plg-ni_order_register_userData-first_name-error"
                >
                  {{ getErrors('phone') }}
                </p>
              </div>

              <div class="col-md-6 m-b-20">
                <label class="labelInput">Adres e-mail: *</label>
                <input
                  title="E-mail"
                  class="form-control zam"
                  readonly
                  name="email"
                  type="text"
                  v-model="userData.email"
                />
                <p
                  class="subTitle text-danger plg-ni_order_register_userData-first_name-error"
                >
                  {{ getErrors('email') }}
                </p>
              </div>
            </div>

            <div class="row bottomSm hidden">
              <div class="col-md-6 m-b-20">
                <label class="labelInput">Wyrażam zgodę na:</label>
                <label class="checkbox checkboxSmallLabel diet-checkbox">
                  <input
                    title="Zgoda"
                    class="form-control"
                    name="agreement"
                    type="checkbox"
                    disabled
                    value="1"
                    v-model="userData.emailMarketingAgreement"
                  />
                  <span class="box"
                    ><i
                      class="fa fa-check"
                      aria-hidden="true"
                      :class="{ 'fa-check': userData.emailMarketingAgreement }"
                    ></i
                  ></span>
                  Wyrażam zgodę na otrzymywanie nie częściej niż 3 razy w
                  miesiącu, wiadomości o treściach handlowych wysyłanych przez
                  Republika Smakoszy spółka z ograniczoną odpowiedzialnością z
                  siedzibą w Warszawie (05-105) przy ul. Twardej 18.
                </label>
              </div>
            </div>
            <!-- /END data -->
            <OrderBackNext />
          </form>
        </section>
      </div>
    </main>
    <footer-client-view></footer-client-view>
  </div>
</template>
<script>
import HeaderClientView from '@/components/HeaderClientView'
import FooterClientView from '@/components/FooterClientView'
import OrderStep from '@/components/OrderStep'
import UserApi from '@/services/api/user'
import * as RouteCollection from '@/router/route_collection'
import MarginMixin from '../../helpers/mixins'
import {
  validateFields,
  getEmailErrors,
  getPhoneErrors
} from '@/helpers/formValidators'
import OrderBackNext from '@/components/OrderBackNext.vue'
import auth from '@/services/api/auth'
import order from '@/services/api/order'
import * as ActionTypes from '@/store/actions_types'

export default {
  components: { OrderBackNext, HeaderClientView, FooterClientView, OrderStep },
  mixins: [MarginMixin],
  name: 'UserData',
  data() {
    return {
      userData: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        emailMarketingAgreement: false
      },
      errors: {},
      countryCallingCode: '48',
      phone: ''
    }
  },
  async mounted() {
    await this.$store.dispatch(ActionTypes.FETCH_CLIENT_DATA)
    const client = this.$store.getters.getClient

    this.userData.firstName = client.firstName
    this.userData.lastName = client.lastName
    this.userData.email = client.email
    this.userData.emailMarketingAgreement = client.emailMarketingAgreement
    if (client.phone) {
      this.phone = client.phone.replace(/^\+48/, '')
    }

    const gtmData = {
      event: 'checkoutStep',
      dimension3: await this.cartDetails.dimension3,
      dimension4: await this.cartDetails.dimension4,
      dimension5: await this.cartDetails.dimension5,
      dimension6: await this.cartDetails.dimension6,
      metric2: await this.cartDetails.metric2,
      ecommerce: {
        checkout: {
          actionField: {
            step: '1',
            option: await this.cartDetails.coupon_code
          },
          products: await this.cartDetails.products
        }
      }
    }

    await window.dataLayer.push(gtmData)
    // this.$gtm.enable(false)
  },
  computed: {
    cartDetails() {
      return this.$store.getters.getCartDetails
    }
  },
  methods: {
    save() {
      this.errors = validateFields(
        {
          firstName: {
            required: true,
            fieldName: 'imię',
            value: this.userData.firstName
          },
          lastName: {
            required: true,
            fieldName: 'nazwisko',
            value: this.userData.lastName
          },
          email: {
            required: true,
            fieldName: 'email',
            validator: getEmailErrors,
            value: this.userData.email
          },
          phone: {
            required: true,
            fieldName: 'telefon',
            validator: getPhoneErrors,
            value: this.phone
          }
        },
        this
      )

      if (this.errors === undefined) {
        if (this.phone.length > 0) {
          this.userData.phone = `${this.countryCallingCode} ${this.phone}`
        }

        UserApi.updateMyData({
          data: JSON.parse(JSON.stringify(this.userData))
        })
          .then(async () => {
            console.log(this.$store.getters.jwtDecoded)
            await auth.refresh()
            if (!this.$store.getters.jwtDecoded.hasRequiredAddresses) {
              this.$router.push({ name: RouteCollection.OrderAddress })
            } else {
              order.create()
            }
          })
          .catch(errRes => {
            switch (errRes.response.status) {
              case 422:
                this.errors = errRes.response.data.errors
                break
            }
          })
      }
    },
    getErrors(field) {
      if (
        this.errors.hasOwnProperty(field) &&
        Object.values(this.errors[field]).length > 0
      ) {
        return Object.values(this.errors[field])[0]
      }

      return null
    },
    back() {
      this.$router.push({ name: RouteCollection.OrderCreate })
    }
  },
  beforeRouteLeave(to, from, next) {
    const isGoingToCreate = to.name === RouteCollection.OrderCreate
    const isGoingToAddresses = to.name === RouteCollection.OrderAddress
    const isGoingToOrderConfirm = to.name === RouteCollection.OrderConfirm
    if (isGoingToCreate || isGoingToAddresses || isGoingToOrderConfirm) {
      next()
      return
    }
    const answer = window.confirm(
      'Czy na pewno chcesz opuścić stronę bez zapisania zmian?'
    )
    next(answer)
  }
}
</script>

<style scoped>
.container {
  max-width: 1280px;
}

.checkboxSmallLabel {
  font-size: 16px;
}
</style>
