<template>
  <div class="row">
    <div class="col-sm-12">
      <h2 class="title">
        <slot />
      </h2>
    </div>

    <Clearfix />

    <InputFormField
      type="select"
      class="col-sm-6 mb-3"
      required
      v-model="model.deliveryHourTo"
      name="deliveryHourTo"
      label="Time of delivery"
      placeholder="Specify a delivery time"
      :options="deliveryTimes"
    />

    <InputFormField
      class="col-sm-6 mb-3"
      label="Address name"
      name="name"
      placeholder="Specify a name of the address"
      required
      :modify="modifyLimit30"
      :max-length="30"
      v-model="model.name"
    />

    <Clearfix />

    <InputFormField
      class="col-sm-12 mb-3"
      type="select"
      required
      v-model="model.packageLeavingOption"
      name="packageLeavingOption"
      label="Where to leave the package?"
      placeholder="Select location"
      :options="packageLeavingOptions"
    />

    <div
      v-if="!!model.packageLeavingOption.toString()"
      :key="
        'underTheDoorType' + model.underTheDoorType + model.packageLeavingOption
      "
    >
      <div>
        <div class="col-sm-12">
          <h4 class="mb-2">
            <strong>{{ $t('How do we get there?') }}</strong>
          </h4>
          <p class="text-muted mb-3">
            <small
              v-html="
                $t(
                  'Fill in the fields below to make sure the courier gets to you without any problems.'
                )
              "
            />
          </p>
        </div>

        <InputFormField
          v-if="isOtherPackageLeavingOptionRequired"
          required
          :max-length="400"
          type="textarea"
          class="col-sm-12 mb-3"
          label="Note to courier"
          name="otherPackageLeavingOption"
          placeholder="Additional information for the courier"
          v-model="model.otherPackageLeavingOption"
        />

        <div>
          <InputFormField
            class="col-sm-4 mb-3"
            label="Door code"
            name="intercomKey"
            placeholder="Enter a code"
            :required="
              model.underTheDoorType &&
              model.packageLeavingOption &&
              'CODE' === model.underTheDoorType.toString() &&
              UNDER_THE_DOOR_ID.toString() ===
                model.packageLeavingOption.toString()
            "
            v-model="model.intercomKey"
          />

          <InputFormField
            class="col-sm-4 mb-3"
            label="Gate code"
            name="gateKey"
            placeholder="Enter a code"
            v-model="model.gateKey"
          />

          <InputFormField
            class="col-sm-4 mb-3"
            label="Floor"
            name="floorNr"
            placeholder="Enter a floor"
            v-model="model.floorNr"
          />
        </div>

        <InputFormField
          v-if="!isOtherPackageLeavingOptionRequired"
          :max-length="400"
          type="textarea"
          class="col-sm-12 mb-3"
          label="Note to courier"
          name="otherPackageLeavingOption"
          placeholder="Additional information for the courier"
          v-model="model.otherPackageLeavingOption"
        />
      </div>
    </div>
    <div class="col-sm-12" v-else>
      <div class="info-block text-center">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        {{
          $t(
            "Once you've chosen your delivery destination, we'll ask you to give some instructions to the courier"
          )
        }}
      </div>
    </div>
  </div>
</template>

<script>
import InputFormField from '@/components/Address/InputFormField.vue'
import UserApi from '@/services/api/user'
import timesRange from '@/helpers/timesRange'
import * as types from '@/store/mutations_types'
import Clearfix from '@/components/Address/Clearfix.vue'

import {
  LEAVE_PARCEL_RECEPTION,
  OTHER_PACKAGE_LEAVING_OPTION,
  UNDER_THE_DOOR_ID
} from '@/consts/packageLeavingOptions'

export default {
  name: 'UserAddress0',
  components: {
    Clearfix,
    InputFormField
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean
    }
  },
  data() {
    return {
      deliveryTimes: [],
      packageLeavingOptions: [],
      underTheDoorTypeOptions: [],
      UNDER_THE_DOOR_ID,
      OTHER_PACKAGE_LEAVING_OPTION,
      model: this.value
    }
  },
  computed: {
    isOtherPackageLeavingOptionRequired() {
      return (
        OTHER_PACKAGE_LEAVING_OPTION.toString() ===
          this.model.packageLeavingOption.toString() ||
        LEAVE_PARCEL_RECEPTION.toString() ===
          this.model.packageLeavingOption.toString() ||
        (UNDER_THE_DOOR_ID.toString() ===
          this.model.packageLeavingOption.toString() &&
          this.model.underTheDoorType &&
          'OTHER' === this.model.underTheDoorType.toString())
      )
    }
  },
  async beforeMount() {
    this.$store.commit(types.LOADING_START)
    this.$store.commit(types.LOADING_MUTE)
    try {
      const response = await UserApi.addressCheck(
        ['city', 'street', 'buildingNr', 'postCode'].reduce((a, c) => {
          a[c] = this.value[c].toString()
          return a
        }, {})
      )
      this.deliveryTimes = timesRange(response.data.deliveryTimes[0])
      this.$emit('intent', true)
    } catch (errRes) {
      this.$swal({
        customClass: 'oldNtfyModal',
        title: this.$t('warning'),
        showCloseButton: true,
        html: this.$t('No map point found for the specified address')
      })
      this.$emit('intent', false)
      this.$store.commit(types.LOADING_UNMUTE)
      this.$store.commit(types.LOADING_STOP)
      return
    }

    const {
      data: packageLeavingOptions
    } = await UserApi.addressPackageLeavingOptions()

    this.packageLeavingOptions = packageLeavingOptions.map(p => {
      const value = this.$t(p.value)
      return {
        value: '' + p.id,
        label: value.charAt(0).toUpperCase() + value.slice(1)
      }
    })

    this.$store.commit(types.LOADING_UNMUTE)
    const {
      data: underTheDoorTypeOptions
    } = await UserApi.addressUnderTheDoorTypeOptions()
    this.underTheDoorTypeOptions = underTheDoorTypeOptions.map(o => ({
      value: o.systemValue,
      label: o.value
    }))

    this.model = this.value
    this.$store.commit(types.LOADING_STOP)
  },
  methods: {
    modifyLimit30(value) {
      return value.slice(0, 30)
    },
    input(property, value) {
      const model = {
        ...this.model,
        [property]: value
      }

      this.model = model
      this.$emit('input', model)
    }
  }
}
</script>
