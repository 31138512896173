var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('h2',{staticClass:"title"},[_vm._t("default")],2)]),_c('Clearfix'),_c('InputFormField',{staticClass:"col-sm-6 mb-3",attrs:{"type":"select","required":"","name":"deliveryHourTo","label":"Time of delivery","placeholder":"Specify a delivery time","options":_vm.deliveryTimes},model:{value:(_vm.model.deliveryHourTo),callback:function ($$v) {_vm.$set(_vm.model, "deliveryHourTo", $$v)},expression:"model.deliveryHourTo"}}),_c('InputFormField',{staticClass:"col-sm-6 mb-3",attrs:{"label":"Address name","name":"name","placeholder":"Specify a name of the address","required":"","modify":_vm.modifyLimit30,"max-length":30},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('Clearfix'),_c('InputFormField',{staticClass:"col-sm-12 mb-3",attrs:{"type":"select","required":"","name":"packageLeavingOption","label":"Where to leave the package?","placeholder":"Select location","options":_vm.packageLeavingOptions},model:{value:(_vm.model.packageLeavingOption),callback:function ($$v) {_vm.$set(_vm.model, "packageLeavingOption", $$v)},expression:"model.packageLeavingOption"}}),(!!_vm.model.packageLeavingOption.toString())?_c('div',{key:'underTheDoorType' + _vm.model.underTheDoorType + _vm.model.packageLeavingOption},[_c('div',[_c('div',{staticClass:"col-sm-12"},[_c('h4',{staticClass:"mb-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('How do we get there?')))])]),_c('p',{staticClass:"text-muted mb-3"},[_c('small',{domProps:{"innerHTML":_vm._s(
              _vm.$t(
                'Fill in the fields below to make sure the courier gets to you without any problems.'
              )
            )}})])]),(_vm.isOtherPackageLeavingOptionRequired)?_c('InputFormField',{staticClass:"col-sm-12 mb-3",attrs:{"required":"","max-length":400,"type":"textarea","label":"Note to courier","name":"otherPackageLeavingOption","placeholder":"Additional information for the courier"},model:{value:(_vm.model.otherPackageLeavingOption),callback:function ($$v) {_vm.$set(_vm.model, "otherPackageLeavingOption", $$v)},expression:"model.otherPackageLeavingOption"}}):_vm._e(),_c('div',[_c('InputFormField',{staticClass:"col-sm-4 mb-3",attrs:{"label":"Door code","name":"intercomKey","placeholder":"Enter a code","required":_vm.model.underTheDoorType &&
            _vm.model.packageLeavingOption &&
            'CODE' === _vm.model.underTheDoorType.toString() &&
            _vm.UNDER_THE_DOOR_ID.toString() ===
              _vm.model.packageLeavingOption.toString()},model:{value:(_vm.model.intercomKey),callback:function ($$v) {_vm.$set(_vm.model, "intercomKey", $$v)},expression:"model.intercomKey"}}),_c('InputFormField',{staticClass:"col-sm-4 mb-3",attrs:{"label":"Gate code","name":"gateKey","placeholder":"Enter a code"},model:{value:(_vm.model.gateKey),callback:function ($$v) {_vm.$set(_vm.model, "gateKey", $$v)},expression:"model.gateKey"}}),_c('InputFormField',{staticClass:"col-sm-4 mb-3",attrs:{"label":"Floor","name":"floorNr","placeholder":"Enter a floor"},model:{value:(_vm.model.floorNr),callback:function ($$v) {_vm.$set(_vm.model, "floorNr", $$v)},expression:"model.floorNr"}})],1),(!_vm.isOtherPackageLeavingOptionRequired)?_c('InputFormField',{staticClass:"col-sm-12 mb-3",attrs:{"max-length":400,"type":"textarea","label":"Note to courier","name":"otherPackageLeavingOption","placeholder":"Additional information for the courier"},model:{value:(_vm.model.otherPackageLeavingOption),callback:function ($$v) {_vm.$set(_vm.model, "otherPackageLeavingOption", $$v)},expression:"model.otherPackageLeavingOption"}}):_vm._e()],1)]):_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"info-block text-center"},[_c('i',{staticClass:"fa fa-info-circle",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t( "Once you've chosen your delivery destination, we'll ask you to give some instructions to the courier" ))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }