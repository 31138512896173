import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import { state, actions, mutations } from './root'
import auth from './modules/auth'
import configurator from './modules/configurator'
import order from './modules/order'
import diet from './modules/diet'
import moneybox from './modules/moneybox'
import tags from './modules/tags'
import user from './modules/user'
import calendar from './modules/calendar'
import cart_details from './modules/cart_details'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    auth,
    configurator,
    order,
    diet,
    tags,
    user,
    calendar,
    moneybox,
    cart_details
  },
  strict: true
})
