<template>
  <div class="diet-configuration__container">
    <div class="diet-configuration__content-wrapper">
      <h2 class="text-center mb-8">
        Wybierz kaloryczność swojej diety z dostawą do domu
      </h2>

      <div class="box-container">
        <div
          class="radio"
          v-for="basket in $store.getters.getConfiguratorBasketOptions"
          :key="basket.id"
          :style="'width:' + getBoxWith() + '%;'"
        >
          <input
            class="form-control"
            :id="'basket_' + basket.id"
            checked="checked"
            name="calorific"
            type="radio"
            :value="basket.id"
            v-model="selected"
          />
          <label
            :for="'basket_' + basket.id"
            :class="{ selected: selected === basket.id }"
            >{{ basket.name }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '../../store/actions_types'

export default {
  name: 'size',
  computed: {
    selected: {
      get: function () {
        if (this.$store.getters.getConfiguratorBasket === null) {
          this.selected = this.$store.getters.getConfiguratorBasketOptions[0].id // eslint-disable-line
        }

        return this.$store.getters.getConfiguratorBasket
      },
      set: function (newValue) {
        this.$store.dispatch(actionTypes.CHANGE_CONFIGURATOR_BASKET, newValue)
      }
    }
  },
  methods: {
    getBoxWith() {
      return 100 / this.$store.getters.getConfiguratorBasketOptions.length
    }
  }
}
</script>

<style scoped>
label {
  font-family: Helvetica !important;
  font-weight: normal !important;
  color: #222 !important;
}
</style>
