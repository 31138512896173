function FormField(value, validator) {
  if (undefined === validator) {
    this.validator = () => {}
    this.required = false
  } else {
    this.validator = validator
    this.required = true
  }
  this.error = false
  this.value = value
}

FormField.prototype.toString = function () {
  return this.value
}

FormField.prototype.setError = function (error) {
  this.error = error
}

FormField.prototype.getError = function () {
  return this.error
}

FormField.prototype.isRequired = function () {
  return this.required
}

FormField.prototype.assign = function (value) {
  return new FormField(value.toString(), this.validator)
}

FormField.prototype.validate = function () {
  this.validator()
}
export default FormField
