<template>
  <div class="container">
    <div
      class="address-form diet-configuration__container mt-8 mb-8 order_register_steps_offset_top"
    >
      <div class="diet-configuration__content-wrapper">
        <OrderStep @next="next" />

        <br />
        <UserAddress0
          v-model="address"
          :form="form"
          class="mb-3"
          v-if="!this.isDetails"
        >
          {{ $t((isEdit ? 'Edit' : 'Add') + ' delivery address') }}
        </UserAddress0>

        <UserAddress1
          v-model="address"
          class="mb-3"
          v-show="!intentDetails"
          v-if="isDetails || intentDetails"
          @intent="intent"
        >
          {{ $t((isEdit ? 'Edit' : 'Add') + ' address details') }}
        </UserAddress1>

        <CheckboxVertical
          v-if="
            !$store.getters.jwtDecoded.hasRequiredAddresses && !this.isDetails
          "
          class="justify-center mb-3"
          v-model="alt"
          :form="form"
          label="Different delivery address on Saturdays and public holidays"
        />

        <UserAddress0
          v-model="altAddress"
          :form="form"
          class="mb-3"
          v-if="
            !$store.getters.jwtDecoded.hasRequiredAddresses && alt && !isDetails
          "
        >
          {{ $t('Add delivery address on Saturdays and public holidays') }}
        </UserAddress0>

        <UserAddress1
          v-model="altAddress"
          :form="form"
          class="mb-3"
          v-show="!intentDetails"
          v-if="
            !$store.getters.jwtDecoded.hasRequiredAddresses &&
            alt &&
            (isDetails || intentDetails)
          "
        >
          {{ $t('Add address details on Saturdays and public holidays') }}
        </UserAddress1>

        <div class="row">
          <OrderBackNext class="col-sm-12 center-content mt-8" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderStep from '@/components/OrderStep'
import UserApi from '@/services/api/user'
import order from '@/services/api/order'
import { UNDER_THE_DOOR_ID } from '@/consts/packageLeavingOptions'
import {
  UserAddresses as RouteUserAddresses,
  UserData
} from '@/router/route_collection'
import CheckboxVertical from '@/components/CheckboxVertical.vue'
import UserAddress0 from '@/pages/user/UserAddress0.vue'
import UserAddress1 from '@/pages/user/UserAddress1.vue'
import AuthApi from '@/services/api/auth'
import * as types from '@/store/mutations_types'
import OrderBackNext from '@/components/OrderBackNext.vue'
import auth from '@/services/api/auth'

const defaultAddress = {
  street: '',
  name: '',
  buildingNr: '',
  placeNr: '',
  postCode: '',
  city: '',
  deliveryHourTo: '',
  floorNr: '',
  intercomKey: '',
  gateKey: '',
  packageLeavingOption: '',
  otherPackageLeavingOption: '',
  underTheDoorType: 'CODE'
}
export default {
  components: {
    OrderBackNext,
    UserAddress0,
    UserAddress1,
    CheckboxVertical,
    OrderStep
  },
  props: {
    defaultAddress: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  name: 'UserAddress',
  data() {
    return {
      UNDER_THE_DOOR_ID,
      UserAddresses: RouteUserAddresses,
      alt: false,
      isOrder: false,
      isBag: false,
      isEdit: false,
      phone: '',
      errors: {},
      form: {},
      isValid: true,
      address: {
        ...defaultAddress,
        ...this.defaultAddress
      },
      altAddress: {
        ...defaultAddress,
        ...this.defaultAddress
      },
      deliveryTimes: [],
      intentDetails: false,
      packageLeavingOptions: [],
      underTheDoorTypeOptions: []
    }
  },
  async mounted() {
    this.isOrder = this.$route.path.includes('order')
    this.isEdit = this.$route.path.match(/address\/\d+/)
    this.gtm('checkoutStep', {
      checkout: {
        actionField: { step: '2' }
      }
    })

    if (this.isDetails) {
      await this.goToMain()
    }

    if (this.isOrder && this.$store.getters.jwtDecoded.hasRequiredAddresses) {
      this.afterSave()
    }
    // this.$gtm.enable(false)
  },
  computed: {
    isDetails() {
      return (
        this.$store.getters.getOrderSteps?.[this.$store.getters.getOrderStep]
          ?.label === 'label.address_additional'
      )
    },
    addresses() {
      if (this.alt) {
        return [this.address, this.altAddress]
      }

      return [this.address]
    },
    cartDetails() {
      return this.$store.getters.getCartDetails
    }
  },
  methods: {
    intent(success) {
      if (success) {
        this.$store.commit(
          types.UPDATE_ORDER_STEP,
          this.$store.getters.getOrderStep + 1
        )
      }
      this.intentDetails = false
    },
    next() {
      if (!this.isDetails) {
        if (this.validate()) {
          this.intentDetails = true
        }
      } else if (this.isDetails) {
        this.saveAddresses()
      }
    },
    back() {
      if (this.isOrder && !this.isDetails) {
        this.$router.push({ name: UserData })
      }
      if (!this.isOrder && !this.isDetails) {
        this.$router.push({ name: RouteUserAddresses })
      }
      if (this.isDetails) {
        this.goToMain()
      }
    },
    async saveAddresses() {
      if (this.validate()) {
        let submitData = JSON.parse(
          JSON.stringify(
            this.addresses.map(a => {
              for (const [n, v] of Object.entries(a)) {
                if (v && undefined !== v.toString) {
                  a[n] = v.toString()
                }
                if (a[n] === '') {
                  a[n] = null
                }
              }

              return a
            })
          )
        )

        if (!this.$store.getters.jwtDecoded.hasRequiredAddresses) {
          submitData[0].isStandardByDefault = true
          submitData[this.alt ? 1 : 0].isSaturdayByDefault = true
        }

        if (submitData[0].id) {
          submitData = submitData[0]
          const { data } = await UserApi.addressHasPlannedDeliveries(
            submitData.id
          )

          const id = submitData.id
          if (data.has_planned_deliveries) {
            this.$swal({
              customClass: 'oldNtfyModal custom-address',
              title: this.$t(
                'You already have deliveries scheduled to this address'
              ),
              showCloseButton: true,
              reverseButtons: true,
              width: '800px',
              confirmButtonText: this.$t('Change for scheduled'),
              denyButtonText: this.$t('Create a new address'),
              html: this.$t('dialog.ask_about_to_change_address_for_scheduled'),
              showDenyButton: true,
              showConfirmButton: true
            }).then(({ isConfirmed, isDenied }) => {
              if (isDenied) {
                delete submitData.id
                UserApi.createAddress([submitData]).then(this.afterSave)
              }
              if (isConfirmed) {
                UserApi.updateAddressForPlannedDeliveries(id, submitData).then(
                  this.afterSave
                )
              }
            })
          } else {
            UserApi.updateAddress(id, submitData).then(this.afterSave)
          }
        } else {
          UserApi.createAddress(submitData).then(payload => {
            this.afterSave(payload)
            auth.refresh() // need to has_address in token
          })
        }
      }
    },
    afterSave(payload) {
      if (location.pathname.includes('calendar/browser')) {
        this.$emit('after', payload)
      } else {
        if (this.isOrder) {
          this.gtm('checkoutOption', {
            checkout_option: {
              actionField: {
                step: '2',
                option: 'standard_address' // @todo weekend_address
              }
            }
          })

          AuthApi.refresh().then(() => order.create())
        } else {
          this.$swal({
            customClass: 'oldNtfyModal',
            title: this.$t('Success!'),
            showCloseButton: true,
            reverseButtons: true,
            html: this.$t('The changes have been saved'),
            showConfirmButton: true
          }).then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: RouteUserAddresses })
            }
          })
        }
      }
    },
    getErrors(field) {
      if (this.errors.hasOwnProperty(field)) {
        return Object.values(this.errors[field])
          .map(v => this.$t(v))
          .join(', ')
      }

      return null
    },
    validate() {
      this.isValid = true
      for (const a of this.addresses) {
        for (const f of Object.values(a)) {
          f?.validate?.()
          if (f?.getError?.()) {
            console.log(f, f?.getError?.())
            this.isValid = false
          }
        }
      }

      return this.isValid
    },
    async goToMain() {
      this.$store.commit(
        types.UPDATE_ORDER_STEP,
        this.$store.getters.getOrderStep - 1
      )
    },
    gtm(event, ecommerce) {
      if (this.isOrder) {
        const gtmData = {
          event,
          dimension3: this.cartDetails.dimension3,
          dimension4: this.cartDetails.dimension4,
          ecommerce
        }

        window.dataLayer.push(gtmData)
      }
    }
  }
}
</script>
