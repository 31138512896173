<template>
  <div class="page-layout">
    <header-client-view></header-client-view>

    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <div class="container">
        <div class="plg-ni_user_moneybox" data-ni_user_moneybox-opt="[]">
          <div class="users_moneybox">
            <div class="moneybox">
              <h1 class="diet-configuration__header text-uppercase text-center">
                Punkty
              </h1>

              <div class="white-background p-4">
                <div class="moneybox__info">
                  <p class="font-bold">
                    Zebrane punkty obniżają koszt Twojej następnej diety.
                  </p>
                  <br />
                  <p class="font-bold">
                    Przelicznik jest prosty 1 punkt = 1 zł
                  </p>
                </div>

                <div class="flex justify-center my-5">
                  <button class="button-red max-w-250" @click="goReplenishment">
                    Doładuj
                  </button>
                  <button
                    class="button-red max-w-250 ml-5"
                    @click="goWithdrawal"
                  >
                    Wypłać
                  </button>
                </div>

                <div class="moneybox__paragraph">
                  <div v-if="mgm">
                    <p style="font-size: 20px">
                      <b>Jak doładować?</b>
                    </p>

                    <p style="font-size: 20px">
                      Możesz doładować swoje konto przelewając na nie środki.
                      Kliknij <b>“doładuj”</b>, wpisz kwotę jaką chcesz
                      doładować konto, a potem wykorzystuj punkty na kolejne
                      zamówienia. Pamiętaj, że 1 wpłacona zł to także 1 punkt.
                    </p>
                    <br />
                    <br />

                    <p style="font-size: 20px">
                      <b>Jak wypłacić?</b>
                    </p>
                    <p style="font-size: 20px">
                      Wpłaconą kwotę możesz przelać z powrotem na swoje konto
                      bankowe. Kliknij <b>“wypłać”</b> i wpisz kwotę jaką
                      ówcześnie wpłaciłeś. Tylko wpłacone środki można wypłacić.
                    </p>
                  </div>
                </div>

                <!--                <div class="moneybox__paragraph">-->
                <!--                  <p class="font-bold">STARY WYJADACZ</p>-->
                <!--                  <p>-->
                <!--                    Zjadłeś już u nas co najmniej 50 zestawów? Kup kolejną-->
                <!--                    dietę, a dostaniesz dodatkowe punkty do skarbonki! System po-->
                <!--                    tym jak zaplanujesz dostawy sprawdzi ile dni zostało-->
                <!--                    wykorzystane wcześniej na Twoim profilu. Wykorzystane-->
                <!--                    oznacza opłacone, więc uwzględnione są także zestawy, które-->
                <!--                    jeszcze nie zostały dostarczone. Ilość punktów uzależniona-->
                <!--                    jest od ilości wykorzystanych zestawów:-->
                <!--                  </p>-->
                <!--                </div>-->

                <!--                <div class="table__wrapper table__wrapper-1">-->
                <!--                  <table class="moneybox__table">-->
                <!--                    <tbody>-->
                <!--                      <tr>-->
                <!--                        <th>Liczba wykorzystanych zestawów</th>-->
                <!--                        <th>-->
                <!--                          Ilość punktów do skarbonki za każdy nowy dzień-->
                <!--                          cateringu-->
                <!--                        </th>-->
                <!--                      </tr>-->
                <!--                      <tr>-->
                <!--                        <td>50-99</td>-->
                <!--                        <td>1</td>-->
                <!--                      </tr>-->
                <!--                      <tr>-->
                <!--                        <td>100-149</td>-->
                <!--                        <td>2</td>-->
                <!--                      </tr>-->
                <!--                      <tr>-->
                <!--                        <td>150 i więcej</td>-->
                <!--                        <td>3</td>-->
                <!--                      </tr>-->
                <!--                    </tbody>-->
                <!--                  </table>-->
                <!--                </div>-->

                <!--                <div class="moneybox__paragraph">-->
                <!--                  <p class="font-bold">PRZYKŁAD</p>-->
                <!--                  <p>-->
                <!--                    Wykorzystałeś u nas łącznie 60 dni diety, 2 razy po 30 dni-->
                <!--                    każda. Chcesz kupić kolejną dietę na 20 dni? Po tym jak-->
                <!--                    zaplanujesz nowe dostawy do Twojej skarbonki trafi 20-->
                <!--                    punktów.-->
                <!--                  </p>-->
                <!--                </div>-->

                <!--                <div class="moneybox__paragraph">-->
                <!--                  <p class="font-bold">WSPÓLNE DOSTAWY</p>-->
                <!--                  <p>-->
                <!--                    Korzystacie z cateringu wspólnie pod tym samym adresem?-->
                <!--                    Powiadom naszych konsultantów z kim otrzymujesz catering na-->
                <!--                    wspólny adres, a oni sprawdzą czy wszystkie dane dotyczące-->
                <!--                    dostawy są takie same, abyście otrzymywali punkty za wspólne-->
                <!--                    dostawy. Za każdą wspólną dostawę do Waszych skarbonek będą-->
                <!--                    wpadały punkty. Warunkiem koniecznym jest identyczny adres i-->
                <!--                    godziny dostawy!-->
                <!--                  </p>-->
                <!--                </div>-->

                <p class="moneybox__info2">
                  Na Twoim koncie znajduje się
                  <span class="moneybox__info2__bold">{{ moneyboxVal }} punktów i {{ walletVal }} zł.</span>
                </p>
                <br />

                <!--               <div class="moneybox__paragraph" style="text-align: center">-->
                <!--                  <div v-if="mgm">-->
                <!--                    <p style="font-size: 20px">-->
                <!--                      <b>Historia punktów:</b>-->
                <!--                    </p>-->
                <!--                  </div>-->
                <!--                </div>-->

                <!--                <div class="table__wrapper table__wrapper-2">-->
                <!--                  <table class="moneybox__table">-->
                <!--                    <tbody>-->
                <!--                      <tr>-->
                <!--                        <th>Ilość osób</th>-->
                <!--                        <th>Ilość punktów dla każdej osoby</th>-->
                <!--                      </tr>-->
                <!--                      <tr>-->
                <!--                        <td>2</td>-->
                <!--                        <td>2</td>-->
                <!--                      </tr>-->
                <!--                      <tr>-->
                <!--                        <td>3 i więcej</td>-->
                <!--                        <td>3</td>-->
                <!--                      </tr>-->
                <!--                    </tbody>-->
                <!--                  </table>-->
                <!--                </div>-->

                <h3 v-if="items.length > 0" class="moneybox__h3">
                  Historia punktów:
                </h3>
                <div class="transaction-history__mobile">
                  <div
                    v-for="transaction in items"
                    :key="transaction.id"
                    class="transaction"
                  >
                    <div
                      class="transaction-info__toggler"
                      @click="toggleActiveClass"
                    >
                      <p>Data:</p>
                      <p class="font-bold">
                        {{ $date(transaction.date).format('LL') }}
                        {{ $date(transaction.date).format('LTS') }}
                      </p>
                      <img
                        src="/images/arrow_down.svg"
                        alt="Girl in a jacket"
                        width="16"
                        height="8"
                        class="transaction-arrow"
                      />
                    </div>

                    <div class="transaction-info__toggle">
                      <div>
                        <p>Liczba:</p>
                        <p class="font-bold">
                          {{ transaction.increase === true ? '+' : '-' }}
                          {{ transaction.value }}
                          {{ transaction.type === 'moneybox' ? 'pkt' : 'zł' }}
                        </p>
                      </div>
                      <div>
                        <p>Zdarzenie:</p>
                        <p class="font-bold">{{ transaction.description }}</p>
                      </div>
                      <div>
                        <p>Stan pkt:</p>
                        <p class="font-bold">
                          {{
                            transaction.type === 'moneybox'
                              ? transaction.stateAfter
                              : '0'
                          }}
                          pkt
                        </p>
                      </div>
                      <div>
                        <p>Stan zł:</p>
                        <p class="font-bold">
                          {{
                            transaction.type !== 'moneybox'
                              ? transaction.stateAfter
                              : '0'
                          }}
                          zł
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="items.length > 0"
                  class="transaction-history__desktop"
                >
                  <table class="transactions-table">
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Liczba</th>
                        <th>Zdarzenie</th>
                        <th>Stan pkt</th>
                        <th>Stan zł</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="history in items">
                        <tr :key="history.id">
                          <td>
                            {{ $date(history.date).format('LL') }}
                            {{ $date(history.date).format('LTS') }}
                          </td>
                          <td>
                            {{ history.increase === true ? '+' : '-'
                            }}{{ history.value }}
                            {{ history.type === 'moneybox' ? 'pkt' : 'zł' }}
                          </td>
                          <td>{{ history.description }}</td>
                          <td>
                            <span v-if="history.type === 'moneybox'">{{
                              history.stateAfter
                            }}</span>
                          </td>
                          <td>
                            <span v-if="history.type === 'wallet'">{{
                              history.stateAfter
                            }}</span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <!--                <nav-->
                  <!--                  aria-label="Page navigation"-->
                  <!--                  class="pull-right"-->
                  <!--                  v-if="$store.getters.getTotalPages > 1"-->
                  <!--                >-->
                  <!--                  <ul class="pagination">-->
                  <!--                    <li @click="deIncrement"><a rel="prev">«</a></li>-->
                  <!--                    <template v-for="n in $store.getters.getTotalPages">-->
                  <!--                      <li-->
                  <!--                        :key="n"-->
                  <!--                        :class="{-->
                  <!--                          active: $store.getters.getCurrentPage === n-->
                  <!--                        }"-->
                  <!--                        @click="setPage(n)"-->
                  <!--                      >-->
                  <!--                        <span>{{ n }}</span>-->
                  <!--                      </li>-->
                  <!--                    </template>-->
                  <!--                    <li @click="increment"><a rel="next">»</a></li>-->
                  <!--                  </ul>-->
                  <!--                </nav>-->
                </div>
                <div v-if="page < pageCount">
                  <div
                    class="text-center order_history-btnWrapper"
                    style="padding-top: 40px"
                  >
                    <a
                      class="button-outlined max-w-250"
                      href="#"
                      @click="next($event)"
                      >Pokaż więcej</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '@/components/HeaderClientView'
import FooterClientView from '@/components/FooterClientView'
import * as actionTypes from '@/store/actions_types'
import * as RouteCollection from '@/router/route_collection'
import MarginMixin from '../../../helpers/mixins'
import moneybox from '@/services/api/user/moneybox'
import * as ActionTypes from '@/store/actions_types'

export default {
  components: { HeaderClientView, FooterClientView },
  mixins: [MarginMixin],
  data() {
    return {
      items: [],
      referer: undefined,
      error: undefined,
      orderId: undefined,
      order: null,
      orderFetched: false,
      page: 1,
      itemsPerPage: 15,
      pageCount: null,
      mgm: null
    }
  },
  async mounted() {
    this.referer = this.$route.query?.referer
    this.error = this.$route.query?.error
    this.orderId = this.$route.query?.orderId
    this.load(this.page)

    await this.$store.dispatch(ActionTypes.FETCH_CLIENT_DATA)
    this.mgm = this.$store.getters.mgm

    if (this.orderId) {
      this.fetchOrder(this.orderId)
    }
  },
  watch: {
    orderFetched() {
      if (this.referer === 'payu') {
        if (this.error && this.error === '501') {
          this.showFailureMessage()
        } else {
          this.showSuccessMessage()
        }
      }
    }
  },
  computed: {
    moneyboxVal() {
      return this.$store.getters.jwtDecoded.moneybox_value || 0
    },
    walletVal() {
      return this.$store.getters.jwtDecoded.wallet_value || 0
    }
  },
  methods: {
    load(page) {
      moneybox.fetchMoneyboxHistory(page, this.itemsPerPage).then(
        response => {
          let history = response.data

          this.items = this.items.concat(history.items)
          this.page = history.page
          this.pageCount = history.pageCount
          this.totalItemCount = history.totalItemCount
        },
        e => {
          this.handleError(e, false)
        }
      )
    },
    next(event) {
      event.preventDefault()
      this.load(++this.page)
    },
    goReplenishment() {
      return this.$router.push({
        name: RouteCollection.UserMoneyboxReplenishment
      })
    },
    goWithdrawal() {
      return this.$router.push({ name: RouteCollection.UserMoneyboxWithdrawal })
    },
    toggleActiveClass(e) {
      e.target.classList.toggle('active')
      e.target.children[2].classList.toggle('rotate-arrow')
      e.target.nextElementSibling.classList.toggle('active')
    },
    showSuccessMessage() {
      window.dataLayer.push({
        event: 'moneybox',
        eventCategory: 'Moneybox',
        eventAction: 'Payment',
        eventLabel: this.order.pricePayedByTransfer
      })

      this.$swal({
        type: 'success',
        customClass: 'modal__replenishment',
        title: 'SUKCES',
        showCloseButton: true,
        text: 'Środki dotrą na Twoje konto najpóźniej w ciągu 7 dni roboczych.',
        confirmButtonText: 'ZAMKNIJ'
      })
    },
    showFailureMessage() {
      window.dataLayer.push({
        event: 'moneybox',
        eventCategory: 'Moneybox',
        eventAction: 'Payment_fail'
      })

      this.$swal({
        type: 'success',
        customClass: 'modal__replenishment failure',
        title: 'NIEPOWODZENIE',
        showCloseButton: true,
        html:
          'Doładowanie skarbonki nie powiodło się. <span>Spróbuj ponownie później.</span>',
        confirmButtonText: 'ZAMKNIJ'
      })
    },
    fetchOrder(id) {
      this.$store.dispatch(actionTypes.FETCH_ORDER, { id: id }).then(
        order => {
          this.order = order
          this.orderFetched = true
        },
        e => {
          this.handleError(e, false)
        }
      )
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1088px;
}
</style>
