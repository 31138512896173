<template>
  <div
    :class="{
      'form-field-error-wrapper': value && value.getError()
    }"
  >
    <FormLabel v-if="label" :is-required="required">
      {{ $t(label) }}
    </FormLabel>

    <div class="input__wrapper" v-if="type === 'input'">
      <input
        :name="name"
        :placeholder="$t(placeholder)"
        :value="value"
        :disabled="disabled"
        @input="input"
        type="text"
      />
    </div>

    <div class="input__wrapper" v-if="type === 'textarea'">
      <textarea
        rows="4"
        :name="name"
        class="w-100 box-sizing-border"
        :value="value"
        :placeholder="$t(placeholder)"
        @input="input"
      ></textarea>
    </div>

    <div class="select__wrapper" v-if="type === 'select'">
      <v-select
        :name="name"
        :reduce="item => item.value"
        :options="options"
        :value="val"
        :placeholder="$t(placeholder)"
        @input="input"
      >
        <template #no-options>{{ $t('No matching options') }}</template>
      </v-select>
    </div>

    <div v-if="type === 'radio'">
      <div class="mb-2" v-for="o in options" :key="o.value">
        <input
          class="radio-input"
          name="keyDoorGateOther"
          :id="`keyDoorGateOther${o.value}`"
          type="radio"
          :checked="value.toString() === o.value"
          :value="o.value"
          @input="input"
        />
        <label
          class="radio-label"
          :for="`keyDoorGateOther${o.value}`"
          v-html="$t(o.label)"
        />
      </div>
    </div>

    <p class="form-field-error" v-if="value && value.getError()">
      {{ $t(value.getError()) }}
    </p>
  </div>
</template>

<script>
import FormLabel from '@/components/Address/FormLabel.vue'
import FormField from '@/services/form/field'

export default {
  name: 'InputFormField',
  components: { FormLabel },
  props: {
    type: {
      type: String,
      default: 'input'
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Object, Number],
      default: ''
    },
    label: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean
    },
    required: {
      type: Boolean
    },
    maxLength: {
      type: Number,
      required: false
    },
    pattern: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    modify: {
      type: Function,
      required: false
    }
  },
  mounted() {
    let value = ''
    if (null !== this.value) {
      value = this.value.toString()
    }
    this.$emit('input', new FormField(value, this.validation))
  },
  beforeDestroy() {
    this.$emit('input', new FormField(this.value.toString()))
  },
  computed: {
    val() {
      const vl = this.value.toString()
      return this.options.length > 0 && this.options.find(v => v.value === vl)
        ? vl
        : ''
    }
  },
  methods: {
    validation() {
      if (this.required && this.value.toString().length === 0) {
        this.value.setError('The field is required')
      }

      if (undefined !== this.pattern) {
        const pattern = new RegExp(this.pattern)
        if (!pattern.test(this.value.toString())) {
          this.value.setError(`form.validation_error.pattern.${this.name}`)
        }
      }

      if (
        undefined !== this.maxLength &&
        this.value.toString().length > this.maxLength
      ) {
        this.value.setError(
          this.$t('form.validation_error.maxLength', { n: this.maxLength })
        )
      }
    },
    input(event) {
      if (this.type === 'select') {
        this.$emit('input', this.value.assign(event || ''))
      } else {
        let value = event.target.value
        if (this.modify) {
          value = this.modify(value)
        }
        this.$emit('input', this.value.assign(value))
      }
    }
  }
}
</script>
