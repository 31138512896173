import axios from '../../../interceptor'
import routes from '../routes'

export default {
  getMyData() {
    return axios.get(routes.myData)
  },
  addressCheck(data) {
    return axios.post(routes.addressCheck, data)
  },
  updateMyData(data) {
    return axios.patch(routes.updateMyData, data)
  },
  changePassword(data) {
    return axios.patch(routes.changePassword, data)
  },
  updateMarketingAgreement(emailMarketingAgreement, phoneMarketingAgreement) {
    return axios.patch(routes.updateMarketingAgreement, {
      data: { emailMarketingAgreement, phoneMarketingAgreement }
    })
  },
  updateNeedFvAndNip(needFv, nip) {
    return axios.patch(routes.updateNeedFvAndNip, { data: { needFv, nip } })
  },
  register(data) {
    return axios.post(routes.registration, data)
  },
  facebook(data) {
    return axios.post(routes.facebook, data)
  },
  deactivate() {
    return axios.post(routes.deactivate)
  }
}
