<template>
  <div class="page-layout__logged-out">
    <header-index-view :fixed="true"></header-index-view>

    <main class="main center-content">
      <div class="container pop-up form-container">
        <h3 class="h4 h4--popup mb-5 title text-center">Odzyskaj hasło</h3>

        <div class="plg-ni_users_remind-formRemind">
          <div class="form-group">
            <input
              title="E-mail"
              placeholder="Adres e-mail"
              class="form-control form-control--main"
              name="email"
              type="text"
              v-model="email"
              @keyup.enter="remind"
            />

            <p
              class="subTitle text-danger plg-ni_order_register_userData-first_name-error"
              v-html="errorMsg"
            ></p>
          </div>

          <button
            type="submit"
            class="btn btn-main btn-main--custom m-b-10 new-btn plg-ni_users_remind-submitBtn"
            @click="remind"
          >
            Przypomnij
          </button>
          <input type="submit" style="display: none" />
        </div>
        <p class="text-white text-center">
          Jeśli masz konto,
          <router-link :to="{ name: 'LogIn' }">zaloguj się.</router-link>
        </p>
      </div>
    </main>

    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderIndexView from '../../components/HeaderIndexView'
import FooterClientView from '../../components/FooterClientView'
import UserApi from '../../services/api/user'

export default {
  components: { HeaderIndexView, FooterClientView },
  name: 'Remind',
  data() {
    return {
      email: '',
      errorMsg: ''
    }
  },
  methods: {
    async remind() {
      this.errorMsg = ''
      UserApi.remindPassword(this.email)
        .then(res => {
          this.$swal({
            type: 'success',
            customClass: 'oldNtfyModal',
            title: 'UWAGA',
            showCloseButton: true,
            text:
              'Twoje instrukcja resetowania hasła została wysłana. Proszę sprawdzić email.'
          })
        })
        .catch(err => {
          if (err.response.data.messages[0] === 'User not found.') {
            this.errorMsg = 'Nie znaleziono użytkownika'
          } else {
            this.errorMsg = err.response.data.messages[0]
          }
        })
    }
  }
}
</script>
