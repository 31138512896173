import * as types from '../mutations_types'
import * as actionTypes from '../actions_types'
import order from '../../services/api/order'

const actions = {
  [actionTypes.FETCH_ORDER]({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      // if (getters.order) {
      //   resolve(getters.order)
      //   return
      // }

      order.fetchOrder(params.id).then(
        successResponse => {
          commit(types.ORDER_CHANGE_LAST_ORDER, successResponse.data)
          resolve(successResponse.data)
        },
        failedResponse => {
          reject(failedResponse)
        }
      )
    })
  }
}

function initialState() {
  return {
    fv: false,
    agreement: false,
    nip: null,
    order: null,
    errors: []
  }
}

const state = initialState()

const getters = {
  error(state) {
    return field => {
      return (state.errors[field] || [])[1]
    }
  },
  order(state) {
    return state.order
  }
}

const mutations = {
  [types.ORDER_CHANGE_FV](state, value) {
    state.fv = value
  },
  [types.ORDER_CHANGE_AGREEMENT](state, value) {
    state.agreement = value
  },
  [types.ORDER_CHANGE_NIP](state, value) {
    state.agreement = value
  },
  [types.ORDER_CHANGE_ERRORS](state, errors) {
    state.errors = errors
  },
  [types.ORDER_CHANGE_LAST_ORDER](state, order) {
    state.order = order
  },
  [types.RESET_ORDER](state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
