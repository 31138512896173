function initialState() {
  return {
    dimension3: null,
    dimension4: null,
    dimension5: null,
    dimension6: null,
    metric2: null,
    products: [],
    weekendAddress: false
  }
}

const state = initialState()

const actions = {
  setCartDetails({ commit }, data) {
    commit('SET_CART_DETAILS', data)
  },
  setWeekendAddress({ commit }, data) {
    commit('SET_WEEKEND_ADDRESS', data)
  }
}

const getters = {
  getCartDetails: state => state,
  isWeekendAddress: state => state.weekendAddress
}

const mutations = {
  SET_CART_DETAILS(state, data) {
    state = Object.assign(state, data)
  },
  SET_WEEKEND_ADDRESS(state, data) {
    state.weekendAddress = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
