<template>
  <div class="diet-configuration__container">
    <div class="diet-configuration__content-wrapper">
      <h2 class="text-center mb-8">Wybrane posiłki</h2>

      <div class="meals-grid">
        <div
          v-for="basketMeal in $store.getters.getConfiguratorBasketMealsOptions"
          :key="basketMeal.id"
          class="box-container checkbox-container__meals"
        >
          <input
            :title="basketMeal.mealType.name"
            :id="'basket_meal_' + basketMeal.id"
            type="checkbox"
            :disabled="
              selected.length === 1 && selected.includes(basketMeal.id)
            "
            :value="basketMeal.id"
            v-model="selected"
          />

          <label
            :for="'basket_meal_' + basketMeal.id"
            class="checkbox-label__meals"
          >
            <span>{{ basketMeal.mealType.name }}</span>
            <span>ok {{ basketMeal.size.calorific }} kcal</span>
          </label>
        </div>
      </div>

      <h4 class="labelSmall text-center mt-8">
        Dzienna kaloryczność wybranych posiłków:
        <strong class="plg-ni_order_register_configurator-dailyCalorific">{{
          getTotalCalorific()
        }}</strong>
        kcal
      </h4>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '../../store/actions_types'

export default {
  name: 'meal-type',
  computed: {
    selected: {
      get: function () {
        return this.$store.getters.getConfiguratorBasketMeals
      },
      set: function (value) {
        const basketMeals = this.$store.getters
          .getConfiguratorBasketMealsOptions

        const sortedBasketMeals = basketMeals
          .sort((a, b) => a.mealType.position - b.mealType.position)
          .map(({ id }) => id)
          .filter(id => value.includes(id))

        this.$store.dispatch(
          actionTypes.CHANGE_CONFIGURATOR_MEAL_TYPES,
          sortedBasketMeals
        )
      }
    },
    selectedBasket: {
      get: function () {
        if (this.$store.getters.getConfiguratorBasket === null) {
          return null
        }

        let basketName
        this.$store.getters.getConfiguratorBasketOptions.forEach(el => {
          if (this.$store.getters.getConfiguratorBasket === el.id) {
            basketName = el.name
          }
        })

        return basketName
      }
    }
  },
  methods: {
    getBoxWidth() {
      return (
        (
          100 / this.$store.getters.getConfiguratorBasketMealsOptions.length
        ).toFixed(1) - 0.1
      )
    },
    getTotalCalorific() {
      let calorific = 0
      let basketMeals = this.$store.getters.getConfiguratorBasketMealsOptions

      this.selected.forEach(function (basketId) {
        basketMeals.forEach(function (basketMeal) {
          if (basketMeal.id === basketId) {
            calorific += basketMeal.size.calorific
          }
        })
      })

      return calorific
    }
  }
}
</script>
