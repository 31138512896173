import * as types from '../mutations_types'
import * as actionTypes from '../actions_types'
import TagsApi from '../../services/api/tags'

const actions = {
  [actionTypes.FETCH_COMING_SOURCE]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      if (getters.getComingSource.length !== 0) {
        resolve()
        return
      }

      TagsApi.getComingSource().then(
        successResponse => {
          commit(types.UPDATE_TAG, {
            type: 'comingSource',
            data: successResponse.data
          })
          resolve()
        },
        failedResponse => {
          console.error(failedResponse)
        }
      )
    })
  }
}

function initialState() {
  return {
    comingSource: []
  }
}

const state = initialState()

const getters = {
  getComingSource: state => state.comingSource
}

const mutations = {
  [types.UPDATE_TAG](state, { type, data }) {
    state[type] = data
  },
  [types.RESET_TAGS](state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
