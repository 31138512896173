<template>
  <div class="card position-relative">
    <div class="flex justify-content-space-between address-header">
      <h4 v-if="address.name">{{ address.name }}</h4>
      <h4 v-else>{{ address.street }} {{ address.buildingNr }}</h4>
      <i
        class="fa actions"
        :class="opened ? 'fa-close' : 'fa-ellipsis-h'"
        @click="opened = !opened"
      />
    </div>
    <div>
      <p class="mb-1 font-smaller">
        {{ address.street }} {{ address.buildingNr }}
      </p>
      <p class="mb-1 font-smaller">{{ address.postCode }} {{ address.city }}</p>
      <p
        class="mb-3 font-smaller"
        v-if="address.deliveryHourFrom || address.deliveryHourTo"
      >
        {{ $t('Delivery') }}
        <span v-if="address.deliveryHourFrom">
          {{ $t('from hrs.') }} {{ address.deliveryHourFrom }}
        </span>
        <span v-if="address.deliveryHourTo">
          {{ $t('to hrs.') }} {{ address.deliveryHourTo }}
        </span>
      </p>

      <p
        class="text-muted font-smaller mb-1"
        v-if="address.isStandardByDefault"
      >
        {{ $t('Default address on weekdays') }}
      </p>
      <p class="text-muted font-smaller" v-if="address.isSaturdayByDefault">
        {{ $t('Default address on weekends and public holidays') }}
      </p>
    </div>
    <div class="menu" v-if="opened">
      <p class="link-mute font-smaller mb-1" @click="setAllByDefault">
        <strong>{{ $t('Set as default address') }}</strong>
      </p>
      <p class="link-mute font-smaller mb-1" @click="setStandardByDefault">
        {{ $t('Set as default address on weekdays') }}
      </p>
      <p class="link-mute font-smaller mb-1" @click="setSaturdayByDefault">
        {{ $t('Set as default address on weekends and public holidays') }}
      </p>

      <p class="font-smaller mb-1">
        <router-link
          class="link-mute"
          :to="{
            name: UserAddress,
            params: { addressId: address.id.toString() }
          }"
        >
          {{ $t('Edit address') }}
        </router-link>
      </p>

      <p
        class="font-smaller mb-1 text-danger cursor-pointer"
        @click="deleteAddress"
      >
        {{ $t('Delete address') }}
      </p>
    </div>
  </div>
</template>

<script>
import { UserAddress } from '@/router/route_collection'
import UserApi from '@/services/api/user'
import store from '@/store'
import * as types from '@/store/mutations_types'
import auth from '@/services/api/auth'

export default {
  name: 'UserAddressCard',
  props: {
    address: {
      type: Object,
      required: true
    },
    remount: {
      type: Function,
      required: false,
      default: () => () => {}
    }
  },
  data() {
    return {
      UserAddress,
      opened: false
    }
  },
  methods: {
    async setAllByDefault() {
      UserApi.updateAddress(this.address.id, {
        isStandardByDefault: true,
        isSaturdayByDefault: true
      }).then(
        this.remount.bind(
          null,
          this.$t('The address has been set as the default')
        )
      )
    },
    async setStandardByDefault() {
      UserApi.updateAddress(this.address.id, {
        isStandardByDefault: true
      }).then(
        this.remount.bind(
          null,
          this.$t('The address has been set as the default on weekdays')
        )
      )
    },
    cannotDeleteWarning() {
      let message = 'dialog.address_cannot_be_deleted'
      if (this.address.isStandardByDefault) {
        message += '.standard'
      }
      if (this.address.isSaturdayByDefault) {
        message += '.saturday'
      }
      this.$swal({
        customClass: 'oldNtfyModal',
        title: this.$t('warning'),
        showCloseButton: true,
        html: this.$t(message)
      })
    },
    async setSaturdayByDefault() {
      UserApi.updateAddress(this.address.id, {
        isSaturdayByDefault: true
      }).then(
        this.remount.bind(
          null,
          this.$t(
            'The address has been set as the default Saturdays and holidays'
          )
        )
      )
    },
    async deleteAddress() {
      if (
        this.address.isStandardByDefault ||
        this.address.isSaturdayByDefault
      ) {
        this.cannotDeleteWarning()
      } else {
        this.$swal({
          customClass: 'oldNtfyModal',
          title: this.$t('warning'),
          showCloseButton: true,
          reverseButtons: true,
          html: this.$t('Are you sure want to delete the address?'),
          showConfirmButton: true
        }).then(async result => {
          if (result.isConfirmed) {
            const {
              data: { has_planned_deliveries }
            } = await UserApi.addressHasPlannedDeliveries(this.address.id)
            if (has_planned_deliveries) {
              this.cannotDeleteWarning()
              return
            }

            UserApi.deleteAddress(this.address.id, error => {
              if (error.response.status === 425) {
                this.cannotDeleteWarning()
                return false
              }
            }).then(async () => {
              await UserApi.fetchAddresses()
              await auth.refresh() // need to has_address in token
              this.remount(this.$t('The address has been removed'))
            })
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.card {
  box-shadow: 0 2px 6px 0 #dadde3;
  background-color: white;
  padding: 20px 15px;
  border-radius: 4px;
  height: 100%;
  min-height: 190px;
}
.menu {
  border-top: 1px solid rgba(60, 60, 60, 0.26);
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  top: 63px;
  padding: 20px 15px;
  background-color: white;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 6px 0 #dadde3;
}
.actions {
  margin-top: 3px;
  cursor: pointer;
}
.menu p {
  line-height: 30px;
}
.address-header {
  margin: 0 0 30px 0;
}
.link-mute {
  color: inherit;
  cursor: pointer;
}
</style>
