<template>
  <div class="day-select-menu-form">
    <div class="info-row mb-8">
      <h5 class="">Wybierz posiłki</h5>

      <div v-if="disabled">
        <p class="">Zmiana posiłków nie jest możliwa.</p>
        <p v-show="!hasMealTypes" class="">
          Menu nie zostało jeszcze ustalone.
        </p>
      </div>

      <div v-if="!disabled" class="">
        <p>zmiana posiłków możliwa jeszcze przez:</p>
        <vue-countdown :time="deadlineChangeMenuDeadline">
          <template slot-scope="props"
            ><template v-if="parseInt(props.days) > 0"
              >{{ props.days }}d </template
            >{{ props.hours }}h {{ props.minutes }}m
            {{ props.seconds }}s</template
          >
        </vue-countdown>
      </div>
    </div>

    <div v-if="dietVariantHelper" class="warning-box mb-8">
      {{ dietVariantHelper }}
    </div>

    <menu-picker
      :meal-types="mealTypes"
      :disabled="disabled"
      class="mb-8"
    ></menu-picker>

    <delivery-date-picker
      :show-change-delivery-date="showChangeDeliveryDate"
      @showChangeDeliveryDateUpdated="
        $emit('showChangeDeliveryDateUpdated', $event)
      "
      class="mb-8"
    ></delivery-date-picker>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'
import DeliveryDatePicker from './DeliveryDatePicker'
import MenuPicker from './MenuPicker'
import { DIET_VARIANT_WARNINGS } from '../../consts/diet'

export default {
  name: 'DaySelectMenuForm',
  components: { DeliveryDatePicker, MenuPicker, VueCountdown },
  props: {
    day: { type: Object },
    showChangeDeliveryDate: { type: Boolean },
    dietVariant: {
      type: String,
      default: null
    }
  },
  beforeDestroy() {
    this.$emit('showChangeDeliveryDateUpdated', false)
  },
  computed: {
    hasMealTypes: function () {
      if (typeof this.mealTypes === 'undefined') {
        return 0
      }

      return this.mealTypes.length > 0
    },
    mealTypes: function () {
      const mealTypes = this.$store.getters.getBagDetails.mealTypes
      // HERE MAYBE

      return mealTypes.map((mealType, index, arr) => {
        if (index > 0) {
          if (mealType.id === arr[index - 1].id) {
            return {
              ...mealType,
              hideName: true
            }
          }
        }
        return mealType
      })
    },
    disabled: function () {
      return (
        !this.bagDetails.editable ||
        !this.hasMealTypes ||
        !this.bagDetails.canChangeMenu ||
        !this.compareWithNow(this.bagDetails.canChangeMenuTo)
      )
    },
    bagDetails: function () {
      return this.$store.getters.getBagDetails
    },
    deadlineChangeMenuDeadline: function () {
      return (
        (this.$date(this.bagDetails.canChangeMenuTo).unix() -
          this.$date().unix()) *
        1000
      )
    },
    dietVariantHelper() {
      return DIET_VARIANT_WARNINGS[this.dietVariant] || null
    }
  },
  methods: {
    compareWithNow(dateTime) {
      let to = this.$date(dateTime).unix()

      return this.$date().unix() < to
    }
  }
}
</script>

<!--<style>-->
<!--.alert-disabled {-->
<!--  color: #ffe123;-->
<!--  font-size: 16px;-->
<!--}-->
<!--.display-block {-->
<!--  display: block;-->
<!--}-->
<!--</style>-->
