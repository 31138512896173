<template>
  <div>
    <h3 class="text-uppercase labelLarge m-b-20" style="white-space: nowrap">
      <i class="fa fa-map-marker" aria-hidden="true"></i>{{ name }}:
    </h3>

    <div class="m-b-20">
      <label class="labelInput">Ulica:</label>
      <input
        title="Ulica"
        readonly="readonly"
        class="form-control"
        disabled="disabled"
        name="street"
        type="text"
        :value="address.street"
      />
    </div>

    <div class="row order_myData-doubleLine">
      <div class="m-b-20" style="padding-left: 0">
        <label class="labelInput">Nr budynku:</label>
        <input
          title="Nr budynku"
          readonly="readonly"
          class="form-control"
          disabled="disabled"
          name="building_nr"
          type="text"
          :value="address.buildingNr"
        />
      </div>
      <div class="m-b-20" style="padding-left: 0">
        <label class="labelInput">Nr lokalu:</label>
        <input
          title="Nr lokalu"
          readonly="readonly"
          class="form-control"
          disabled="disabled"
          name="place_nr"
          type="text"
          :value="address.placeNr"
        />
      </div>
      <div class="m-b-20" style="padding-left: 0; padding-right: 0">
        <label class="labelInput">Piętro:</label>
        <input
          title="Piętro"
          readonly="readonly"
          class="form-control"
          disabled="disabled"
          name="floor_nr"
          type="text"
          :value="address.floorNr"
        />
      </div>
    </div>

    <div class="row">
      <div class="m-b-20" style="padding-left: 0">
        <label class="labelInput">Kod pocztowy:</label>
        <input
          title="Kod pocztowy"
          readonly="readonly"
          class="form-control"
          disabled="disabled"
          name="floor_nr"
          type="text"
          :value="address.postCode"
        />
      </div>
      <div class="m-b-20" style="padding-left: 0; padding-right: 0">
        <label class="labelInput">Miasto:</label>
        <input
          title="Miasto"
          readonly="readonly"
          class="form-control"
          disabled="disabled"
          name="floor_nr"
          type="text"
          :value="address.city"
        />
      </div>
    </div>

    <div class="m-b-20">
      <label class="labelInput">Godziny dostawy od do:</label>
      <input
        title="Godziny dostawy od do"
        readonly="readonly"
        class="form-control"
        disabled="disabled"
        name="floor_nr"
        type="text"
        :value="address.hours"
      />
    </div>

    <div class="m-b-20">
      <label class="labelInput">Uwagi do kierowcy:</label>
      <textarea
        title="Uwagi do kierowcy"
        readonly="readonly"
        class="form-control"
        disabled="disabled"
        name="floor_nr"
        cols="50"
        rows="10"
        :value="address.otherPackageLeavingOption"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientAddressBlock',
  props: {
    address: { type: Object },
    name: { type: String }
  }
}
</script>
