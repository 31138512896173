<template>
  <div id="app">
    <!-- Hotjar Tracking Code for https://app.ntfy.pl -->
    <script type="application/javascript" v-if="prod">
      ;(function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            ;(h.hj.q = h.hj.q || []).push(arguments)
          }
        h._hjSettings = { hjid: 1504051, hjsv: 6 }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    </script>
    <!-- Facebook Pixel Code -->
    <script type="application/javascript" v-if="prod">
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      )
      fbq('init', '626873491690468')
      fbq('track', 'PageView')
    </script>
    <noscript v-if="prod">
      <img
        height="1"
        width="1"
        src="https://www.facebook.com/tr?id=626873491690468&ev=PageView
&noscript=1"
      />
    </noscript>
    <!-- End Facebook Pixel Code -->
    <router-view />
    <loader></loader>
  </div>
</template>

<script>
import Loader from './components/Loader'
import Vue from 'vue'
// import VueGtm from 'vue-gtm'
import axios from 'axios'
import vSelect from 'vue-select'
import * as types from '@/store/mutations_types'

Vue.component('v-select', vSelect)

// Vue.use(VueGtm, {
//   id: process.env.VUE_APP_GTM_ID,
//   enabled: true,
//   debug: true
// })

// Vue.use(VueAnalytics, {
//   id: 'UA-82649631-1',
//   debug: {
//     enabled: false
//   },
//   disableScriptLoader: true,
//   ecommerce: {
//     enabled: true,
//     enhanced: true
//   },
//   autoTracking: {
//     screenview: false
//   },
//   optimize_id: 'GTM-NC3FGFX'
// })

Vue.filter('defaultValue', (property, defaultValue) => {
  if (typeof property === 'undefined' || property === null) {
    return defaultValue
  }

  return property
})

const isAutopilotEnabled = process.env.VUE_APP_AUTOPILOT_ENABLED === 'TRUE'

export default {
  computed: {
    componentDialog() {
      return this.$store.state.componentDialog
    }
  },
  components: { Loader },
  watch: {
    $route() {
      isAutopilotEnabled && this.sendToAutopilot()
    },
    componentDialog(n) {
      document.body.style.overflow = n ? 'hidden' : 'inherit'
    }
  },
  data() {
    return {
      prod:
        process.env.VUE_APP_LANDING_PAGE_URL === 'https://republikasmakoszy.pl'
    }
  },
  methods: {
    sendToAutopilot: function () {
      var instance = axios.create()
      delete instance.defaults.headers.common['X-BRAND']
      instance
        .post(
          process.env.VUE_APP_AUTOPILOT_URL + '/pagevisit/associate',
          {
            sessionId: this.$store.getters.jwtDecoded
              ? this.$store.getters.jwtDecoded.email
              : 'Anonymous',
            email: this.$store.getters.jwtDecoded
              ? this.$store.getters.jwtDecoded.email
              : 'Anonymous'
          },
          {
            headers: {
              autopilotapikey: process.env.VUE_APP_AUTOPILOT_API_KEY,
              'Content-Type': 'application/json'
            }
          }
        )
        .catch(() =>
          console.log('Wystąpił problem po stronie serwisu Autopilot')
        )
      instance
        .post(
          process.env.VUE_APP_AUTOPILOT_URL + '/pagevisit/visit',
          {
            sessionId: this.$store.getters.jwtDecoded
              ? this.$store.getters.jwtDecoded.email
              : 'Anonymous',
            url: window.location.href
          },
          {
            headers: {
              autopilotapikey: process.env.VUE_APP_AUTOPILOT_API_KEY,
              'Content-Type': 'application/json'
            }
          }
        )
        .catch(() =>
          console.log('Wystąpił problem po stronie serwisu Autopilot')
        )
    }
  },
  created() {
    isAutopilotEnabled && this.sendToAutopilot()
  },
  name: 'App'
}
</script>

<style>
#cp-widget .cp-type--minimal .cp-btn-container--position-bottom-right {
  bottom: 15px !important;
  right: 299px !important;
}
</style>
