<template xmlns:mailto="http://www.w3.org/1999/xhtml">
  <div
    class="alert alert-calendar alert-calendar__mobile"
    style="font-size: 18px; text-align: center; width: 100%"
  >
    <span
      class="pull-right"
      style="margin-left: 15px; color: black; cursor: pointer; transition: 0.7s"
      onclick="this.parentElement.style.display='none';"
      >&times;</span
    >
    <h3 class="center">Zebraliśmy Wasz feedback!</h3>
    <br />
    <p>
      Głosujący w programie <b>Firma Przyjazna Klientowi</b> użytkownicy ocenili
      nas następująco:
    </p>
    <br />
    <p>Podejście do Klientów – <b>96%</b></p>
    <p>Jakość obsługi – <b>96%</b></p>
    <p>Ogólne zadowolenie – <b>93%</b></p>
    <br />
    <p>Dziękujemy za motywację do dalszej pracy!</p>
  </div>
</template>

<script>
export default {
  name: 'NtfyFeedbackMsg'
}
</script>
