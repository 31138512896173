<template>
  <div class="col-sm-12 flex">
    <label
      class="checkbox checkboxSmallLabel styled-checkbox"
      for="invoice_agreement"
    >
      <input
        type="checkbox"
        class="form-control"
        id="invoice_agreement"
        :checked="value"
        @input="$emit('input', !value)"
      />
      <span class="box">
        <i
          class="fa fa-check"
          aria-hidden="true"
          :class="{ 'fa-check': value }"
        />
      </span>
    </label>
    <label for="invoice_agreement" class="lbl">
      {{ $t(label) }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxVertical',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    label: {
      default: ''
    }
  },
  methods: {
    input(e) {
      console.log(e)
      //
    }
  },
  data() {
    return {
      needFv: false
    }
  }
}
</script>

<style scoped>
.lbl {
  margin: 7px 0;
  cursor: pointer;
  user-select: none;
}
.styled-checkbox {
  font-size: 25px;
  font-family: Helvetica;
  color: #222;
  font-weight: normal;
  position: relative;
  display: inline-block;
  margin-right: 30px;
  transform: scale(0.7);
}
.styled-checkbox > input {
  display: none;
}

.styled-checkbox > .box {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  border: 3px solid #d2d2d2;
  margin-right: 10px;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.styled-checkbox > .box .fa {
  font-size: 20px;
  line-height: 24px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  color: #ff4068;
  vertical-align: super;
}

.styled-checkbox > input:checked + .box {
  border: 3px solid #ff4068;
}

.styled-checkbox > input:checked + .box .fa {
  opacity: 1;
  color: #ff4068;
}

.styled-checkbox > input:checked + .box.checkbox-disabled {
  border: 3px solid #d2d2d2;
  pointer-events: none;
}

.styled-checkbox > input:checked + .box.checkbox-disabled .fa {
  color: #d2d2d2;
}
</style>
