import axios from '../../interceptor'
import * as types from '../../store/mutations_types'
import * as actionsTypes from '../../store/actions_types'
import router from '../../router'
import * as RouteCollection from '../../router/route_collection'
import store from '../../store'
import routes from './routes'
import sentry from '../../sentry'

// axios.defaults.baseURL = ''
export default {
  login(data) {
    store.commit(types.AUTH_ERROR_CHANGE, null)

    axios
      .post(routes.login, data)
      .then(response => {
        store.commit(types.UPDATE_TOKEN, response.data)

        // window.dataLayer.push({
        //   event: 'login',
        //   eventCategory: 'Customer',
        //   eventAction: 'Login',
        //   eventLabel: 'Direct'
        // })

        if (store.getters.hasRedirect) {
          let redirectTo = store.getters.redirectTo
          store.commit(types.UPDATE_REDIRECT_AFTER_LOGIN, null)
          router.push({ name: redirectTo })
        } else {
          router.push({ name: RouteCollection.CalendarBrowser })
        }
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          store.commit(
            types.AUTH_ERROR_CHANGE,
            'Wprowadzony adres e-mail lub hasło są nieprawidłowe'
          )
        }

        sentry.captureException(error)
      })
  },
  refresh() {
    return new Promise((resolve, reject) => {
      axios
        .post(routes.refreshTocken, {
          refresh_token: store.state.auth.refresh_token
        })
        .then(response => {
          store.commit(types.UPDATE_TOKEN, response.data)
          resolve(response.data)
        })
        .catch(error => {
          sentry.captureException(error)
          reject(error)
        })
    })
  },
  logout() {
    store.dispatch(actionsTypes.CLEAR_STORE).then(function () {
      store.commit(types.REMOVE_TOKEN)
      window.location.href = '/'
    })
  },
  isAuthorized() {
    let jwt = store.getters.jwtDecoded

    return jwt && jwt.exp > Date.now() / 1000
  }
}
