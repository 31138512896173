export const dietCalorificMap = {
  10: '1000',
  11: '1250',
  12: '1500',
  13: '1750',
  14: '2000',
  15: '2550'
}

export const dietVariant = (variants, id) => {
  return variants.find(el => el.id === id) || { name: '', id }
}

export const dietPackageMap = {
  6: 'rs'
}
