<template>
  <div class="toggleWrapper scale-08">
    <input
      type="checkbox"
      class="mobileToggle"
      :name="name"
      :id="name"
      @change="$emit('input', !value)"
      :checked="value"
    />
    <label :for="name"></label>
  </div>
</template>

<script>
export default {
  name: 'SwitchToggle',
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>
.toggleWrapper * {
  cursor: pointer;
}
.toggleWrapper {
  display: inline-block;
  height: 30px;
  width: 56px;
}
.toggleWrapper input.mobileToggle {
  opacity: 0;
  position: absolute;
}
.toggleWrapper input.mobileToggle + label {
  position: relative;
  display: inline-block;
  user-select: none;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  -webkit-tap-highlight-color: transparent;
  height: 20px;
  width: 50px;
  border: 1px solid #e4e4e4;
  border-radius: 60px;
  margin-top: 4px;
}
.toggleWrapper input.mobileToggle + label:before {
  content: '';
  position: absolute;
  display: block;
  -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 51px;
  top: 0;
  left: 0;
  border-radius: 30px;
  background: grey;
}
.toggleWrapper input.mobileToggle + label:after {
  content: '';
  position: absolute;
  display: block;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: whitesmoke;
  height: 28px;
  width: 28px;
  top: -4px;
  left: -1px;
  border-radius: 60px;
}
.toggleWrapper input.mobileToggle:checked + label:before {
  background: #ff4068;
  -moz-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  -o-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}
.toggleWrapper input.mobileToggle:checked + label:after {
  left: 24px;
}
</style>
