<template>
  <div class="page-layout">
    <header-client-view></header-client-view>

    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <div class="container">
        <div class="plg-ni_users_myDataForm order_myData">
          <h1 class="diet-configuration__header text-uppercase text-center">
            Moje dane
          </h1>

          <!-- form -->
          <div class="plg-ni_users_myDataForm-editForm white-background p-4">
            <!-- data -->
            <div class="personal-data">
              <div>
                <h3 class="text-uppercase labelLarge m-b-20 text-left">
                  <i class="fa fa-user" aria-hidden="true"></i>Dane personalne:
                </h3>

                <div class="m-b-20">
                  <label class="labelInput">Imię:</label>
                  <input
                    title="Imię"
                    readonly="readonly"
                    class="form-control"
                    disabled="disabled"
                    name="first_name"
                    type="text"
                    :value="client.firstName"
                  />
                </div>
                <div class="m-b-20">
                  <label class="labelInput">Nazwisko:</label>
                  <input
                    title="Nazwisko"
                    readonly="readonly"
                    class="form-control"
                    disabled="disabled"
                    name="last_name"
                    type="text"
                    :value="client.lastName"
                  />
                </div>
                <div class="m-b-20">
                  <label class="labelInput">E-mail:</label>
                  <input
                    title="Email"
                    readonly="readonly"
                    class="form-control"
                    disabled="disabled"
                    name="email"
                    type="text"
                    :value="client.email"
                  />
                </div>
                <div class="m-b-20">
                  <label class="labelInput">Numer telefonu:</label>
                  <input
                    title="Numer telefonu"
                    readonly="readonly"
                    class="form-control"
                    disabled="disabled"
                    name="phone"
                    type="text"
                    :value="client.phone"
                  />
                </div>
                <div class="checkbox-container m-b-5">
                  <label class="checkbox checkboxSmallLabel diet-checkbox">
                    <input
                      title="Zgoda"
                      class="form-control"
                      name="agreement"
                      type="checkbox"
                      disabled
                      checked
                    />
                    <span class="box checkbox-disabled">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                  </label>
                  <collapsed-text
                    class="checkboxSmallLabel"
                    style="margin-top: -15px"
                    lead="Wyrażam zgodę na przetwarzanie moich danych osobowych"
                    append-text="przez Republika Smakoszy spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (05-105) przy ul. Twardej 18 zgodnie z zasadami <a href='https://republikasmakoszy.pl/polityka-prywatnosci' target='_blank'>Polityki Prywatności</a>."
                  ></collapsed-text>
                </div>
                <div class="checkbox-container m-b-5 m-t-20">
                  <label class="checkbox checkboxSmallLabel diet-checkbox">
                    <input
                      title="Zgoda"
                      class="form-control"
                      name="agreement"
                      type="checkbox"
                      value="1"
                      v-model="emailMarketingAgreement"
                    />
                    <span class="box"
                      ><i
                        class="fa fa-check"
                        aria-hidden="true"
                        :class="{ 'fa-check': emailMarketingAgreement }"
                      ></i
                    ></span>
                  </label>
                  <collapsed-text
                    class="checkboxSmallLabel"
                    style="margin-top: -15px"
                    lead="Wyrażam zgodę na otrzymywanie drogą elektroniczną (adres e-mail) wiadomości o treściach handlowych i marketingowych"
                    append-text="wysyłanych przez Republika Smakoszy spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (05-105) przy ul. Twardej 18."
                  ></collapsed-text>
                </div>
                <div class="checkbox-container m-b-5 m-t-20">
                  <label class="checkbox checkboxSmallLabel diet-checkbox">
                    <input
                      title="Zgoda"
                      class="form-control"
                      name="agreement"
                      type="checkbox"
                      value="1"
                      v-model="phoneMarketingAgreement"
                    />
                    <span class="box"
                      ><i
                        class="fa fa-check"
                        aria-hidden="true"
                        :class="{ 'fa-check': phoneMarketingAgreement }"
                      ></i
                    ></span>
                  </label>
                  <collapsed-text
                    class="checkboxSmallLabel"
                    style="margin-top: -15px"
                    lead="Wyrażam zgodę na otrzymywanie telefonicznie oraz SMS wiadomości o treściach handlowych i marketingowych"
                    append-text="wysyłanych przez Republika Smakoszy spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (05-105) przy ul. Twardej 18."
                  ></collapsed-text>
                </div>
                <div class="m-b-20 order_myData-btnWrapper">
                  <button
                    class="button-red max-w-250"
                    type="submit"
                    @click="updateMarketingAgreement()"
                  >
                    Zapisz zgodę
                  </button>
                </div>
              </div>

              <div>
                <h3 class="text-uppercase labelLarge m-b-20 text-left">
                  <i class="fa fa-lock" aria-hidden="true"></i>Zmiana hasła:
                </h3>
                <p
                  v-if="
                    passwordUpdated &&
                    changePassword.password === '' &&
                    changePassword.newPassword.first === '' &&
                    changePassword.newPassword.second === ''
                  "
                  class="plg-ni_order_register_configurator-discountSuccess subTitle text-success text-center"
                >
                  <strong>Hasło zostało zmienione</strong>
                </p>

                <div class="m-b-20">
                  <label class="labelInput">Stare hasło:</label>
                  <input
                    class="form-control"
                    type="password"
                    name="current_password"
                    placeholder="Stare hasło"
                    v-model="changePassword.password"
                    @input="removeValidationError"
                    autocomplete="new-password"
                  />
                  <p
                    class="subTitle text-danger plg-ni_order_register_userData-first_name-error"
                    v-if="errors.password"
                  >
                    {{ Object.values(errors.password).join(' ,') }}
                  </p>
                </div>

                <div class="m-b-20">
                  <label class="labelInput">Nowe hasło:</label>
                  <input
                    class="form-control"
                    type="password"
                    name="password"
                    placeholder="Nowe hasło"
                    v-model="changePassword.newPassword.first"
                    @input="removeValidationError"
                    autocomplete="new-password"
                  />
                  <p
                    class="subTitle text-danger plg-ni_order_register_userData-first_name-error"
                    v-if="errors.newPassword.first"
                  >
                    {{ Object.values(errors.newPassword.first).join(' ,') }}
                  </p>
                </div>

                <div class="m-b-20">
                  <label class="labelInput">Powtórz nowe hasło:</label>
                  <input
                    class="form-control"
                    type="password"
                    name="password_confirmation"
                    placeholder="Powtórz nowe hasło"
                    v-model="changePassword.newPassword.second"
                    @input="removeValidationError"
                    autocomplete="new-password"
                  />
                  <p
                    class="subTitle text-danger plg-ni_order_register_userData-first_name-error"
                    v-if="errors.newPassword.second"
                  >
                    {{ Object.values(errors.newPassword.second).join(' ,') }}
                  </p>
                </div>
                <div class="m-b-20 order_myData-btnWrapper text-right">
                  <button
                    class="button-red max-w-250"
                    type="submit"
                    @click="updatePassword"
                  >
                    zmień hasło
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div class="m-b-40 fv-checkbox">
                <label class="checkbox checkboxSmallLabel diet-checkbox">
                  <input
                    type="checkbox"
                    class="form-control"
                    name="invoice_agreement"
                    v-model="fv.needFv"
                  />
                  <span class="box"
                    ><i
                      class="fa fa-check"
                      aria-hidden="true"
                      :class="{ 'fa-check': fv.needFv }"
                    ></i
                  ></span>
                </label>
                <b>Chcę otrzymywać fakturę</b>
              </div>

              <div
                class="m-b-40-ni_order_summary-fieldContainer"
                id="invoiceCheck"
                style="display: none"
                v-show="fv.needFv"
              >
                <div style="width: 100%">
                  <input id="order_id" type="hidden" value="61490" />
                  <p>Wpisz NIP</p>

                  <b
                    ><input
                      class="form-control cursor-pointer bold"
                      style="max-width: 450px; font-weight: normal"
                      placeholder="Wpisz NIP"
                      name="nip"
                      type="text"
                      v-model="fv.nip"
                  /></b>
                  <div
                    class="subTitle text-danger plg-ni_order_register_configurator-name-error"
                  >
                    {{ fv.nipError }}
                  </div>
                </div>
              </div>

              <div class="m-b-20 order_myData-btnWrapper" @click="updateNip">
                <button class="button-red max-w-250" type="submit">
                  zapisz dane do faktury
                </button>
              </div>
            </div>

            <div
              class="text-center labelNormal cursor-pointer centered"
              @click="deactivateAccount"
            >
              Usuń konto i dane
            </div>
            <!-- /END data -->
          </div>
          <!-- /END form -->

          <!-- title -->
          <h4 class="text-center labelNormal">
            Jeżeli chcesz zmienić swoje dane skontaktuj się z nami.
          </h4>
          <!-- /END title -->
        </div>
      </div>

      <!--======== cookesBox ========-->
      <div class="cookseBox" id="cookseBox">
        <a onclick="cookieOff('ntfy', 1, 360);" class="cookesBox__close">
          <i class="fa fa-close"></i>
        </a>

        <p class="cookseBox__content">
          Ciasteczka to jedyna niedietetyczna rzecz u nas. Korzystamy z nich w
          celu świadczenia usług. Zmiana ustawień plików cookie jest możliwa w
          każdym momencie.
        </p>
      </div>
      <!--======== /END cookesBox ========-->
      <div class="dialog" style="display: none; opacity: 0"></div>
      <div
        class="dialog-content"
        style="display: none; opacity: 0; top: -100px; background: #ffe123"
      >
        <a href="javascript:void(0)" class="js-close-button pop-up__close">
          &nbsp;
        </a>

        <!-- content-box -->
        <div class="content-box content-box--mr-none"></div>
      </div>
    </main>
    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '@/components/HeaderClientView'
import FooterClientView from '@/components/FooterClientView'
import CollapsedText from '@/components/CollapsedText'
import ClientAddressBlock from '@/components/ClientAddressBlock'
import * as ActionTypes from '@/store/actions_types'
import UserApi from '@/services/api/user'
import MarginMixin from '../../helpers/mixins'
import auth from '../../services/api/auth'

export default {
  components: {
    HeaderClientView,
    FooterClientView,
    CollapsedText
  },
  mixins: [MarginMixin],
  name: 'UserMyData',
  mounted() {
    this.$store.dispatch(ActionTypes.FETCH_CLIENT_DATA).then(() => {
      this.emailMarketingAgreement = this.client.emailMarketingAgreement
      this.phoneMarketingAgreement = this.client.phoneMarketingAgreement
      this.fv.needFv = this.client.needFv
      this.fv.nip = this.client.nip
    })
  },
  data() {
    return {
      passwordUpdated: false,
      canChangePassword: false,
      emailMarketingAgreement: false,
      phoneMarketingAgreement: false,
      addressTypes: ['STANDARD', 'FESTIVE'],
      changePassword: {
        password: '',
        newPassword: {
          first: '',
          second: ''
        }
      },
      fv: {
        needFv: false,
        nip: null,
        nipError: null
      },
      errors: {
        password: {},
        newPassword: {}
      }
    }
  },
  computed: {
    client() {
      return this.$store.getters.getClient
    }
  },
  methods: {
    updateNip() {
      this.fv.nip = this.fv.needFv ? this.fv.nip : null
      UserApi.updateNeedFvAndNip(this.fv.needFv, this.fv.nip)
        .then(() => {
          this.fv.nipError = null
          this.$swal({
            type: 'success',
            customClass: 'oldNtfyModal',
            title: 'INFORMACJA',
            showCloseButton: true,
            text: 'Zmiany zostały zapisane'
          })
        })
        .catch(e => {
          this.fv.nipError = e.response.data.errors.nip[1]
        })
    },
    deactivateAccount() {
      const swal = this.$swal
      const deactivated = this.deactivated
      swal({
        customClass: 'modal__deactivate-account',
        title: 'Czy na pewno chcesz usunąć konto i dane?',
        showCancelButton: true,
        width: 400,
        showCloseButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        reverseButtons: true,
        confirmButtonText: 'Tak, usuwam konto i dane',
        cancelButtonText: 'Nie, nie usuwam konta i danych',
        preConfirm: function () {
          return new Promise(function (resolve) {
            UserApi.deactivate()
              .then(() => {
                deactivated('Twoje konto zostało usunięte', true)
              })
              .catch(function (error) {
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.messages
                ) {
                  error = error.response.data.messages.join(', ')
                } else {
                  error = 'Coś poszło nie tak'
                }
                deactivated(error)
              })
              .finally(() => {
                resolve()
              })
          })
        }
      })
    },
    deactivated(title, status = false) {
      this.$swal({
        customClass: 'modal__deactivate-account',
        title,
        timer: 4000,
        width: 400,
        showConfirmButton: false
      }).then(() => {
        if (status) {
          auth.logout()
        }
      })
    },
    updatePassword() {
      this.errors = { password: {}, newPassword: {} }
      this.passwordUpdated = false
      let data = {
        password: this.changePassword.password,
        newPassword: this.changePassword.newPassword
      }
      UserApi.changePassword({ data })
        .then(res => {
          if (res.status === 204) {
            this.passwordUpdated = true
            this.changePassword.password = ''
            this.changePassword.newPassword.first = ''
            this.changePassword.newPassword.second = ''
          }
        })
        .catch(errRes => {
          if (errRes.response.status === 422) {
            Object.assign(this.errors, errRes.response.data.errors)
          }
        })
    },
    updateMarketingAgreement() {
      UserApi.updateMarketingAgreement(
        this.emailMarketingAgreement,
        this.phoneMarketingAgreement
      )
        .then(() => {
          this.$swal({
            type: 'success',
            customClass: 'oldNtfyModal',
            title: 'INFORMACJA',
            showCloseButton: true,
            text: 'Zmiany zostały zapisane'
          })
        })
        .catch(e => {
          this.handleError(e)
        })
    },
    removeValidationError() {
      this.errors.password = {}
      this.errors.newPassword = {}
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1280px;
}

.order_myData-btnWrapper.text-right {
  text-align: right !important;
}

span.checkboxSmallLabel {
  margin-top: -15px;
  padding-left: 43px;
  display: block;
  font-size: 15px;
}

.checkbox-container {
  min-height: 42px;
}
</style>
