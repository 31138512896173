export const Index = 'Index'
export const CalendarBrowser = 'CalendarBrowser'
export const OrderCreate = 'OrderCreate'
export const OrderAddMeal = 'OrderAddMeal'
export const OrderConfirm = 'OrderConfirm'
export const OrderThanks = 'OrderThanks'
export const AddMealThanks = 'AddMealThanks'
export const OrderTransfer = 'OrderTransfer'
export const OrderHistory = 'OrderHistory'
export const UserMyData = 'UserMyData'
export const UserPartnerProgram = 'UserPartnerProgram'
export const OrderFromPartnerProgram = 'OrderFromPartnerProgram'
export const UserMoneyboxIndex = 'UserMoneyboxIndex'
export const UserMoneyboxReplenishment = 'UserMoneyboxReplenishment'
export const UserMoneyboxWithdrawal = 'UserMoneyboxWithdrawal'
export const LogIn = 'LogIn'
export const Authorize = 'Authorize'
export const Remind = 'Remind'
export const RemindChangePassword = 'RemindChangePassword'
export const Register = 'Register'
export const Agreement = 'Agreement'
export const UserData = 'UserData'
export const UserAddresses = 'UserAddresses'
export const UserAddress = 'UserAddress'
export const OrderAddress = 'OrderAddress'
export const FAQ = 'FAQ'
export const Setup = 'Setup'
