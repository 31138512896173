<template>
  <header class="navbar-logged-in__container">
    <nav class="navbar-logged-in">
      <div class="navbar-logged-in__menu-wrapper">
        <div class="navbar-logo">
          <!--          <router-link-->
          <!--            :to="{ name: routeCollection.CalendarBrowser }"-->
          <!--            class=""-->
          <!--            active-class="active"-->
          <!--          >-->
          <!--            <img src="/img/logo_rs.png" />-->
          <!--          </router-link>-->
          <a :href="lpUrl"><img src="/img/logo_rs.png" /></a>
        </div>

        <div class="hamburger-menu__container">
          <div class="toggle" @click="showMenu = !showMenu">
            <div class="hamburger"></div>
          </div>
        </div>

        <ul :class="['navbar-menu', { visible: showMenu }]">
          <li class="link">
            <router-link
              :to="{ name: routeCollection.CalendarBrowser }"
              active-class="active"
              >{{ $t('menu.calendar') }}
            </router-link>
          </li>
          <li class="link">
            <router-link
              :to="{ name: routeCollection.OrderCreate }"
              active-class="active"
              >{{ $t('menu.new_order') }}
            </router-link>
          </li>
          <li class="link">
            <router-link
              :to="{ name: routeCollection.UserAddresses }"
              active-class="active"
              >{{ $t('menu.user_address') }}
            </router-link>
          </li>
          <li class="link">
            <router-link
              :to="{ name: routeCollection.UserMyData }"
              active-class="active"
              >{{ $t('menu.my_data') }}
            </router-link>
          </li>
          <li class="link">
            <router-link
              :to="{ name: routeCollection.OrderHistory }"
              active-class="active"
              >{{ $t('menu.order_history') }}
            </router-link>
          </li>
          <li class="link">
            <router-link
              :to="{ name: routeCollection.UserPartnerProgram }"
              active-class="active"
              >{{ $t('menu.invite_friends') }}
            </router-link>
          </li>
          <li class="link">
            <router-link
              :to="{ name: routeCollection.UserMoneyboxIndex }"
              active-class="active"
            >
              {{ $t('menu.moneybox') }}:
              <strong>
                {{ moneybox }}
              </strong>
            </router-link>
          </li>
          <li class="link log-out">
            <button class="button-red" @click="logout($event)">wyloguj</button>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <!--  <header class="header header-client user-login">-->
  <!--    <div class="impersonator" v-if="$store.getters.isImpersonated">-->
  <!--      Jesteś zalogowany jako administrator na koncie użytkownika-->
  <!--      <button @click="logout($event)">Zakończ</button>-->
  <!--    </div>-->

  <!--    <nav class="navbar">-->
  <!--      <div-->
  <!--        v-if="showNewAppMsg"-->
  <!--        class="new-diet-promo earth-day-msg new-app-msg"-->
  <!--      >-->
  <!--        <div class="new-app-msg__container">-->
  <!--          <div>-->
  <!--            Pobierz naszą aplikację przez Google Play lub App Store i rezerwuj-->
  <!--            dietę jeszcze wygodniej!-->
  <!--          </div>-->

  <!--          <div>-->
  <!--            <a-->
  <!--              href="https://play.google.com/store/apps/details?id=pl.netinteractive.ntfy"-->
  <!--              target="_blank"-->
  <!--              ><img src="/images/googlePlay.svg" alt=""-->
  <!--            /></a>-->
  <!--            <a-->
  <!--              href="https://apps.apple.com/pl/app/ntfy-nice-to-fit-you/id1246308752?l=pl"-->
  <!--              target="_blank"-->
  <!--              ><img src="/images/appleStore.svg" alt=""-->
  <!--            /></a>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      <div class="container no-padding">-->
  <!--        <div class="navbar-header navbar-header-client">-->
  <!--          <button-->
  <!--            type="button"-->
  <!--            class="navbar-toggle collapsed"-->
  <!--            data-toggle="collapse"-->
  <!--            data-target="#navbar-collapse"-->
  <!--            @click="menuCollapsed = !menuCollapsed"-->
  <!--          >-->
  <!--            <span class="sr-only">Toggle navigation</span>-->
  <!--            <span class="icon-bar top-bar"></span>-->
  <!--            <span class="icon-bar middle-bar"></span>-->
  <!--            <span class="icon-bar bottom-bar"></span>-->
  <!--          </button>-->
  <!--          <router-link-->
  <!--            :to="{ name: routeCollection.CalendarBrowser }"-->
  <!--            class="navbar-brand logo"-->
  <!--            active-class="active"-->
  <!--          >-->
  <!--            <img src="/img/logo_rs.png" />-->
  <!--          </router-link>-->
  <!--        </div>-->

  <!--        <div-->
  <!--          class="collapse navbar-collapse navbar-collapse-client-header"-->
  <!--          id="navbar-collapse"-->
  <!--          :style="{ display: menuCollapsedDisplay }"-->
  <!--        >-->
  <!--          <ul class="nav navbar-nav">-->
  <!--            <li>-->
  <!--              <router-link-->
  <!--                :to="{ name: routeCollection.CalendarBrowser }"-->
  <!--                active-class="active"-->
  <!--                >{{ $t('menu.calendar') }}-->
  <!--              </router-link>-->
  <!--            </li>-->
  <!--            <li>-->
  <!--              <router-link-->
  <!--                :to="{ name: routeCollection.OrderCreate }"-->
  <!--                active-class="active"-->
  <!--                >{{ $t('menu.new_order') }}-->
  <!--              </router-link>-->
  <!--            </li>-->
  <!--            <li>-->
  <!--              <router-link-->
  <!--                :to="{ name: routeCollection.UserMyData }"-->
  <!--                active-class="active"-->
  <!--                >{{ $t('menu.my_data') }}-->
  <!--              </router-link>-->
  <!--            </li>-->
  <!--            <li>-->
  <!--              <router-link-->
  <!--                :to="{ name: routeCollection.OrderHistory }"-->
  <!--                active-class="active"-->
  <!--                >{{ $t('menu.order_history') }}-->
  <!--              </router-link>-->
  <!--            </li>-->
  <!--            <li>-->
  <!--              <router-link-->
  <!--                :to="{ name: routeCollection.UserPartnerProgram }"-->
  <!--                active-class="active"-->
  <!--                >{{ $t('menu.invite_friends') }}-->
  <!--              </router-link>-->
  <!--            </li>-->
  <!--          </ul>-->

  <!--          <ul class="nav navbar-nav navbar-right">-->
  <!--            <li>-->
  <!--              <router-link-->
  <!--                :to="{ name: routeCollection.UserMoneyboxIndex }"-->
  <!--                active-class="active"-->
  <!--              >-->
  <!--                {{ $t('menu.moneybox') }}:-->
  <!--                <strong>-->
  <!--                  {{ moneybox }}-->
  <!--                </strong>-->
  <!--              </router-link>-->
  <!--            </li>-->

  <!--            <li class="header-exit">-->
  <!--              <a href="#" @click="logout($event)" style="padding: 20px"-->
  <!--                ><img src="/img/wyloguj.png"-->
  <!--              /></a>-->
  <!--            </li>-->
  <!--          </ul>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </nav>-->
  <!--  </header>-->
</template>

<script>
import auth from '../services/api/auth'
import * as RouteCollection from '../router/route_collection'
import * as ActionTypes from '../store/actions_types'
import { pathsWithLeavingWarning } from '@/consts'

export default {
  name: 'HeaderClientView',
  data() {
    return {
      routeCollection: RouteCollection,
      menuCollapsed: false,
      showMenu: false,
      lpUrl: this.getLpUrl()
    }
  },
  mounted() {
    const blackFridayPromoBanners = document.getElementsByClassName(
      'new-diet-promo'
    )

    if (blackFridayPromoBanners.length) {
      blackFridayPromoBanner[0].style.display = 'flex'
    }
  },
  computed: {
    moneybox() {
      let moneybox =
        this.$store.getters.jwtDecoded.moneybox === null
          ? 0
          : this.$store.getters.jwtDecoded.moneybox
      return moneybox.toFixed(2)
    },
    menuCollapsedDisplay() {
      return this.menuCollapsed ? 'block' : 'none'
    },
    showNewAppMsg() {
      return this.$date().isBetween(
        '2021-08-24 00:00:00',
        '2021-09-07 23:59:59'
      )
    }
  },
  methods: {
    logout(event) {
      event.preventDefault()
      const currentRoute = window.location.pathname
      const showWarning = pathsWithLeavingWarning.some(el =>
        currentRoute.includes(el)
      )

      const answer = showWarning
        ? window.confirm(
            'Czy na pewno chcesz opuścić stronę bez zapisania zmian?'
          )
        : true

      if (answer) {
        auth.logout()
      }
    },
    refreshState() {
      this.$store.dispatch(ActionTypes.RESET_CONFIGURATOR_FIRST_CORRECT_STATE)
    },
    getLpUrl() {
      return process.env.VUE_APP_LANDING_PAGE_URL
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: Soehne;
  src: url(/fonts/soehne-breit-web-kraftig.woff);
}

.impersonator {
  width: 100%;
  text-align: center;
  background: #222222;
  color: #fff;
}

.impersonator button {
  background: #222222;
  font-size: 20px;
  color: #ffe123;
  outline: none;
  border: none;
}

.new-diet-promo {
  font-family: Helvetica-Bold, sans-serif;
  border-bottom: 1px solid #444;
  font-size: 22px;
  background-color: #000;
  color: white;
  text-align: center;

  display: none;
  justify-content: center;
}

.new-diet-promo > div {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding: 12px 30px;
}

.new-diet-promo div span.yellow-text {
  color: #ffe023;
}

.new-diet-promo a > span {
  text-transform: uppercase;
}

.new-diet-promo a span {
  color: white;
}

.new-diet-promo img ~ span {
  color: blue;
}

.new-diet-promo.earth-day-msg {
  background-color: #d7ffc6;
  color: #000000;
}

.new-app-msg {
  display: flex;
  flex-direction: column;
}

.new-app-msg .new-app-msg__container > div:nth-child(2) {
  margin-top: 10px;
}

.new-app-msg .new-app-msg__container > div:nth-child(2) > a:nth-child(2) {
  margin-left: 8px;
}

@media (max-width: 750px) {
  .new-diet-promo span {
    font-size: 14px;
    line-height: 0;
  }

  .new-diet-promo.earth-day-msg {
    font-size: 14px;
  }
}
</style>
