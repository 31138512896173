import Vue from 'vue'
import Router from 'vue-router'
import CalendarBrowser from '@/pages/calendar/Browser'
import OrderCreate from '@/pages/order/Create'
import OrderAddMeal from '@/pages/order/AddMeal'
import OrderConfirm from '@/pages/order/Confirm'
import OrderThanks from '@/pages/order/Thanks'
import AddMealThanks from '@/pages/order/AddMealThanks'
import OrderTransfer from '@/pages/order/Transfer'
import OrderHistory from '@/pages/order/History'
import UserData from '@/pages/order/UserData'
import RefererProgram from '@/pages/RefererProgram'
import UserMoneyboxIndex from '@/pages/user/moneybox/Index'
import UserMoneyboxReplenishment from '@/pages/user/moneybox/Replenishment'
import UserMoneyboxWithdrawal from '@/pages/user/moneybox/Withdrawal'
import UserMyData from '@/pages/user/MyData'
import UserAddresses from '@/pages/user/UserAddresses'
import UserAddressPage from '@/pages/user/UserAddressPage'
import UserPartnerProgram from '@/pages/user/PartnerProgram'
import LogIn from '@/pages/user/LogIn'
import Setup from '@/pages/Setup'
import Authorize from '@/pages/user/Authorize'
import Remind from '@/pages/user/Remind'
import RemindChangePassword from '@/pages/user/RemindChangePassword'
import Register from '@/pages/user/Register'
import store from '@/store'
import AuthApi from '@/services/api/auth'
import * as RouteCollection from './route_collection'
import * as MutationTypes from '../store/mutations_types'
import auth from '../services/api/auth'
import UserApi from '@/services/api/user'
import * as ActionTypes from '@/store/actions_types'
import { FETCH_CLIENT_DATA_ONCE } from '@/store/actions_types'
import * as types from '@/store/mutations_types'

Vue.use(Router)
const pagesWithLandingPage = [
  'https://ntfy.pl',
  'https://ntfy-vue.nidev.pl',
  'https://stage.front.ntfy.pl'
]

const beforeEnterAddress = async function (to, from, next) {
  const { data: addresses } = await UserApi.fetchAddresses()
  if (addresses.length > 0 && to.params.addressId) {
    const defaultAddress = addresses.find(
      address => parseInt(to.params.addressId, 10) === address.id
    )
    if (defaultAddress) {
      if (defaultAddress.packageLeavingOption) {
        defaultAddress.packageLeavingOption =
          defaultAddress.packageLeavingOption.id
      }
      to.params.defaultAddress = defaultAddress
    }
  }
  next()
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: RouteCollection.Index,
      beforeEnter(to, from, next) {
        if (
          !auth.isAuthorized() &&
          pagesWithLandingPage.includes(process.env.VUE_APP_LANDING_PAGE_URL)
        ) {
          window.location.href = process.env.VUE_APP_LANDING_PAGE_URL
        } else {
          window.location.href = '/user/log-in'
        }
      }
    },
    {
      path: '/calendar/browser',
      name: RouteCollection.CalendarBrowser,
      component: CalendarBrowser,
      meta: {
        requiresAuth: true
      },
      beforeEnter(to, from, next) {
        if (to.query.day) {
          store.commit(MutationTypes.UPDATE_ACTIVE_DAY, to.query.day)
        }
        next()
      }
    },
    {
      path: '/order/create',
      name: RouteCollection.OrderCreate,
      component: OrderCreate,
      meta: {
        requiresAuth: true,
        beforeEnterActions: [ActionTypes.FETCH_CLIENT_DATA_ONCE]
      }
    },
    {
      path: '/order/add-meal',
      name: RouteCollection.OrderAddMeal,
      component: OrderAddMeal,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/order/confirm/:id',
      name: RouteCollection.OrderConfirm,
      component: OrderConfirm,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/order/thanks/:id',
      name: RouteCollection.OrderThanks,
      component: OrderThanks,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/order/add-meal/thanks',
      name: RouteCollection.AddMealThanks,
      component: AddMealThanks,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/order/add-meal/thanks/:id',
      name: RouteCollection.AddMealThanks,
      component: AddMealThanks,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/order/transfer/:id',
      name: RouteCollection.OrderTransfer,
      component: OrderTransfer,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/order/history',
      name: RouteCollection.OrderHistory,
      component: OrderHistory,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/order/user-data',
      name: RouteCollection.UserData,
      component: UserData,
      meta: {
        requiresAuth: true,
        beforeEnterActions: [ActionTypes.FETCH_CLIENT_DATA_ONCE]
      }
    },
    {
      path: '/user/address/:addressId?',
      name: RouteCollection.UserAddress,
      component: UserAddressPage,
      props: true,
      meta: {
        requiresAuth: true,
        beforeEnterActions: [ActionTypes.FETCH_CLIENT_DATA_ONCE]
      },
      beforeEnter: beforeEnterAddress
    },
    {
      path: '/order/address/:addressId?',
      name: RouteCollection.OrderAddress,
      component: UserAddressPage,
      props: true,
      meta: {
        requiresAuth: true,
        beforeEnterActions: [ActionTypes.FETCH_CLIENT_DATA_ONCE]
      },
      beforeEnter: beforeEnterAddress
    },
    {
      path: '/user/my-data',
      name: RouteCollection.UserMyData,
      component: UserMyData,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/addresses',
      name: RouteCollection.UserAddresses,
      component: UserAddresses,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/partner-program',
      name: RouteCollection.UserPartnerProgram,
      component: UserPartnerProgram,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/moneybox',
      name: RouteCollection.UserMoneyboxIndex,
      component: UserMoneyboxIndex,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/moneybox/replenishment',
      name: RouteCollection.UserMoneyboxReplenishment,
      component: UserMoneyboxReplenishment,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/moneybox/withdrawal',
      name: RouteCollection.UserMoneyboxWithdrawal,
      component: UserMoneyboxWithdrawal,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/log-in',
      name: RouteCollection.LogIn,
      component: LogIn,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/user/authorize/:token/:impersonatorToken',
      name: RouteCollection.Authorize,
      component: Authorize,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/user/remind',
      name: RouteCollection.Remind,
      component: Remind,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/user/remind-change-password/:token',
      name: RouteCollection.RemindChangePassword,
      component: RemindChangePassword,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/user/register',
      name: RouteCollection.Register,
      component: Register,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/polecam/:code',
      name: RouteCollection.OrderFromPartnerProgram,
      component: RefererProgram,
      meta: {
        requiresAuth: false,
        redirectToMyAccount: false
      }
    },
    {
      path: '/setup',
      name: RouteCollection.Setup,
      component: Setup,
      meta: {
        requiresAuth: false,
        redirectToMyAccount: false
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const host = window.location.href.toLowerCase()
  const www = 'www.'

  if (host.includes(www)) {
    window.location.href = host.replace(www, '')
    return
  }

  for await (const record of to.matched) {
    if (undefined !== record.meta.beforeEnterActions) {
      for await (const action of record.meta.beforeEnterActions) {
        await store.dispatch(action)
      }
    }
  }

  if (auth.isAuthorized()) {
    window.dataLayer.push({
      event: 'userID',
      userID: store.getters.jwtDecoded.id
    })

    if (
      to.matched.some(
        record =>
          !record.meta.requiresAuth &&
          (typeof record.meta.redirectToMyAccount === 'undefined' ||
            record.meta.redirectToMyAccount === true)
      )
    ) {
      // console.log('TO BROWSER')
      // console.log('to.matched:', to.matched)
      next({ name: RouteCollection.CalendarBrowser })
    }
    AuthApi.refresh()
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      store.commit(MutationTypes.UPDATE_REDIRECT_AFTER_LOGIN, to.name)
      next({ name: RouteCollection.LogIn })
      return
    }
  }

  next()
})

export default router
