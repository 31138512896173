<template>
  <div>
    <div class="calendar_main-address">
      <div>
        <h5>Adres dostawy:</h5>
        <ul>
          <li>
            ul. {{ selectedAddress.street }} {{ selectedAddress.buildingNr }}
            {{ selectedAddress.placeNr ? ' / ' + selectedAddress.placeNr : '' }}
          </li>
          <li>{{ selectedAddress.postCode }} {{ selectedAddress.city }}</li>
        </ul>
      </div>
      <div>
        <h5>Godziny dostawy:</h5>
        <ul class="mb-3">
          <li>{{ $t(deliveryDay) }} {{ actualDeliveryDate() }}</li>
          <li>
            {{ addressTime }}
          </li>
        </ul>

        <button
          v-if="isDeliveryChangePossible"
          class="button-outlined"
          type="button"
          @click.stop="intentToChangeAddress"
          v-html="$t('change')"
        />
      </div>
    </div>
    <div v-if="showAddresses">
      <h3>Wybierz adres dostawy:</h3>
      <div v-for="a in addresses" :key="a.id">
        <div
          class="address-card"
          :class="{ active: activeAddress(a) }"
          @click="changeAddress(a)"
        >
          <div class="flex justify-content-space-between">
            <h4 class="mb-3">
              {{ addressName(a) }}
            </h4>
            <span class="text-active" v-if="activeAddress(a)"> Wybrany </span>
          </div>

          <p class="mb-1 font-smaller">{{ a.street }} {{ a.buildingNr }}</p>
          <p class="mb-1 font-smaller">{{ a.postCode }} {{ a.city }}</p>
          <p
            class="mb-1 font-smaller"
            v-if="a.deliveryHourFrom || a.deliveryHourTo"
          >
            {{ $t('Delivery') }}
            <span v-if="a.deliveryHourFrom">
              {{ $t('from hrs.') }} {{ a.deliveryHourFrom }}
            </span>
            <span v-if="a.deliveryHourTo">
              {{ $t('to hrs.') }} {{ a.deliveryHourTo }}
            </span>
          </p>
          <div class="mb-2 mt-3" v-if="a.isStandardByDefault">
            <span v-html="$t('standard')" class="tag uppercase"></span>
          </div>
          <div class="mb-2 mt-3" v-if="a.isSaturdayByDefault">
            <span v-html="$t('saturday')" class="tag uppercase"></span>
          </div>
        </div>
      </div>
      <button class="button-black mt-3" @click="addAddress">
        <b v-html="$t('add_new_address')" class="uppercase"></b>
      </button>
    </div>
    <ComponentDialog v-model="addNewAddressDialog">
      <UserAddress @after="afterAddedAddress" />
    </ComponentDialog>
  </div>
</template>

<script>
import UserApi from '@/services/api/user'
import UserAddress from '@/pages/user/UserAddress.vue'
import ComponentDialog from '@/containers/ComponentDialog.vue'

export default {
  name: 'MenuPlannerAddress',
  components: { UserAddress, ComponentDialog },
  props: {
    address: {
      type: Object,
      default: function () {
        return {}
      }
    },
    deliveryDate: null,
    isDeliveryChangePossible: null,
    bag: Number,
    selectedDiet: null,
    deliveryDay: null
  },
  data() {
    return {
      addNewAddressDialog: false,
      selectedAddress: this.address,
      showAddresses: false,
      addresses: []
    }
  },
  computed: {
    addressTime() {
      let addressTime = ''
      if (
        this.address.deliveryHourFrom === null &&
        this.address.deliveryHourTo === null
      ) {
        addressTime = ''
      } else if (this.address.deliveryHourFrom === null) {
        addressTime =
          'do ' + this.$date(this.address.deliveryHourTo).format('HH:mm')
      } else if (this.address.deliveryHourTo === null) {
        addressTime =
          'od ' + this.$date(this.address.deliveryHourFrom).format('HH:mm')
      } else {
        addressTime =
          this.$date(this.address.deliveryHourFrom).format('HH:mm') +
          ' - ' +
          this.$date(this.address.deliveryHourTo).format('HH:mm')
      }

      return addressTime
    }
  },
  watch: {
    bag() {
      this.showAddresses = false
    }
  },
  methods: {
    addAddress() {
      this.addNewAddressDialog = true
    },
    async afterAddedAddress(payload) {
      const {
        data: { addresses_ids }
      } = payload
      this.addNewAddressDialog = false
      await this.fetchAddresses()
      if (addresses_ids.length === 1) {
        const newAddress = this.addresses.find(a => a.id === addresses_ids[0])
        await this.changeAddress(newAddress)
      }
    },
    addressName(a) {
      return a.name || `${a.street} ${a.buildingNr}`
    },
    actualDeliveryDate(format = 'DD.MM.YYYY') {
      if (this.deliveryDay === 'niedziela') {
        return this.$date(this.deliveryDate).add(-1, 'days').format(format)
      }

      if (this.$date(this.deliveryDate).format('YYYY-MM-DD') === '2020-12-24') {
        return this.$date(this.deliveryDate).add(-1, 'days').format(format)
      }

      return this.$date(this.deliveryDate).format(format)
    },
    setAddresses(addresses) {
      this.addresses = addresses
        .sort((a, b) =>
          a.isSaturdayByDefault === true && b.isSaturdayByDefault === false
            ? -1
            : 0
        )
        .sort((a, b) =>
          a.isStandardByDefault === true && b.isStandardByDefault === false
            ? -1
            : 0
        )
        .sort((a, b) =>
          this.activeAddress(a) && !this.activeAddress(b) ? -1 : 0
        )
    },
    activeAddress(a) {
      return this.selectedAddress?.id === a.id
    },
    async intentToChangeAddress() {
      if (!this.showAddresses) {
        await this.fetchAddresses()
      }
      this.showAddresses = !this.showAddresses
    },
    async fetchAddresses() {
      const {
        data: { address }
      } = await UserApi.getBagDelivery(this.bag)
      this.selectedAddress = address
      const { data: addresses } = await UserApi.fetchAddresses()
      this.setAddresses(addresses)
    },
    async changeAddress(a) {
      if (this.selectedDiet && !this.activeAddress(a)) {
        this.$swal({
          customClass: 'oldNtfyModal',
          title: this.$t('warning'),
          showCloseButton: true,
          showDenyButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('dialog.button.ok_change'),
          denyButtonText: this.$t('dialog.button.cancel_change'),
          html: this.$t(
            'dialog.confirm.intent_to_change_address_for_date_and_diet',
            { addressName: this.addressName(a) }
          ),
          showConfirmButton: true
        }).then(async result => {
          if (result.isConfirmed) {
            await UserApi.patchBagDelivery(this.bag, {
              address: a.id
            })
            this.$swal({
              customClass: 'oldNtfyModal',
              title: 'INFORMACJA',
              showCloseButton: true,
              text: this.$t('dialog.delivery_address_was_changed')
            })
            this.selectedAddress = a
            this.setAddresses(this.addresses)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.tag {
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
}
.address-card {
  border: 2px solid #dbe0eb;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.address-card.active {
  border: 2px solid #ff4068;
}
.text-active {
  color: #ff4068;
}
</style>
