<template>
  <div class="page-layout">
    <header-client-view></header-client-view>

    <main
      class="main main__history"
      :class="{ 'expanded-navbar': addMarginToMain }"
    >
      <div class="container">
        <div class="users_myDataForm plg-ni_users_myDataForm">
          <div class="plg-ni_order_userHistory">
            <div class="order_history">
              <h1 class="diet-configuration__header text-uppercase text-center">
                Historia
              </h1>

              <h2 class="text-uppercase text-center" v-if="!items.length">
                Brak historii
              </h2>

              <div
                v-else
                class="user-transaction-history__wrapper white-background p-4"
              >
                <div
                  v-for="item in items"
                  :key="item.id"
                  class="user-transaction-history__single-transaction"
                >
                  <div>
                    <h5 class="title">
                      {{ transactionTitle(item) }}
                    </h5>
                    <small
                      >Nr {{ item.id }} z dnia
                      {{ formatDate(item.createdAt) }}</small
                    >
                  </div>

                  <div>
                    <small>Status</small>
                    <h5
                      class="title"
                      :class="'text-' + status(item).toLowerCase()"
                    >
                      {{ status(item) }}
                    </h5>
                  </div>

                  <div class="user-transaction-history__btn-wrapper">
                    <button
                      class="button-red"
                      type="submit"
                      alt="Edycja nie jest możliwa"
                      @click="edit(item.diet.id)"
                      :disabled="
                        !['ORDER_PAID', 'ORDER_PREPAID'].includes(
                          item.paymentStatus.systemValue
                        ) || item.diet === null
                      "
                    >
                      Edytuj
                    </button>

                    <button
                      v-if="
                        ['ORDER_PARTIAL_PAID', 'ORDER_NOT_PAID'].includes(
                          item.paymentStatus.systemValue
                        )
                      "
                      class="button-red"
                      @click="cancelOrder(item.id)"
                    >
                      Odwołaj
                    </button>
                  </div>
                </div>
              </div>

              <div v-if="page < pageCount">
                <div class="text-center order_history-btnWrapper">
                  <a
                    class="btn slick-next plg-ni_order_userHistory-showMore inheritColor"
                    href="#"
                    @click="next($event)"
                    >Pokaż więcej</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '@/components/HeaderClientView'
import FooterClientView from '@/components/FooterClientView'
import order from '@/services/api/order'
import * as actions from '@/store/actions_types'
import * as RouteCollection from '@/router/route_collection'
import auth from '@/services/api/auth'
import MarginMixin from '../../helpers/mixins'

export default {
  components: { HeaderClientView, FooterClientView },
  mixins: [MarginMixin],
  name: 'history',
  data() {
    return {
      items: [],
      page: 1,
      pageCount: null,
      totalItemCount: null
    }
  },
  mounted() {
    this.load(this.page)
    // this.$gtm.enable(false)
  },
  methods: {
    formatDate(date) {
      return this.$date(date).format('YYYY-MM-DD')
    },
    load(page) {
      order.fetchOrders(page).then(
        response => {
          let orders = response.data

          this.items = this.items.concat(orders.items)
          this.page = orders.page
          this.pageCount = orders.pageCount
          this.totalItemCount = orders.totalItemCount
        },
        e => {
          this.handleError(e, false)
        }
      )
    },
    next(event) {
      event.preventDefault()
      this.load(++this.page)
    },
    edit(dietId) {
      this.$store.dispatch(actions.SELECT_DIET, dietId)
      this.$router.push({ name: RouteCollection.CalendarBrowser })
    },
    confirm(orderId) {
      this.$router.push({
        name: RouteCollection.OrderConfirm,
        params: { id: orderId }
      })
    },
    thanks(orderId) {
      this.$router.push({
        name: RouteCollection.OrderThanks,
        params: { id: orderId }
      })
    },
    cancelOrder(orderId) {
      let r = confirm(this.$t('order.cancel'))

      if (r === true) {
        order.cancelOrder(orderId).then(() => {
          this.page = 1
          this.items = []
          this.load(this.page)
          auth.refresh()

          this.$swal({
            type: 'success',
            customClass: 'oldNtfyModal',
            title: this.$t('info'),
            showCloseButton: true,
            text: this.$t('order.canceled')
          })
        })
      }
    },
    status(item) {
      if (
        item.paymentStatus.systemValue !== 'ORDER_CANCELED' &&
        item.paymentStatus.systemValue !== 'ORDER_FINISHED'
      ) {
        if (item.contactWithUs) {
          return this.$t('status.contact_with_us')
        }

        if (item.active) {
          return this.$t('status.active')
        }

        if (item.waitingForBankWirePayment) {
          return this.$t('status.waiting_for_bank_wire_payment')
        }
      }

      return item.paymentStatus.value
    },
    transactionTitle(transaction) {
      if (transaction.diet) {
        return transaction.dietName
      } else {
        if (transaction.dietName) {
          return `Dokupienie do diety ${transaction.dietName}`
        } else {
          return 'Doładowanie skarbonki'
        }
      }
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1280px;
}
</style>
