<template>
  <div class="page-layout">
    <header-client-view></header-client-view>

    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <div class="container replenishment-wrapper">
        <div class="moneybox">
          <!--          <h1 class="moneybox__header text-center">Doładuj skarbonkę</h1>-->
          <h1 class="diet-configuration__header text-uppercase text-center">
            Doładuj konto
          </h1>

          <div
            class="moneybox__paragraph moneybox__withdrawal-container white-background p-4"
          >
            <p class="replenishment-paragraph">
              <span>Jeśli chcesz doładować swoje konto, </span>
              <strong>wpisz dowolną kwotę</strong> i kliknij ,,Doładuj’’.
            </p>

            <form class="row replenishment-form" @submit.prevent="pay">
              <div>
                <div class="row form-line">
                  <div class="custom-input__wrapper">
                    <input
                      type="text"
                      class="form-control"
                      :class="[value !== '' ? 'hide-placeholder' : '']"
                      pattern="^\d+\.{0,1}\d{0,2}$"
                      v-model="value"
                      @input="validateNumber"
                    />
                    <span class="custom-input__placeholder"
                      >Wpisz kwotę, np. 2 500</span
                    >
                  </div>
                  <div class="pln">
                    <span>zł</span>
                  </div>
                </div>
              </div>
              <div class="moneybox__replenishment-btn-wrapper">
                <button
                  :disabled="value === ''"
                  class="btn btn-custom"
                  type="submit"
                >
                  DOŁADUJ
                </button>
              </div>
            </form>

            <div class="moneybox__paragraph payu-info">
              <p>Płatności obsługiwane przez PayU.</p>
            </div>

            <div class="moneybox__payment-icons">
              <img src="/images/visa.jpg" alt="visa" />
              <img src="/images/mcSc.jpg" alt="mastercard" />
              <img src="/images/ssl.jpg" alt="ssl secure" />
              <img src="/images/pci_dss.jpg" alt="pci dss certified" />
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '@/components/HeaderClientView'
import FooterClientView from '@/components/FooterClientView'
import * as actionTypes from '@/store/actions_types'
import order from '@/services/api/order'
import MarginMixin from '../../../helpers/mixins'

export default {
  components: { HeaderClientView, FooterClientView },
  mixins: [MarginMixin],
  data() {
    return {
      value: ''
    }
  },
  watch: {
    value: function (val) {
      if (val.charAt(0) === '0' || val.charAt(0) === '.') {
        this.value = val.slice(1)
      }

      let fractionalPart = this.value.split('.')[1]

      if (fractionalPart.length > 2) {
        this.value = val.slice(0, -1)
      }
    }
  },
  methods: {
    pay() {
      this.$store
        .dispatch(actionTypes.FETCH_MONEYBOX_REPLENISHMENT, {
          walletBoost: { price: this.value }
        })
        .then(id => {
          this.goPayu(id)
        })
        .catch(this.showFailureMessage)
    },
    goPayu: function (id) {
      order
        .fetchOrderPaymentDetails(id)
        .then(response => {
          window.location = response.data.redirectUri
        })
        .catch(this.showFailureMessage)
    },
    showFailureMessage() {
      this.$swal({
        type: 'success',
        customClass: 'modal__replenishment failure',
        title: 'NIEPOWODZENIE',
        showCloseButton: true,
        html:
          'Doładowanie skarbonki nie powiodło się. <span>Spróbuj ponownie później.</span>',
        confirmButtonText: 'ZAMKNIJ'
      })
    },
    validateNumber(event) {
      const pattern = /^\d+\.{0,1}\d{0,2}$/
      if (!pattern.test(event.target.value)) {
        this.value = this.value.slice(0, -1)
      }
    }
  }
}
</script>
