import Vue from 'vue'
import VueI18n from 'vue-i18n'

/**
 * Import Language
 */
import pl from './i18n/pl_PL'
import en from './i18n/en_EN'

/**
 * Config
 */
Vue.use(VueI18n)

const messages = {
  en: {
    ...en
  },
  pl: {
    ...pl
  }
}
/**
 * Export
 */
export default new VueI18n({
  locale: 'pl',
  messages
})
