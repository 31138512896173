/** Configurator **/
export const FETCH_CONFIGURATION = 'FETCH_CONFIGURATION'
export const FETCH_DIET_PRICE = 'FETCH_DIET_PRICE'
export const FETCH_MEALS_PRICE = 'FETCH_MEALS_PRICE'
export const FETCH_SELECTED_DIET = 'FETCH_SELECTED_DIET'
export const CHANGE_CONFIGURATOR_BASKET = 'CHANGE_CONFIGURATOR_BASKET'
export const CHANGE_CONFIGURATOR_MEAL_TYPES = 'CHANGE_CONFIGURATOR_MEAL_TYPES'
export const CHANGE_CONFIGURATOR_PACKAGE = 'CHANGE_CONFIGURATOR_PACKAGE'
export const CHANGE_CONFIGURATOR_VARIANT = 'CHANGE_CONFIGURATOR_VARIANT'
export const CHANGE_CONFIGURATOR_DIET_LENGTH = 'CHANGE_CONFIGURATOR_DIET_LENGTH'
export const CHANGE_ADDON_MEALS = 'CHANGE_ADDON_MEALS'
export const RESET_CONFIGURATOR_FIRST_CORRECT_STATE =
  'RESET_CONFIGURATOR_FIRST_CORRECT_STATE'

/** User **/
export const FETCH_MONEYBOX_HISTORY = 'FETCH_MONEYBOX_HISTORY'
export const FETCH_MONEYBOX_WITHDRAWAL = 'FETCH_MONEYBOX_WITHDRAWAL'
export const FETCH_MONEYBOX_REPLENISHMENT = 'FETCH_MONEYBOX_REPLENISHMENT'
export const FETCH_CLIENT_DATA = 'FETCH_CLIENT_DATA'
export const FETCH_CLIENT_DATA_ONCE = 'FETCH_CLIENT_DATA_ONCE'
export const FETCH_CLIENT_ADDRESSES = 'FETCH_CLIENT_ADDRESSES'

/** Tags **/
export const FETCH_COMING_SOURCE = 'FETCH_COMING_SOURCE'

/** Order **/
export const FETCH_ORDER = 'FETCH_ORDER'

/** Clear store **/
export const CLEAR_STORE = 'CLEAR_STORE'

/** Calendar **/
export const SELECT_DIET = 'SELECT_DIET'
export const LOAD_CALENDAR = 'LOAD_CALENDAR'
export const LOAD_BAG_DETAILS = 'LOAD_BAG_DETAILS'
export const LOAD_MEAL_BADGES = 'LOAD_MEAL_BADGES'
export const SELECT_BAG = 'SELECT_BAG'
export const SELECT_DAY = 'SELECT_DAY'
export const SET_DATA_FROM_LP = 'SET_DATA_FROM_LP'
export const SET_SELECTED_DIET_VARIANT = 'SET_SELECTED_DIET_VARIANT'
