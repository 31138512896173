<template>
  <div class="select" @keydown.esc="close()">
    <div
      :class="[
        'ui-selectmenu-button',
        'ui-widget',
        'ui-state-default',
        'ui-corner-top ui-state-hover',
        { disabled: disabled }
      ]"
      :id="'ui-id-selected-' + value.id"
      style="width: 100%"
      @click.stop="open()"
    >
      <span class="select-icon" :class="{ opened: opened }"></span>
      <span class="ui-selectmenu-text">
        <span class=""></span>
        {{ value.name }}
      </span>
    </div>

    <div class="ui-selectmenu-menu ui-front ui-selectmenu-open" v-show="opened">
      <ul class="ui-menu ui-widget ui-widget-content ui-corner-bottom">
        <li
          class="ui-menu-item ui-state-focus"
          :id="'ui-id-' + item.id"
          v-for="item in items"
          :key="item.variantId + item.id"
          @click.stop="select(item)"
        >
          <img
            v-if="!!item.badge"
            :src="item.badge.url"
            :alt="item.badge.name_for_client"
          />
          <span v-html="formatTextWithHashTags(item.name)"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectStyled',
  props: {
    disabled: {
      default: false
    },
    items: {
      type: Array,
      default: function () {
        return []
      }
    },
    value: {
      type: Object,
      default: function () {
        return {
          id: null,
          value: 'Nie ustalono menu'
        }
      }
    }
  },
  data() {
    return {
      opened: false
    }
  },
  computed: {},
  mounted() {
    document.body.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.close()
      }
    })
    document.body.addEventListener('click', e => {
      this.close()
    })
  },
  methods: {
    formatTextWithHashTags(text) {
      return text.replace(/#\w+/g, '<strong>$&</strong>')
    },
    open() {
      if (this.disabled) {
        return
      }

      if (this.opened === true) {
        this.opened = false
      } else {
        document.getElementsByTagName('body')[0].click()
        this.opened = true
      }
    },
    close() {
      this.opened = false
    },
    select(item) {
      this.$emit('input', item)
      this.close()
    }
  }
}
</script>
