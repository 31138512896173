<template>
  <div class="col-md-7 calendar_main-calendar-wrapper">
    <div class="diet-select__container">
      <div class="diet-select__input-wrapper">
        <v-select
          label="name"
          :options="items"
          v-model="selectedDietId"
          :reduce="item => item.id"
          :clearable="false"
        >
          <template #no-options>Brak pasujących opcji</template>
        </v-select>
      </div>

      <div class="diet-select__variant-info">
        <p>Twoja domyślna dieta to:</p>
        <p>{{ variantName }}</p>
      </div>
    </div>

    <div class="calendar_main-calendar">
      <div class="calendar_main-calendarTitle">
        <a @click="prevMonth" class="plg-ni_calendar_Calendar-prev">
          <img src="/img/left.png" alt="" />
        </a>
        <h3>{{ displayData }}</h3>
        <a @click="nextMonth" class="plg-ni_calendar_Calendar-next">
          <img src="/img/right.png" alt="" />
        </a>
      </div>

      <div class="calendar_main-calendarDayTitle plg-ni_calendar_Calendar-box">
        <span>pon.</span>
        <span>wt.</span>
        <span>śr.</span>
        <span>czw.</span>
        <span>pt.</span>
        <span>sob.</span>
        <span>niedz.</span>
      </div>

      <div class="calendar_main-calendarDays clearfix" v-if="calendar">
        <span
          class="calendarDay muted plg-ni_calendar_Calendar-day"
          v-for="n in calendar.pushDays"
          :key="n + 236"
        >
          <i class="day__number"> &nbsp; </i>
        </span>
        <span
          class="calendarDay muted plg-ni_calendar_Calendar-day"
          :class="{
            'menu ': day.bag !== null && !day.isDelivered,
            active:
              day.bag !== null && $store.getters.getSelectedBag === day.bag,
            rate: day.isDelivered && !day.isRated && canRateNow(day),
            wait:
              !day.isDelivered &&
              day.isMenuPrepared &&
              !day.isMenuChanged &&
              dateGt(day.canChangeMenuTo),
            selected:
              !day.isDelivered &&
              (day.isMenuChanged || !dateGt(day.canChangeMenuTo)),
            eaten: day.isDelivered && (day.isRated || !canRateNow(day))
          }"
          v-for="day in calendar.days"
          :key="day.day"
          @click="selectDay(day)"
        >
          <i class="day__number">
            <p>{{ formatCalendarDay(day.day) }}</p>
          </i>
        </span>
        <span
          class="calendarDay muted plg-ni_calendar_Calendar-day"
          v-for="n in calendar.pushDaysAtEnd"
          :key="n + 321"
        >
        </span>
      </div>
    </div>

    <h4 class="subTitle">
      <span class="iconDownload"></span
      ><a href="#" @click.prevent="loadAllergenReport"
        >Pobierz PDF ze składnikami i alergenami</a
      >
    </h4>

    <h4 class="subTitle">
      <span class="iconStar"></span>mamy nadzieję, że Ci smakowało, oceń nasze
      posiłki
    </h4>

    <h4 class="subTitle"><span class="iconMeal"></span>wybrałeś już menu</h4>

    <h4 class="subTitle">
      <span class="iconMealGray"></span>menu jest już gotowe, dowolnie
      konfiguruj swoje posiłki
    </h4>

    <div v-if="showCalendarLegend" class="calendar__legend">
      <p>
        Poniższe oznaczenia pomogą Ci wybrać menu dopasowane do Twoich potrzeb:
      </p>
      <p><b>#LG</b> – Less Gluten – potrawy z ograniczoną ilością glutenu</p>
      <p><b>#DF</b> – Dairy Free – dania wykluczające nabiał</p>
      <p><b>#lowIG</b> – dania z obniżonym indeksem glikemicznym</p>
      <p><b>#vege</b> – dania bez mięsa</p>
      <p><b>#vege+fish</b> – dania wegetariańskie, które zawierają ryby</p>
    </div>
  </div>
</template>

<script>
import * as MutationTypes from '../store/mutations_types'
import * as ActionTypes from '../store/actions_types'
import routes from '../services/api/routes'
import axios from '../interceptor'
import * as actions from '@/store/actions_types'

export default {
  name: 'MenuPlannerCalendar',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedDiet: {
      type: Object,
      default: null
    }
  },
  computed: {
    selectedDietId: {
      get() {
        return this.$store.state.diet.selected
      },
      set(val) {
        this.$store.dispatch(actions.SELECT_DIET, val)
      }
    },
    calendar() {
      return this.$store.getters.getCalendar
    },
    selected() {
      return this.$store.state.diet.selected
    },
    getBagDetails() {
      return this.$store.getters.getBagDetails
    },
    getDate() {
      return this.getBagDetails.bag.deliveryDate
    },
    packageName() {
      return this.selectedDiet ? this.selectedDiet.package.name : ''
    },
    variantName() {
      return this.selectedDiet ? this.selectedDiet.defaultVariant.name : ''
    },
    selectedDate: {
      get() {
        return this.$store.getters.getViewDate
      },
      set(val) {
        this.$store.commit(MutationTypes.UPDATE_CALENDAR_VIEW_DATE, val)
      }
    },
    selectedBag: {
      get() {
        return this.$store.getters.getSelectedBag
      },
      set(val) {
        this.$emit('bagSelected', val)
      }
    },
    selectedDay: {
      get() {
        return this.$store.getters.getSelectedDay
      },
      set(val) {
        this.$store.dispatch(ActionTypes.SELECT_DAY, val)
        this.$emit('daySelected', val)
      }
    },
    showCalendarLegend() {
      const now = this.$date()
      const day = this.$date('2021-03-18')
      return now.isSameOrAfter(day)
    }
  },
  data() {
    return {
      firstCalendarLoaded: false,
      displayData: ''
    }
  },
  watch: {
    calendar(newCalendar) {
      if (newCalendar && !this.firstCalendarLoaded) {
        this.$store.commit(
          MutationTypes.UPDATE_CALENDAR_VIEW_DATE,
          this.$date(newCalendar.activeDay || this.$date().format('YYYY-MM-DD'))
        )
        this.displayData = this.selectedDate.format('MMMM YYYY')
        this.$store.dispatch(ActionTypes.LOAD_CALENDAR)
        this.firstCalendarLoaded = true
      } else {
        this.displayData = this.selectedDate.format('MMMM YYYY')
      }
    },
    selected() {
      this.firstCalendarLoaded = false
    }
  },
  mounted() {
    this.displayData = this.selectedDate.format('MMMM YYYY')
  },
  methods: {
    dateGt(a, b = this.$date().unix()) {
      return this.$date(a).unix() > b
    },
    formatDate(date) {
      return this.$date(date).format('YYYY-MM-DD')
    },
    loadAllergenReport() {
      axios({
        url: routes.allergenReport(
          process.env.VUE_APP_BRAND_ID,
          this.$date(this.getDate).format('YYYY-MM-DD')
        ),
        method: 'GET',
        responseType: 'blob'
      }).then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'RS_raport_skladnikow_alergenow.pdf')
        document.body.appendChild(link)
        link.click()
      })
    },
    prevMonth() {
      this.$store.commit(MutationTypes.UPDATE_CALENDAR_DE_INCREMENT_VIEW_DATE)
      this.displayData = this.selectedDate.format('MMMM YYYY')
      this.$store.dispatch(ActionTypes.LOAD_CALENDAR)
    },
    nextMonth() {
      this.$store.commit(MutationTypes.UPDATE_CALENDAR_INCREMENT_VIEW_DATE)
      this.displayData = this.selectedDate.format('MMMM YYYY')
      this.$store.dispatch(ActionTypes.LOAD_CALENDAR)
    },
    selectBag(bagId) {
      if (bagId === null) {
        return
      }

      this.selectedBag = bagId
    },
    selectDay(day) {
      if (day === null || day.bag === null) {
        return
      }

      this.selectedDay = day
      this.selectedBag = day.bag
    },
    canRateNow(day) {
      let now = this.$date().unix()
      let to = this.$date(day.canRateTo).unix()
      let from = this.$date(day.canRateFrom).unix()
      return now < to && now > from
    },
    formatCalendarDay(day) {
      return day >= 10 ? day : `0${day}`
    }
  }
}
</script>

<style scoped lang="scss">
.calendar_main-calendarDays .menu > i {
  color: #303030;
}

.calendar_main-calendarDays .rate > i {
  color: #ced4e1;
}

.diet-details-wrapper {
  display: flex;
}
@media (max-width: 991px) {
  .diet-details-wrapper {
    display: block;
  }
}

@media (min-width: 992px) {
  .diet-details-wrapper {
    justify-content: space-between;

    .calendar_main-table {
      display: block;
      margin-right: 10px;
      width: 100%;
      max-width: 240px;
    }

    & > .calendar_main-cell {
      width: 100%;
      max-width: 170px;

      .title {
        padding-right: 0;
      }
    }
  }
}
</style>
