var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"diet-configuration__container"},[_c('div',{staticClass:"diet-configuration__content-wrapper"},[_c('h2',{staticClass:"text-center mb-8"},[_vm._v("Wybierz domyślny wariant")]),_c('div',{staticClass:"meals-grid"},_vm._l((_vm.variants),function(variant){return _c('div',{key:variant.id,staticClass:"box-container"},[_c('div',{class:[
            'variant-container',
            _vm.selectedVariant && variant.id === _vm.selectedVariant.id
              ? 'selected-variant'
              : ''
          ]},[_c('div',{staticClass:"variant-container__info-section",on:{"click":function($event){return _vm.selectVariant(variant)}}},[_c('div',[_c('img',{attrs:{"src":("/img/" + (_vm.selectedVariant && variant.id === _vm.selectedVariant.id
                    ? 'checked.png'
                    : 'unchecked.png')),"alt":""}})]),_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(variant.name))]),_c('div',[_c('img',{attrs:{"src":_vm.imageLink(variant.image),"alt":""}})]),_c('div',{staticClass:"text-center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showDescription.includes(variant.id)),expression:"!showDescription.includes(variant.id)"}]},[_vm._v(" "+_vm._s(variant.title)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDescription.includes(variant.id)),expression:"showDescription.includes(variant.id)"}],domProps:{"innerHTML":_vm._s(variant.clientDescription)}})])]),_c('div',{staticClass:"info-section-switcher",on:{"click":function($event){return _vm.showVariantDescription(variant)}}},[_c('p',[_vm._v(" "+_vm._s(_vm.showDescription.includes(variant.id) ? 'wróć' : 'więcej o diecie')+" ")]),_c('img',{attrs:{"src":"/img/arrow-black-circle.png","alt":""}})])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }