<template>
  <footer class="footer client-log-in">
    <h4>© {{ year }} REPUBLIKA SMAKOSZY. Wszelkie prawa zastrzeżone.</h4>
  </footer>
</template>

<script>
export default {
  name: 'FooterClientView',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
