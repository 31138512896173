<template>
  <div class="page-layout">
    <header-client-view></header-client-view>

    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <div class="ajax-loader-start js-ajax-load"></div>
      <div class="container">
        <div class="order_transfer">
          <header-h1 title="Dziękujemy za złożenie zamówienia"></header-h1>

          <h3 class="text-center text-uppercase labelLarge">
            Dane do przelewu:
          </h3>

          <table class="order_transfer-table table bottomSm">
            <tr>
              <td>Nazwa odbiorcy:</td>
              <td>NTFY</td>
            </tr>
            <tr>
              <td>Kwota:</td>
              <td>{{ priceToPay }}</td>
            </tr>
            <tr>
              <td>Numer konta:</td>
              <td>54 1030 0019 0109 8503 3002 4099</td>
            </tr>
            <tr>
              <td>Tytuł przelewu:</td>
              <td>Zamówienie numer {{ orderNumber }}</td>
            </tr>
          </table>

          <h4 class="text-center labelNormal">
            <strong>
              Prosimy o jak najszybszą wpłatę i przesłanie do nas potwierdzenia
              przelewu na
              <a href="mailto:hello@ntfy.pl"> hello@ntfy.pl </a>!
            </strong>
            <br />
            Jeśli dokonasz wpłaty zbyt późno, możemy nie zdążyć zrealizować
            dostawy w pierwszym wskazanym przez Ciebie dniu!
          </h4>

          <h4 class="text-center labelNormal">
            Jeżeli masz jakieś pytania skontaktuj się z nami pod numerem
            telefonu
            <strong>728 604 000</strong>
          </h4>

          <div class="text-center order_transfer-btnWrapper">
            <router-link :to="{ name: 'CalendarBrowser' }" class="btn">
              Przejdź do swojego profilu
            </router-link>
          </div>
        </div>
      </div>
    </main>

    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '@/components/HeaderClientView'
import FooterClientView from '@/components/FooterClientView'
import * as RouteCollection from '@/router/route_collection'
import * as actionTypes from '@/store/actions_types'
import HeaderH1 from '@/components/HeaderH1'
import MarginMixin from '../../helpers/mixins'

export default {
  components: { HeaderH1, HeaderClientView, FooterClientView },
  mixins: [MarginMixin],
  name: 'transfer',
  props: {
    id: null
  },
  data() {
    return {
      order: {}
    }
  },
  mounted() {
    this.fetchOrder(this.id)
    // this.$gtm.enable(false)
  },
  computed: {
    orderNumber: function () {
      return this.order.id
    },
    priceToPay: function () {
      return this.order.priceToPay
    }
  },
  methods: {
    fetchOrder: function (id) {
      this.$store.dispatch(actionTypes.FETCH_ORDER, { id: id }).then(
        order => {
          this.order = order
        },
        e => {
          this.handleError(e, false)
          router.push({ name: RouteCollection.OrderHistory })
        }
      )
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1280px;
}
</style>
