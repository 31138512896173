import moneyboxApi from './moneybox'
import myData from './my_data'
import address from './address'
import routes from '../routes'
import axios from '../../../interceptor'

export default {
  ...moneyboxApi,
  ...myData,
  ...address,
  remindPassword(email) {
    return axios.post(routes.remindPassword, { data: { email } })
  },
  remindPasswordChange(data) {
    return axios.post(routes.tokenChangePassword, { data })
  }
}
