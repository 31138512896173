<template>
  <Layout>
    <div class="container" :key="state" v-if="!isLoading()">
      <div class="row">
        <div class="col-sm-10 col-sm-offset-1">
          <div class="flex justify-content-space-between mb-8">
            <h2>{{ $t('My addresses') }}</h2>
            <button
              @click="
                () =>
                  this.$router.push({
                    name: UserAddress,
                    params: { step: 'main' }
                  })
              "
              type="submit"
              class="btn btn-md btn-outline btn-xsmall"
            >
              {{ $t('add_address') }}
            </button>
          </div>
          <template v-if="addresses.length > 0">
            <div class="flex-container">
              <template v-for="[i, address] of addresses.entries()">
                <div class="flex-item-6 mb-8" :key="i">
                  <UserAddressCard
                    :address="address"
                    :remount="remount"
                    :key="address.id"
                  />
                </div>
              </template>
            </div>
          </template>
          <div v-else class="col-sm-12 text-center empty-addresses-block">
            <h2 class="mb-3">Ale tu pusto!</h2>
            <div class="text-muted font-smaller">
              <p class="mb-1">
                {{ $t('Add your address and order your meals.') }}
              </p>
              <p>{{ $t('Saved addresses will appear here.') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Layout.vue'
import UserAddressCard from '@/pages/user/UserAddressCard.vue'
import UserApi from '@/services/api/user'
import { UserAddress } from '@/router/route_collection'

export default {
  name: 'UserMyData',
  components: {
    UserAddressCard,
    Layout
  },
  data() {
    return {
      UserAddress,
      addresses: [],
      state: 'initial-state'
    }
  },
  async mounted() {
    await this.fetch()
  },
  methods: {
    isLoading() {
      return this.$store.state.isLoading
    },
    async fetch() {
      const { data: addresses } = await UserApi.fetchAddresses()
      this.addresses = addresses.sort((a, b) =>
        (a.isSaturdayByDefault === true && b.isSaturdayByDefault === false) ||
        (a.isStandardByDefault === true && b.isStandardByDefault === false)
          ? -1
          : 0
      )
    },
    async remount(message) {
      await this.fetch()
      this.state = new Date().toString()

      if (message) {
        this.$swal({
          customClass: 'oldNtfyModal',
          title: this.$t('Success!'),
          showCloseButton: true,
          reverseButtons: true,
          html: message,
          showConfirmButton: true
        })
      }
    }
  }
}
</script>

<style scoped>
.empty-addresses-block {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  justify-content: center;
}
.btn-outline {
  border-radius: 4px;
  border: 1px solid rgba(60, 60, 60, 0.26);
}
</style>
