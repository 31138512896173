<template>
  <div>
    <!--    <h4 class="labelSmall"></h4>-->
    <h5 v-if="!day.isRated">Oceń wybrane posiłki</h5>
    <div>
      <div class="" v-for="(mealType, key) in mealTypes" :key="key">
        <div class="calendar_main-selectDark">
          <label class="labelInput">{{ mealType.name }}</label>
          <div>
            <div class="mealBox row">
              <div class="col-lg-7">
                <strong>{{ mealType.selected.variantName }}:</strong>
                {{ mealType.selected.name }}
              </div>
              <div class="col-lg-5">
                <div class="br-wrapper br-theme-fontawesome-stars">
                  <div class="br-widget">
                    <a
                      v-for="(i, iKey) in 5"
                      @click="setRate(key, 5 - iKey, !day.isRated)"
                      :key="iKey"
                      :data-rating-text="5 - iKey"
                      :id="'rate_' + mealType.selected.bagItemId"
                      :class="{
                        'br-selected br-current':
                          mealType.selected.rate >= 5 - iKey
                      }"
                    ></a>
                  </div>
                </div>
              </div>
              <transition v-if="!day.isRated">
                <div class="" v-if="lastRated === mealType.selected.bagItemId">
                  <textarea
                    placeholder="Dodaj komentarz"
                    class="form-control text_rate plg-ni_menu_rating-text_rate"
                    @input="updateSelectedComment($event.target.value, key)"
                  ></textarea>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!day.isRated">
      <button
        class="button-red"
        @click.prevent="saveRatings"
        style="margin: 20px 0"
      >
        zapisz oceny
      </button>
    </div>
  </div>
</template>

<script>
import * as MutationTypes from '../../store/mutations_types'
import * as ActionTypes from '../../store/actions_types'
import BagApi from '../../services/api/bag'
import AuthApi from '../../services/api/auth'

export default {
  name: 'DayRateForm',
  data() {
    return {
      lastRated: null
    }
  },
  props: {
    day: { type: Object }
  },
  computed: {
    bagDetails: function () {
      return this.$store.getters.getBagDetails
    },
    mealTypes: function () {
      return this.bagDetails.mealTypes
    }
  },
  methods: {
    setRate(el, rate, canEdit) {
      if (!canEdit) {
        return
      }

      if (this.mealTypes[el].selected.rate === rate) {
        this.lastRated = null
        this.$store.commit(MutationTypes.UPDATE_BAG_DETAILS_MEAL_RATE, {
          index: el,
          value: 0
        })
      } else {
        this.lastRated = this.mealTypes[el].selected.bagItemId
        this.$store.commit(MutationTypes.UPDATE_BAG_DETAILS_MEAL_RATE, {
          index: el,
          value: rate
        })
      }
    },
    saveRatings() {
      let allMealsRated = true
      let data = this.bagDetails.mealTypes.map(mt => {
        if (mt.selected.rate === 0) {
          allMealsRated = false
        }
        return {
          bagItem: mt.selected.bagItemId,
          rate: mt.selected.rate,
          comment: mt.selected.comment
        }
      })

      if (allMealsRated) {
        BagApi.saveRating(this.bagDetails.id, data)
          .then(() => {
            this.$store.dispatch('rateMeal', this.bagDetails.id)
          })
          .then(() => {
            this.$swal({
              type: 'success',
              customClass: 'oldNtfyModal',
              title: 'INFORMACJA',
              showCloseButton: true,
              text: 'Oceny zostały zapisane'
            })

            // this.$store
            //   .dispatch(ActionTypes.LOAD_CALENDAR, {
            //     viewDate: this.$store.getters.getViewDate,
            //     selectedDay: this.$date(this.bagDetails.bag.deliveryDate).date()
            //   })
            //   .then(() => {
            //     AuthApi.refresh()
            //   })
          })
          .catch(e => {
            this.handleError(e)
          })
      } else {
        this.$swal({
          type: 'warning',
          customClass: 'oldNtfyModal',
          showCloseButton: true,
          title: 'UWAGA',
          text: 'Musisz ocenić wszystkie posiłki'
        })
      }
    },
    updateSelectedComment($event, key) {
      this.$store.commit(MutationTypes.UPDATE_BAG_DETAILS_MEAL_COMMENT, {
        index: key,
        value: $event
      })
    }
  }
}
</script>
