<template>
  <div class="select" @keydown.esc="close()">
    <div
      :class="[
        'ui-selectmenu-button',
        opened ? 'select-yellow-border' : 'select-gray-border',
        'ui-corner-top ui-state-hover',
        { disabled: disabled }
      ]"
      :id="'ui-id-selected-' + value.id"
      style="width: 100%"
      @click.stop="open()"
    >
      <span class="ui-icon yellow-triangle-icon"></span>
      <div class="menu-mod-light-wrapper">
        <span class="menu-mod-light">
          <span class="bold-text">{{ value.nameForClient }}</span> - ok.
          {{ value.calorific }} kcal
        </span>
      </div>
    </div>
    <div class="select-menu__container" v-show="opened">
      <ul>
        <li
          :id="'ui-id-' + item.id"
          v-for="item in items"
          :key="'menuItem' + item.variantId + item.id"
          @click.stop="select(item)"
        >
          <span class="bold-text">{{ item.nameForClient }}</span> - ok.
          {{ item.calorific }} kcal
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '@/store/actions_types'

export default {
  name: 'SelectStyled',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: function () {
        return []
      }
    },
    value: {
      type: Object,
      default: function () {
        return {
          id: null,
          value: 'Nie ustalono menu'
        }
      }
    },
    selectInputIndex: {
      type: Number,
      default: 0
    },
    mealTypeName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      opened: false
    }
  },
  computed: {
    availableAddonMeals() {
      return this.$store.getters.getConfiguratorAvailableMeals
    }
  },
  mounted() {
    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.close()
      }
    })
    document.body.addEventListener('click', e => {
      this.close()
    })
  },
  methods: {
    open() {
      if (this.disabled) {
        return
      }

      if (this.opened === true) {
        this.opened = false
      } else {
        document.getElementsByTagName('body')[0].click()
        this.opened = true
      }
    },
    close() {
      this.opened = false
    },
    getSelectedAddonPrice(id) {
      const mealPrice = this.$store.getters.getConfiguratorMealsPrice.packages.find(
        ({ sizeId }) => sizeId === id
      )

      return mealPrice.price
    },
    select(item) {
      this.$emit('input', item)
      const bag = this.$store.getters.getBagDetails.bag.id
      const defaultPackageId = this.$store.getters.getSelectedDietId.id
      const mealAddonsArray = this.$store.getters.getConfiguratorSelectedMeals
      const selectInputIndex = this.selectInputIndex
      const deepCopy = JSON.parse(JSON.stringify(mealAddonsArray))

      const enhancedItem = {
        ...item,
        bag,
        package: defaultPackageId
      }

      // this.value = enhancedItem
      deepCopy[this.mealTypeName][selectInputIndex] = enhancedItem

      this.$store.dispatch(actionTypes.CHANGE_ADDON_MEALS, deepCopy)

      this.close()
    }
  }
}
</script>

<style scoped lang="scss">
.disabled {
  border-color: #424242;

  .ui-selectmenu-text {
    color: #cccccc;
  }
}

.yellow-triangle-icon {
  background: none;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px dashed #ff4068;
  display: inline-block;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  width: 0;
}

.menu-mod-light-wrapper {
  padding: 12px 14px;
}

.menu-list {
  position: absolute;
  width: 100%;
}

.select-gray-border {
  border: 2px solid #d4d4d4;
  background: transparent;
  border-radius: 3px;
}

.select-yellow-border {
  border-radius: 3px;
  border: solid 2px #ff4068;
  z-index: 1001;
}

.menu-mod-light {
  color: black;
  font-size: 14px;
}

.bold-text {
  font-weight: bold;
}

.select-menu__container {
  position: absolute;
  top: 45px;
  z-index: 1000;
  width: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  ul {
    list-style: none;
    padding: 8px 5px;
    margin: 0;

    li:not(:last-child) {
      margin-bottom: 10px;
    }

    li {
      font-family: Helvetica, sans-serif;
      font-size: 14px;
      line-height: 32px;
      color: #000000;
      cursor: pointer;
      padding: 0 10px;

      &:hover {
        background-color: #ff4068;
      }
    }
  }
}
</style>
