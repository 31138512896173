<template>
  <div class="page-layout">
    <header-client-view></header-client-view>
    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <slot></slot>
    </main>
    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '@/components/HeaderClientView.vue'
import FooterClientView from '@/components/FooterClientView.vue'
import MarginMixin from '@/helpers/mixins'

export default {
  name: 'Layout',
  mixins: [MarginMixin],
  components: {
    FooterClientView,
    HeaderClientView
  }
}
</script>
