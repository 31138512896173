<template>
  <div class="page-layout__logged-out">
    <header-index-view :fixed="true"></header-index-view>

    <main class="main center-content">
      <div class="container pop-up form-container">
        <h3 class="h4 h4--popup mb-5 title text-center">Zarejestruj się</h3>

        <form
          method="POST"
          @submit.prevent="register"
          accept-charset="UTF-8"
          class="plg-ni_user_register-formRegister"
        >
          <input
            name="_token"
            type="hidden"
            value="HGeXIwxpvdyfx08v4a0NKDmCy13DZhvmHRcaISYy"
          />
          <input name="partner" type="hidden" value="" />
          <div class="form-group">
            <input
              title="E-mail"
              placeholder="Adres e-mail"
              class="form-control form-control--main"
              name="email"
              type="text"
              v-model="registration.email"
            />
            <small
              class="text-danger plg-ni_order_register_userData-first_name-error"
            >
              {{ getErrors('email') }}
            </small>
          </div>

          <div class="form-group">
            <input
              title="Hasło"
              placeholder="Hasło"
              class="form-control form-control--main"
              name="password"
              type="password"
              v-model="registration.plainPassword.first"
            />
            <small
              class="text-danger plg-ni_order_register_userData-first_name-error"
            >
              {{ getErrors('plainPassword.first') }}
            </small>
          </div>

          <div class="form-group">
            <input
              title="Powtórz hasło"
              placeholder="Powtórz hasło"
              class="form-control form-control--main"
              name="password_confirmation"
              type="password"
              v-model="registration.plainPassword.second"
            />
            <small
              class="text-danger plg-ni_order_register_userData-first_name-error"
            >
              {{ getErrors('plainPassword.second') }}
            </small>
          </div>

          <ComponentDialog v-model="verificationCodeDialog">
            <VerificationCodeForm
              :countdown="countdown"
              v-model="registration.verificationCode"
              :phone="registration.phone"
              :error="getErrors('verificationCode')"
              @submit="register"
            />
          </ComponentDialog>

          <div
            class="flex justify-content-space-between align-items-center mb-3"
          >
            <b>Posiadam kod promocyjny</b>
            <SwitchToggle
              name="referrer-code-switcher"
              v-model="referrerCodeShow"
            />
          </div>

          <div v-if="referrerCodeShow">
            <div class="form-group" v-if="mgm">
              <input
                title="Kod polecający"
                placeholder="Kod polecający"
                class="form-control form-control--main"
                name="referrer_code"
                type="text"
                v-model="registration.referrerCode"
              />
              <small
                class="text-danger plg-ni_order_register_userData-first_name-error"
              >
                {{ getErrors('referrerCode') }}
              </small>
            </div>

            <div class="form-group" v-if="mgm">
              <PhoneInput name="phone" v-model="registration.phone" />

              <small
                v-if="getErrors('phone')"
                class="text-danger plg-ni_order_register_userData-first_name-error"
              >
                {{ getErrors('phone') }}
              </small>

              <p>
                <small style="text-align: left">
                  W kolejnym kroku, na podany numer telefonu wyślemy kod
                  potrzebny do weryfikacji kodu promocyjnego.
                </small>
              </p>
            </div>
          </div>

          <div class="register-page__checkbox-wrapper mb-2">
            <div class="register-page__checkbox">
              <input
                title="Zgoda_reg"
                name="accept_rules"
                type="checkbox"
                v-model="registration.rulesAgreement"
              />

              <span class="checkbox__text checkbox__text&#45;&#45;mod">
                <span class="text-white"
                  >Akceptuję
                  <a :href="lpUrl + '/regulamin-serwisu'" target="_blank"
                    >regulamin serwisu</a
                  >
                </span>
              </span>
            </div>

            <div class="register-page__checkbox-error">
              <small
                class="text-danger plg-ni_order_register_userData-first_name-error"
              >
                {{ getErrors('rulesAgreement') }}
              </small>
            </div>
          </div>

          <div class="register-page__checkbox-wrapper mb-2">
            <div class="register-page__checkbox">
              <input
                title="marketing_agreement"
                name="marketing_agreement"
                type="checkbox"
                v-model="registration.processingPersonalDataAgreement"
              />
              <collapsed-text
                class="checkboxSmallLabel"
                style="margin-top: -15px"
                lead="Wyrażam zgodę na przetwarzanie moich danych osobowych"
                append-text="przez Republika Smakoszy spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (05-105) przy ul. Twardej 18 zgodnie z zasadami <a href='https://republikasmakoszy.pl/polityka-prywatnosci' target='_blank'>Polityki Prywatności</a>."
              ></collapsed-text>
            </div>

            <div class="register-page__checkbox-error">
              <small
                class="text-danger plg-ni_order_register_userData-first_name-error"
              >
                {{ getErrors('processingPersonalDataAgreement') }}
              </small>
            </div>
          </div>

          <div class="register-page__checkbox-wrapper mb-2">
            <div class="register-page__checkbox">
              <input
                title="commercial_agreement"
                name="commercial_agreement"
                type="checkbox"
                v-model="registration.emailMarketingAgreement"
              />
              <collapsed-text
                class="checkboxSmallLabel"
                style="margin-top: -15px"
                lead="Wyrażam zgodę na otrzymywanie drogą elektroniczną (adres e-mail) wiadomości o treściach handlowych i marketingowych"
                append-text="wysyłanych przez Republika Smakoszy spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (05-105) przy ul. Twardej 18."
              ></collapsed-text>
            </div>

            <div class="register-page__checkbox-error">
              <small
                class="text-danger plg-ni_order_register_userData-first_name-error"
              >
                {{ getErrors('emailMarketingAgreement') }}
              </small>
            </div>
          </div>

          <div class="register-page__checkbox-wrapper mb-2">
            <div class="register-page__checkbox">
              <input
                title="commercial_agreement"
                name="commercial_agreement"
                type="checkbox"
                v-model="registration.phoneMarketingAgreement"
              />
              <collapsed-text
                class="checkboxSmallLabel"
                style="margin-top: -15px"
                lead="Wyrażam zgodę na otrzymywanie telefonicznie oraz SMS wiadomości o treściach handlowych i marketingowych"
                append-text="wysyłanych przez Republika Smakoszy spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (05-105) przy ul. Twardej 18."
              ></collapsed-text>
            </div>
            <div class="register-page__checkbox-error">
              <small
                class="text-danger plg-ni_order_register_userData-first_name-error"
              >
                {{ getErrors('phoneMarketingAgreement') }}
              </small>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-main btn-main--custom m-b-10 plg-ni_user_register-submitBtn new-btn login-btn"
            style="margin-top: 10px"
          >
            zarejestruj się
          </button>

          <button
            type="submit"
            class="btn btn-main btn-main--custom new-btn fb-btn"
            @click.prevent="facebook($event)"
            style="margin-left: 0"
          >
            Zarejestruj się przez facebooka
          </button>

          <input type="submit" style="display: none" />
        </form>
        <p class="text-white text-center" style="margin-top: 20px">
          <span class="register">
            Masz już profil?
            <router-link :to="{ name: routeCollection.LogIn }"
              ><b>Zaloguj się</b>.</router-link
            >
          </span>
        </p>
      </div>
    </main>

    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderIndexView from '../../components/HeaderIndexView'
import FooterClientView from '../../components/FooterClientView'
import * as RouteCollection from '../../router/route_collection'
import UserApi from '../../services/api/user'
import * as MutationTypes from '../../store/mutations_types'
import CollapsedText from '../../components/CollapsedText'
import axios from '../../interceptor'
import routes from '../../services/api/routes'
import SwitchToggle from '@/components/SwitchToggle.vue'
import VerificationCodeForm from '@/containers/VerificationCodeForm.vue'
import ComponentDialog from '@/containers/ComponentDialog.vue'
import PhoneInput from '@/pages/user/PhoneInput.vue'

export default {
  components: {
    PhoneInput,
    VerificationCodeForm,
    ComponentDialog,
    SwitchToggle,
    CollapsedText,
    HeaderIndexView,
    FooterClientView
  },
  name: 'Register',
  data: function () {
    return {
      verificationCodeDialog: false,
      verificationCodeTimeout: undefined,
      referrerCodeShow: false,
      routeCollection: RouteCollection,
      countdown: 0,
      countdownInterval: undefined,
      prevRegistration: undefined,
      registration: {
        plainPassword: {
          first: '',
          second: ''
        },
        emailMarketingAgreement: false,
        phoneMarketingAgreement: false,
        processingPersonalDataAgreement: false,
        referrerCode: null,
        verificationCode: undefined,
        phone: undefined
      },
      mgm: null,
      errors: {
        plainPassword: {}
      },
      lpUrl: this.getLpUrl()
    }
  },
  async mounted() {
    const mgm = await axios.get(routes.config).then(response => {
      if (undefined !== response.data && undefined !== response.data.mgm) {
        return response.data.mgm
      }

      return false
    })
    this.mgm = mgm

    if (
      !!this.$store.getters.getConfiguratorMgmCode &&
      !this.registration.referrerCode
    ) {
      this.registration.referrerCode = this.$store.getters.getConfiguratorMgmCode

      if (this.mgm) {
        this.referrerCodeShow = true
      }
    }
    this.prevRegistration = JSON.parse(JSON.stringify(this.registration))
  },
  watch: {
    registration: {
      handler: function (n) {
        const nn = Object.entries(n)
        const d = nn.find(([nnk, nnv]) => {
          if (this.prevRegistration) {
            if (
              JSON.stringify(this.prevRegistration[nnk]) !== JSON.stringify(nnv)
            ) {
              return true
            }
          }

          return false
        })

        if (undefined !== d) {
          this.errors[d[0]] = []
        }

        this.prevRegistration = JSON.parse(JSON.stringify(n))
      },
      deep: true
    },
    referrerCodeShow() {
      this.errors = {
        plainPassword: {}
      }
    }
  },
  methods: {
    register(verificationCode) {
      const data = { ...this.registration }
      if (false === verificationCode && undefined !== data.verificationCode) {
        delete data.verificationCode
      }

      if (!this.referrerCodeShow) {
        data.phone = undefined
        data.referrerCode = undefined
      }
      if (undefined === data.referrerCode) {
        delete data.referrerCode
      }
      if (undefined === data.verificationCode) {
        delete data.verificationCode
      }
      if (
        !this.registration.phone ||
        this.registration.phone.toString()?.length === 0
      ) {
        delete data.phone
      } else {
        if (data && data.phone) {
          data.phone = `+48${data.phone.toString()}`
        }
      }

      this.errors.verificationCode = []
      this.countdown = 32
      clearInterval(this.countdownInterval)
      this.countdownInterval = setInterval(() => {
        this.countdown--
        if (this.countdown <= 0) {
          clearInterval(this.countdownInterval)
        }
      }, 1000)

      UserApi.register({ data })
        .then(res => {
          this.$store.commit(MutationTypes.UPDATE_TOKEN, res.data)

          window.dataLayer.push({
            event: 'register',
            eventCategory: 'Customer',
            eventAction: 'Register',
            eventLabel: 'Direct'
          })

          this.$router.push({ name: RouteCollection.OrderCreate })
        })
        .catch(e => {
          this.handleError(e, false)
          if (e.response.status === 403) {
            this.verificationCodeDialog = true
          }

          if (e.response.status === 422) {
            const errors = e.response.data.errors
            if (this.referrerCodeShow && !this.registration.referrerCode) {
              if (undefined === errors.referrerCode) {
                errors.referrerCode = []
              }
              errors.referrerCode.push('The field is required')
            }

            this.errors = errors
            if (this.getErrors('phone')) {
              this.verificationCodeDialog = false
            }
          }
        })
    },
    getErrors(field) {
      let BreakException = {}

      try {
        let state = this.errors
        if (field.includes('.')) {
          field.split('.').forEach(function (path) {
            if (!state.hasOwnProperty(path)) {
              throw BreakException
            }
            state = state[path]
          })
          return Object.values(state).join(', ')
        } else {
          if (!state.hasOwnProperty(field)) {
            throw BreakException
          }
          state = state[field]
          const errors = Object.values(state)
          return errors.length > 0
            ? this.$t(errors[0]).replace(/\.$/, '') + '.'
            : null
        }
      } catch (e) {
        if (e !== BreakException) throw e
        return null
      }
    },
    getLpUrl() {
      return process.env.VUE_APP_LANDING_PAGE_URL
    }
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval)
  }
}
</script>

<style scoped>
label {
  text-transform: none;
}
</style>
