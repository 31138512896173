<template>
  <label>
    <slot />
    <span v-if="!isRequired"> (opcjonalnie)</span>
    <sup v-else style="color: red">*</sup>:
  </label>
</template>

<script>
export default {
  name: 'FormLabel',
  props: {
    isRequired: {
      type: Boolean
    }
  }
}
</script>
