<template>
  <div class="page-layout">
    <script
      type="application/javascript"
      src="https://apis.google.com/js/platform.js?onload=renderOptIn"
      async
      defer
      v-if="orderFetched"
    ></script>

    <script type="application/javascript" v-if="orderFetched">
      if (
        null === window.localStorage.getItem('googleReviewOrder_{{order.id}}')
      ) {
        if (undefined !== window.fbq) {
          window.fbq('track', 'Purchase', {
            value: '{{order.priceAfterDiscount}}',
            currency: 'PLN'
          })
        }

        window.renderOptIn = function () {
          window.gapi.load('surveyoptin', function () {
            window.gapi.surveyoptin.render({
              merchant_id: '137914606',
              order_id: '{{order.id}}',
              email: '{{order.user.email}}',
              delivery_country: 'PL',
              estimated_delivery_date: '{{order.diet.bags[0].deliveryDate}}',
              opt_in_style: 'CENTER_DIALOG'
            }) ||
              window.localStorage.setItem('googleReviewOrder_{{order.id}}', '1')
          })
        }
      }
    </script>
    <script type="application/javascript" v-if="orderFetched">
      window.___gcfg = {
        lang: 'pl'
      }
    </script>
    <header-client-view></header-client-view>

    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <div class="ajax-loader-start js-ajax-load"></div>
      <div class="container">
        <div class="order-thanks">
          <div class="text-center">
            <h1 class="title">Dziękujemy!</h1>
            <div
              class="sub-title"
              v-if="order.paymentStatus.systemValue === 'ORDER_PAID'"
            >
              Otrzymaliśmy Twoją wpłatę.
            </div>
            <div class="sub-title" v-else>Czekamy na status płatności.</div>
            <div class="sub-title">
              Twoja dieta będzie widoczna po jej otrzymaniu.
            </div>
          </div>
          <div class="card">
            <div class="titles-and-socials">
              <div class="card-titles">
                <div class="sub-title">
                  <strong> Pamietają, że możesz wybierać posiłki! </strong>
                </div>
                <div class="sub-title sub-title-smaller">
                  Opcja dostępna tylko w aplikacji mobilnej:
                </div>
              </div>

              <div>
                <a
                  href="https://apps.apple.com/app/republika-smakoszy/id1596929678"
                  class="apple-app-store"
                >
                  <img
                    alt="apple-app-store"
                    src="@/assets/svg/apple-app-store.svg"
                  />
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=pl.netinteractive.rs"
                  class="google-play-market"
                >
                  <img
                    alt="google-play-market"
                    src="@/assets/svg/google-play-market.svg"
                  />
                </a>
              </div>
            </div>
            <img alt="qr-code" class="qr-code" src="@/assets/svg/qr-code.svg" />
            <img
              src="/images/thanks-order-smartphone.png"
              alt="thanks-order-smartphone"
              class="thanks-order-smartphone"
            />
          </div>
        </div>
      </div>
    </main>

    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '@/components/HeaderClientView'
import FooterClientView from '@/components/FooterClientView'
import * as RouteCollection from '@/router/route_collection'
import * as actionTypes from '@/store/actions_types'
import router from '@/router'
import MarginMixin from '../../helpers/mixins'
import * as ActionTypes from '@/store/actions_types'

export default {
  components: { HeaderClientView, FooterClientView },
  mixins: [MarginMixin],
  name: 'thanks',
  props: {
    id: null
  },
  data() {
    return {
      order: {
        paymentStatus: {}
      },
      orderFetched: false
    }
  },
  async mounted() {
    this.fetchOrder(this.id)
    await this.$store.dispatch(ActionTypes.FETCH_CLIENT_ADDRESSES)
  },
  watch: {
    orderFetched: function () {
      let products = this.order.diet.bags[0].bagItems.map(item => {
        return {
          brand: 'RS',
          name: `${item.mealType.name}_${item.size.nameForClient}`,
          category: `${item.mealType.name}/${item.variant.name}/${item.size.calorific}`,
          variant: item.size.nameForClient,
          metric5: item.size.calorific,
          price: item.priceAfterDiscount,
          quantity: this.order.diet.bags.length
        }
      })

      const gtmData = {
        event: 'transaction',
        dimension3: 'stary classic',
        dimension4: 'basic',
        dimension5: this.mealTypeNames,
        dimension6: `${this.order.totalSize}/${this.order.diet.defaultBasket.name}`,
        dimension7: this.differentWeekendAddress
          ? 'weekend_address'
          : 'standard_address',
        metric2: this.order.diet.bags.length,
        metric3:
          this.order.priceAfterDiscount -
          (this.order.pricePayedByTransfer + this.order.priceLeftToPay),
        metric4: this.order.discountCode?.discount
          ? this.order.discountCode.discount / 100
          : '0.00',
        ecommerce: {
          purchase: {
            actionField: {
              id: this.order.id,
              revenue: this.order.priceAfterDiscount,
              coupon: this.order.discountCode?.code || '',
              shipping: this.order.diet.bags[0].bagItemsDelivery[0]
                .priceAfterDiscount
            },
            products
          }
        }
      }

      window.dataLayer.push(gtmData)
    }
  },
  computed: {
    dietLength() {
      if (this.diet) {
        return this.diet.bags.length
      }

      return null
    },
    dietOrder() {
      return this.$store.state.order.order
    },
    diet() {
      return this.dietOrder.order
    },
    firstBag() {
      if (this.dietOrder) {
        return this.dietOrder.diet.bags[0]
      }

      return null
    },
    bagPriceFormatted() {
      if (this.dietOrder) {
        return this.dietOrder.priceAfterDiscount.toFixed(2).replace('.', ',')
      }

      return null
    },
    mealTypeNames() {
      let meals = []
      this.order.diet.bags[0].bagItems.forEach(meal => {
        meals.push(meal.mealType.name)
      })
      return meals.join(', ')
    },
    differentWeekendAddress() {
      return this.$store.getters.differentWeekendAddress
    }
  },
  methods: {
    fetchOrder: function (id) {
      this.$store.dispatch(actionTypes.FETCH_ORDER, { id: id }).then(
        order => {
          this.order = order
          this.orderFetched = true
        },
        e => {
          this.handleError(e, false)
          router.push({ name: RouteCollection.OrderHistory })
        }
      )
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 40px;
}
.card-titles {
  margin-bottom: 30px;
}
.sub-title {
  font-size: 24px;
}
.sub-title-smaller {
  font-size: 23px;
}
.card {
  margin: 70px auto 100px auto;
  padding: 28px 31px 8px 31px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 1044px;
  justify-content: space-between;
  align-items: start;
  min-height: 202px;
  background: white;
  box-shadow: 0 5px 20px 0 #0000000d;
  border-radius: 29px;
}
.qr-code {
  margin: 6px auto;
}
.apple-app-store,
.google-play-market {
  margin-bottom: 20px;
  display: inline-block;
}
.apple-app-store {
  margin-right: 19px;
}
.titles-and-socials {
  display: inline-block;
}
.thanks-order-smartphone {
  margin: -150px -100px -220px -30px;
}
@media (max-width: 1000px) {
  .card {
    display: block;
  }
  .qr-code {
    display: block;
    margin: 0 auto 0 0;
  }
  .thanks-order-smartphone {
    margin: -123px -62px -100px auto;
    display: block;
  }
}
@media (max-width: 400px) {
  .apple-app-store img,
  .google-play-market img {
    margin-top: 8px;
    height: 17.7vw;
  }
}
@media (max-width: 517px) {
  .order-thanks {
    margin-top: -39px;
  }
  .title {
    font-size: 31px;
  }
  .sub-title {
    font-size: 18px;
    line-height: 26px;
  }
  .thanks-order-smartphone {
    width: 80vw;
  }
  .qr-code {
    visibility: hidden;
  }
  .card {
    margin-top: 34px;
  }
}
@media (max-width: 1199px) {
  .container {
    padding: 0 30px;
  }
}
</style>
