import * as types from './mutations_types'
import * as actionsTypes from './actions_types'

export const state = {
  isLoadingMute: false,
  isLoading: false,
  componentDialog: null
}

export const mutations = {
  [types.LOADING_START](state) {
    if (!state.isLoadingMute) {
      state.isLoading = true
    }
  },
  [types.LOADING_STOP](state) {
    if (!state.isLoadingMute) {
      state.isLoading = false
    }
  },
  [types.LOADING_MUTE](state) {
    state.isLoadingMute = true
  },
  [types.LOADING_UNMUTE](state) {
    state.isLoadingMute = false
  }
}

export const actions = {
  [actionsTypes.CLEAR_STORE]({ commit }) {
    commit(types.RESET_AUTH)
    commit(types.RESET_CONFIGURATOR)
    commit(types.RESET_ORDER)
    commit(types.RESET_DIET)
    commit(types.RESET_TAGS)
    commit(types.RESET_USER)
    commit(types.RESET_CALENDAR)
    commit(types.RESET_MONEYBOX)
  }
}
