<template>
  <div class="page-layout">
    <header-client-view></header-client-view>

    <main class="main">
      <div class="ajax-loader-start js-ajax-load"></div>
      <div class="container">
        <div>
          <div class="addon-meal-wrapper">
            <div class="order_summary">
              <h1 class="mainTitle addon-meal-title text-uppercase text-center">
                Dodaj posiłki
              </h1>
              <p class="sub-title">
                W ramach każdego posiłku możesz dodać dowolną liczbę dań.
              </p>
              <div class="current-date-wrapper">
                <img alt="calendar" src="@/assets/svg/calendar.svg" />
                <div class="since-when-wrapper">
                  <p>Kiedy</p>
                  <h3 class="current-date-heading">
                    {{ bagDetails.deliveryDay }}
                    {{ bagDetails.formattedDeliveryDate }}
                  </h3>
                </div>
              </div>
              <template v-for="(mealType, index) in mealTypes">
                <div :key="mealType.name + index" class="label-wrapper">
                  <label class="meal-input-label">{{ mealType.name }}</label>
                  <span
                    class="add-meal-button"
                    @click="addNewAddonMeal(mealType)"
                    >+</span
                  >
                </div>

                <template
                  v-for="(inputTest, index) in getSelectedAddonMeals[
                    mealType.name
                  ]"
                >
                  <div :key="inputTest.id + index" class="select-meal-row">
                    <div class="select-with-price-wrapper">
                      <div class="select-wrapper">
                        <select-styled
                          :mealTypeName="mealType.name"
                          :selectInputIndex="index"
                          :disabled="false"
                          :value="inputTest"
                          :items="getAvailableMeals[mealType.name]"
                        ></select-styled>
                      </div>
                      <span>{{ inputTest.price[packageId] }}&nbsp;zł</span>
                    </div>
                    <img
                      class="trash-icon"
                      @click="removeSelectedMeal(index, mealType.name)"
                      alt="trash"
                      src="@/assets/svg/trash.svg"
                    />
                  </div>
                </template>
                <div
                  :key="mealType.name + 'horizontal-line' + index"
                  class="addon-meal-horizontal-line"
                ></div>
              </template>

              <h1
                style="margin-bottom: 64px"
                class="mainTitle addon-meal-title text-uppercase text-center"
              >
                Podsumowanie
              </h1>

              <div class="summary-section">
                <p>Wybrane posiłki</p>
                <div class="summary-section--right-section">
                  <div
                    v-for="mealTypeName in this.mealTypeNames"
                    :key="mealTypeName"
                  >
                    <p
                      v-for="meal in getMealsInMealType(mealTypeName)"
                      :key="meal.id"
                      class="not-breaking-text"
                    >
                      {{ mealTypeName }} ({{ meal.nameForClient }}) - ok.
                      {{ meal.calorific }} kcal
                    </p>
                  </div>
                </div>
              </div>

              <div class="summary-section">
                <p>Data dostawy</p>
                <div class="summary-section--right-section">
                  <p>{{ bagDetails.formattedDeliveryDate }}</p>
                </div>
              </div>

              <div class="summary-section">
                <p>Wartość</p>
                <div class="summary-section--right-section">
                  <p>{{ getFormattedSumPrice }}</p>
                </div>
              </div>
              <div class="summary-section discount-code-section">
                <p>Kod rabatowy</p>
                <div class="summary-section--right-section">
                  <div class="discount-code-inputs-wrapper">
                    <div class="discount-code-text-input-wrapper">
                      <input
                        placeholder="Wpisz kod"
                        type="text"
                        name="discountValue"
                        v-bind:readonly="
                          $store.getters.getConfiguratorDiscountCode !== null
                        "
                        class="discount-code-input plg-ni_order_register_configurator-discountCode"
                        v-model="discount"
                      />
                      <div
                        class="subTitle text-danger text-center plg-ni_order_register_configurator-name-error"
                      >
                        {{ $t(discountError) }}
                      </div>
                    </div>
                    <div style="width: 100%" class="">
                      <button
                        @click="checkDiscount"
                        class="activate-discount-code-btn main-button plg-ni_order_register_configurator-discountActivate"
                        type="button"
                      >
                        Aktywuj
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="summary-section">
                <p>Ze skarbonki</p>
                <div class="summary-section--right-section">
                  <p>{{ getMoneyboxPoints }} zł</p>
                </div>
              </div>

              <div class="summary-section price-to-pay-section">
                <p>Kwota do zapłaty</p>
                <div class="summary-section--right-section">
                  <p>{{ getSumPrice }} zł</p>
                </div>
              </div>
              <div class="terms-agreement-wrapper">
                <label class="checkbox" @click="changeAgreement($event)">
                  <input
                    type="checkbox"
                    name=""
                    class="plg-ni_order_summary-acceptRules"
                  />
                  <span class="box"
                    ><i
                      class="fa fa-check"
                      v-show="agreement"
                      style="display: none"
                    ></i
                  ></span>
                </label>

                <div>
                  Akceptuję
                  <a href="#" @click.stop.prevent="gotoAgreement">regulamin</a>
                  serwisu
                </div>
              </div>

              <div class="agreement-container text-center m-b-40">
                <div
                  class="subTitle text-danger text-center plg-ni_order_register_configurator-name-error"
                >
                  {{ error }}
                </div>
              </div>

              <div
                class="cta-buttons-wrapper text-center order_summary-btnWrapper plg-ni_order_summary-payContainer"
              >
                <a @click="$router.back()" class="back-btn">Powrót</a>
                <button
                  class="btn btn-yellow"
                  type="button"
                  :disabled="!agreement || getSelectedAddonSumCount === 0"
                  @click="payMoneyBox"
                >
                  <template
                    v-if="
                      getSumPrice !== '0.00' ||
                      getSelectedAddonSumPrice === '0.00'
                    "
                  >
                    Płacę
                  </template>
                  <template v-else> Płacę punktami </template>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <footer-client-view></footer-client-view>
  </div>
</template>
<script>
import HeaderClientView from '../../components/HeaderClientView'
import FooterClientView from '../../components/FooterClientView'
import SelectStyled from '@/components/SelectStyledLight'
import * as types from '../../store/mutations_types'
import router from '../../router'
import * as RouteCollection from '../../router/route_collection'
import * as actionTypes from '../../store/actions_types'
import routes from '../../services/api/routes'
import axios from '../../interceptor'
import ConfiguratorApi from '@/services/api/configurator'
import { dietPackageMap, dietVariant } from '@/helpers'

export default {
  components: { SelectStyled, HeaderClientView, FooterClientView },
  name: 'confirm',

  props: {
    id: null
  },
  data() {
    return {
      routeCollection: RouteCollection,
      errors: {},
      paymentClicked: false,
      addMealDiscount: {},
      addMealDiscountText: ''
    }
  },
  async mounted() {
    const bagId = this.$store.getters.getCalendarSelectedDiet
    if (!bagId) {
      return router.push('/calendar/browser')
    }
    await this.fetchSelectedPackage(bagId)
    await this.fetchMealPrices()
    await this.$store.dispatch(actionTypes.FETCH_CONFIGURATION)
  },
  destroyed() {
    this.clearState()
  },
  computed: {
    totalCalorieCount() {
      let calories = 0
      this.getSortedAddonMeals.forEach(meals => {
        meals.forEach(meal => {
          calories += meal.calorific
        })
      })

      return calories
    },
    mealTypes() {
      return this.$store.getters.getMealTypes
    },
    mealTypeNames() {
      return this.$store.getters.getMealTypeNames
    },
    bagId() {
      return this.$store.getters.getCalendarSelectedDiet
    },
    discount: {
      get: function () {
        return this.addMealDiscountText
      },
      set: function (value) {
        this.addMealDiscountText = value
      }
    },

    discountError: {
      get: function () {
        return this.$store.getters.getConfiguratorDiscountCodeError
      },
      set: function (value) {
        this.$store.commit(types.UPDATE_CONFIGURATOR_DISCOUNT_CODE_ERROR, value)
      }
    },
    bagDetails: function () {
      const { deliveryDate } = this.$store.getters.getBagDetails || {}

      return {
        deliveryDate,
        deliveryDay: this.$date(deliveryDate).format('dddd'),
        formattedDeliveryDate: this.$date(deliveryDate).format('MM-DD-YYYY')
      }
    },
    getSelectedAddonMeals() {
      return this.$store.getters.getConfiguratorSelectedMeals
    },
    getSelectedAddonSumCount() {
      return Object.values(
        this.$store.getters.getConfiguratorSelectedMeals
      ).reduce((prev, curr) => {
        return prev + curr.length
      }, 0)
    },
    getSelectedAddonSumPrice() {
      const addonMeals = this.getSelectedAddonMeals
      const flattenMeals = Object.values(addonMeals)
      const sumPrice = flattenMeals.reduce((prev, curr) => {
        return (
          prev +
          curr.reduce((innerPrev, innerCurr) => {
            return innerPrev + parseFloat(innerCurr.price[this.packageId])
          }, 0)
        )
      }, 0)

      return sumPrice.toFixed(2)
    },
    discountedAddonSumPrice() {
      const addonMeals = this.getSelectedAddonMeals
      const flattenMeals = Object.values(addonMeals)
      const sumPrice = this.getSelectedAddonSumPrice
      const discountCode = this.addMealDiscount.discount || 0

      if (discountCode) {
        const discountedPrice = flattenMeals.reduce((prev, curr) => {
          return (
            prev +
            curr.reduce((innerPrev, innerCurr) => {
              const parsedPrice = parseFloat(innerCurr.price[this.packageId])
              const discountPrice = Math.round(parsedPrice * discountCode) / 100
              return innerPrev + discountPrice
            }, 0)
          )
        }, 0)
        return (sumPrice - discountedPrice).toFixed(2)
      }

      return null
    },
    getFormattedSumPrice() {
      const discountedPrice = this.discountedAddonSumPrice

      return discountedPrice
        ? `${discountedPrice} zł (${this.getSelectedAddonSumPrice} zł przed rabatem)`
        : `${this.getSelectedAddonSumPrice} zł`
    },
    getMoneyboxPoints() {
      let moneybox =
        this.$store.getters.jwtDecoded.moneybox === null
          ? 0
          : this.$store.getters.jwtDecoded.moneybox

      moneybox = moneybox > 0 ? moneybox : 0

      const sumPrice =
        this.discountedAddonSumPrice || this.getSelectedAddonSumPrice

      const usedMoneyboxPoints = Math.min(moneybox, sumPrice)

      return usedMoneyboxPoints.toFixed(2)
    },
    getSumPrice() {
      const sumPrice = this.discountedAddonSumPrice
        ? this.discountedAddonSumPrice
        : this.getSelectedAddonSumPrice
      return (sumPrice - this.getMoneyboxPoints).toFixed(2)
    },
    getAvailableMeals() {
      return this.$store.getters.getConfiguratorAvailableMeals
    },
    getSortedAddonMeals() {
      const meals = this.getSelectedAddonMeals
      const mealTypeNames = this.mealTypeNames
      return mealTypeNames.map(mealTypeName => meals[mealTypeName])
    },
    error: function () {
      if (this.errors[1]) {
        return this.errors[1]
      }

      return null
    },
    packageId() {
      return this.$store.getters.getSelectedDietId.id
    },
    agreement: function () {
      return this.$store.state.order.agreement
    },
    differentWeekendAddress() {
      return this.$store.getters.differentWeekendAddress
    }
  },
  methods: {
    gotoAgreement() {
      window.open('https://republikasmakoszy.pl/regulamin-serwisu')
    },
    updateSelectedAddonMeals(payload) {
      this.$store.dispatch(actionTypes.CHANGE_ADDON_MEALS, payload)
    },
    getCurrentPackageId() {
      return this.$store.getters.getSelectedDietId.id
    },
    removeSelectedMeal(index, mealTypeName) {
      const mealAddonsArray = this.getSelectedAddonMeals
      const shallowCopy = JSON.parse(JSON.stringify(mealAddonsArray))
      shallowCopy[mealTypeName].splice(index, 1)
      this.$store.dispatch(actionTypes.CHANGE_ADDON_MEALS, shallowCopy)
    },
    addNewAddonMeal(mealType) {
      const availableMeals = this.getAvailableMeals[mealType.name]
      if (!availableMeals) return
      const mealAddonsArray = this.$store.getters.getConfiguratorSelectedMeals
      const selectedMeal = availableMeals[0]
      const mealAddonsDeepCopy = JSON.parse(JSON.stringify(mealAddonsArray))

      const bag = this.$store.getters.getBagDetails.bag.id
      const pckg = this.package.id

      const mealToAdd = {
        ...selectedMeal,
        bag,
        package: pckg
      }

      mealAddonsDeepCopy[mealType.name].push(mealToAdd)

      this.$store.dispatch(actionTypes.CHANGE_ADDON_MEALS, mealAddonsDeepCopy)
    },
    getMealsInMealType(mealType) {
      return this.getSelectedAddonMeals[mealType]
    },

    getSelectedAddonPrice(id) {
      const mealPrice = this.$store.getters.getConfiguratorMealsPrice.packages.find(
        ({ sizeId }) => sizeId === id
      )

      return mealPrice.price
    },
    checkDiscount() {
      if (typeof this.discount !== 'undefined' && this.discount !== null) {
        this.discountError = null
        if (this.discount === '') {
          if (this.addMealDiscount.discountText) {
            this.addMealDiscount = {}
          }
          return
        }
        ConfiguratorApi.checkDiscountCodeAvailability(this.discount).then(
          res => {
            if (res.data.error !== null) {
              this.discountError = 'pages.configurator.' + res.data.error
            } else {
              this.addMealDiscount = {
                ...res.data,
                discountText: this.discount
              }
            }
          }
        )
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    payMoneyBox: function () {
      const items = Object.values(this.getSelectedAddonMeals)
        .reduce((prev, curr) => [...prev, ...curr], [])
        .map(({ bag, package: pckg, id: size, mealTypeId: mealType }) => ({
          type: 'BagItem',
          bagItem: {
            bag,
            variant: this.defaultVariant,
            mealType,
            package: pckg,
            size
          }
        }))

      const products = []
      const mealTypes = []

      for (const [key, value] of Object.entries(this.getSelectedAddonMeals)) {
        const variant = dietVariant(
          this.$store.getters.getConfiguratorVariantOptions,
          this.defaultVariant
        )

        value.forEach(meal => {
          const mealData = {
            name: `${key}_${meal.nameForClient}`,
            price: meal.price['3'],
            brand: 'RS',
            category: `${key}/${this.capitalizeFirstLetter(
              dietPackageMap[meal.package]
            )}/${this.capitalizeFirstLetter(variant.name)}/${meal.calorific}`,
            variant: meal.nameForClient,
            quantity: 1,
            metric5: meal.calorific
          }

          const foundMeal = products.find(meal => meal.name === mealData.name)

          if (foundMeal) {
            foundMeal.quantity += 1
          } else {
            products.push(mealData)
            if (!mealTypes.includes(key)) {
              mealTypes.push(key)
            }
          }
        })
      }

      const sumOfCalories = products.reduce((totalCalories, meal) => {
        return totalCalories + meal.metric5
      }, 0)

      const isPayMoney =
        this.getSumPrice !== '0.00' || this.getSelectedAddonSumPrice === '0.00'

      const discountCode = this.addMealDiscount.discountText || ''

      const payment = {
        needFV: false,
        nip: null,
        paymentType: isPayMoney
          ? 'PAYMENT_TYPE_ONLINE'
          : 'PAYMENT_TYPE_MONEYBOX'
      }

      const addMealRequestPayload = {
        items,
        discountCode,
        payment
      }

      // const gtmData = {
      //   dimension3: '',
      //   dimension4: 'change',
      //   dimension5: this.getSelectedAddonMeals,
      //   dimension6: this.totalCalorieCount,
      //   metric2: 1
      // }

      const gtmData = {
        dimension3: 'old_classic',
        dimension4: 'change',
        dimension5: mealTypes.join(', '),
        dimension6: sumOfCalories,
        metric2: 1
      }

      axios
        .post(routes.addMealsToDietDay, addMealRequestPayload)
        .then(({ status, data }) => {
          window.dataLayer.push({
            event: 'addToCart',
            ...gtmData,
            ecommerce: {
              currencyCode: 'PLN',
              add: {
                products
              }
            }
          })

          window.dataLayer.push({
            event: 'checkoutStep',
            ...gtmData,
            ecommerce: {
              checkout: {
                actionField: {
                  step: '1',
                  option: discountCode ? 'coupon_code' : 'without_coupon'
                },
                products
              }
            }
          })

          window.dataLayer.push({
            event: 'checkoutStep',
            dimension3: 'old_classic',
            dimension4: 'change',
            ecommerce: {
              checkout: {
                actionField: { step: '2' }
              }
            }
          })

          window.dataLayer.push({
            event: 'checkoutStep',
            dimension3: 'old_classic',
            dimension4: 'change',
            ecommerce: {
              checkout: {
                actionField: {
                  step: '2',
                  option: this.differentWeekendAddress
                    ? 'weekend_address'
                    : 'standard_address'
                }
              }
            }
          })

          window.dataLayer.push({
            event: 'checkoutStep',
            dimension3: 'old_classic',
            dimension4: 'change',
            ecommerce: {
              checkout: {
                actionField: {
                  step: '3',
                  option:
                    this.getMoneyboxPoints > 0 ? 'moneybox' : 'without_moneybox'
                }
              }
            }
          })

          if (status === 200) {
            return (window.location = data.redirectUri)
          }
          router.push({ name: RouteCollection.OrderThanks })
        })
        .catch(error => {
          this.$swal({
            type: 'success',
            customClass: 'modal__replenishment modal__withdrawal',
            title: 'NIEPOWODZENIE',
            showCloseButton: true,
            text: error.response.data.messages[0],
            confirmButtonText: 'ZAMKNIJ'
          })
        })
    },
    fetchSelectedPackage(id) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(actionTypes.FETCH_SELECTED_DIET, { id }).then(
          pckg => {
            this.package = pckg.package
            this.defaultVariant = pckg.defaultVariant
            resolve(pckg)
          },
          e => {
            this.handleError(e, false)
            router.push({ name: RouteCollection.OrderHistory })
            reject(e)
          }
        )
      })
    },
    fetchMealPrices() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(actionTypes.FETCH_MEALS_PRICE, { id: 'null' })
          .then(
            prices => {
              this.prices = prices
              resolve(prices)
            },
            e => {
              this.handleError(e, false)
              router.push({ name: RouteCollection.OrderHistory })
              reject(e)
            }
          )
      })
    },
    changeAgreement(event) {
      event.preventDefault()
      event.stopPropagation()
      if (!this.paymentClicked) {
        this.$store.commit(
          types.ORDER_CHANGE_AGREEMENT,
          !this.$store.state.order.agreement
        )
      }
    },
    clearState() {
      this.$store.commit(types.RESET_ADD_MEALS_STATE)
      this.$store.commit(
        types.ORDER_CHANGE_AGREEMENT,
        (this.$store.state.order.agreement = false)
      )
      this.discountError = null
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1280px;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.addon-meal-wrapper {
  margin: 16px;
}

h1.addon-meal-title {
  margin-bottom: 19px;
}

p.sub-title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 38px;
}

.current-date-heading {
  margin: 0;
}

.current-date-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 58px;
}

.back-btn {
  padding: 0 109px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 24px;
  color: #d2d2d2;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
}

.since-when-wrapper {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 3px solid #ff4068;
}

.since-when-wrapper > p {
  font-size: 18px;
  font-weight: bold;
  color: #d3d3d7;
  margin: 0;
  margin-bottom: 5px;
}

.since-when-wrapper > h3 {
  font-size: 20px;
}

.summary-section {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.summary-section p {
  margin: 0;
}

.summary-section--ordered-meals {
  //margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.summary-section--ordered-meals > p {
  //margin-left: -60px;
  font-weight: bold;
}

.summary-section--right-section {
  width: 50%;
  font-weight: bold;
}

.price-to-pay-section {
  font-size: 30px;
  font-weight: bold;
}

.terms-agreement-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms-agreement-wrapper .checkbox {
  position: relative;
  height: 21px;
  width: 21px;
  margin: 0 10px 0 0;
  padding: 0;
}

.meal-input-label {
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 21px;
}

.main-button {
  text-transform: capitalize;
  font-size: 24px;
}

.activate-discount-code-btn {
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
  text-align: center;
  max-width: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: #fff;
  border-radius: 30px;
  border: 3px solid #ff4068;
  height: 60px;
  width: 100%;
  min-width: 196px;
}

.discount-code-input {
  font-family: Helvetica, sans-serif;
  background: #fff;
  border-radius: 30px;
  border: 3px solid #d2d2d2;
  color: #222;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 60px;
  width: 100%;
  text-align: center;
}

.discount-code-text-input-wrapper {
  width: 164px;
  margin-right: 20px;
  flex-shrink: 0;
}

.discount-code-inputs-wrapper {
  display: flex;
  justify-content: space-between;
}

.btn-yellow {
  border-radius: 30px;
  background: #ff4068;
  font-size: 24px;
}

.label-wrapper {
  display: flex;
  justify-content: space-between;
}

.addon-meal-horizontal-line {
  border-bottom: 2px solid #707070;
  opacity: 0.3;
  margin-bottom: 28px;
}

label.checkbox {
  text-transform: inherit;
  font-size: 18px;
}

.add-meal-button {
  color: #ff4068;
  font-size: 30px;
  line-height: 10px;
  cursor: pointer;
}

.trash-icon {
  cursor: pointer;
  margin-left: 30px;
}

.select-meal-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.checkbox {
  cursor: pointer;
  font-size: 25px;
  font-family: Helvetica;
  color: #222;
  font-weight: normal;
  position: relative;
  padding-left: 45px;
  display: inline-block;
  margin-top: 13px;
  margin-bottom: 13px;
}

.checkbox > .box {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 21px;
  border-radius: 3px;
  border: 3px solid #ff4068;
  margin-right: 10px;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.checkbox > .box .fa {
  font-size: 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #ff4068;
  display: block;
  position: absolute;
  top: -3px;
  left: -3px;
}

.order_summary_price {
  font-size: 45px;
  font-weight: bold;
}

.select-wrapper {
  position: relative;
  width: 400px;
}

.select-with-price-wrapper {
  display: flex;
  align-items: center;
}

.select-with-price-wrapper > span {
  margin-left: 25px;
  font-size: 14px;
}

@media only screen and (max-width: 850px) {
  .discount-code-section {
    display: block;
  }

  .cta-buttons-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .discount-code-section > .summary-section--right-section {
    width: 100%;
  }

  .discount-code-section > p {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 600px) {
  h1.mainTitle {
    font-size: 32px;
  }

  .order_summary-table tr td:first-child {
    padding-right: 10px;
  }
  .order_summary-table tr td:last-child {
    padding-left: 0;
  }

  .select-with-price-wrapper {
    width: 100%;
  }

  .select-wrapper {
    width: 100%;
  }

  .summary-section {
    display: block;
  }

  .summary-section > p {
    margin-bottom: 16px;
  }

  .price-to-pay-section {
    font-size: 24px;
    font-weight: normal;
  }

  .terms-agreement-wrapper {
    text-align: center;
  }

  .agreement-container {
    text-align: left;
  }

  .fv-nip {
    padding-left: 0;
  }
}

@media only screen and (max-width: 460px) {
  .discount-code-inputs-wrapper {
    display: block;
  }

  .discount-code-text-input-wrapper {
    width: 100%;
  }
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.not-breaking-text {
  white-space: nowrap;
}
</style>
