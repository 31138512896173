<template>
  <div>
    <div class="delivery-date-picker" v-if="isDeliveryChangePossible">
      <div class="info-row">
        <h5 class="">Zmień termin dostawy</h5>
        <p>zmiana terminu możliwa jeszcze przez</p>
      </div>

      <div class="countdown__container">
        <button
          class="button-outlined"
          type="button"
          @click.stop="
            $emit('showChangeDeliveryDateUpdated', !showChangeDeliveryDate)
          "
        >
          zmień
        </button>
        <vue-countdown :time="deliveryChangeDeadline" class="">
          <template slot-scope="props">
            {{ props.days }}d {{ props.hours }}h {{ props.minutes }}m
            {{ props.seconds }}s
          </template>
        </vue-countdown>
      </div>
    </div>

    <div class="">
      <div class="">
        <datepicker
          v-if="showChangeDeliveryDate"
          class=""
          :language="language.pl"
          :disabled-dates="disabledAtDays"
          :monday-first="true"
          @selected="changeDeliveryDate"
          :inline="true"
        ></datepicker>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import VueCountdown from '@chenfengyuan/vue-countdown'
import { pl } from 'vuejs-datepicker/dist/locale'
import BagApi from '../../services/api/bag'
import * as ActionTypes from '../../store/actions_types'

export default {
  name: 'DeliveryDatePicker',
  components: { Datepicker, VueCountdown },
  props: {
    showChangeDeliveryDate: { type: Boolean }
  },
  data() {
    return {
      language: {
        pl
      }
    }
  },
  computed: {
    disabledAtDays() {
      return {
        to: new Date(),
        dates: this.bagDetails.canChangeDeliveryTime.disabledDates.map(el => {
          return new Date(el)
        }),
        days: this.bagDetails.canChangeDeliveryTime.disabledDays
      }
    },
    bagDetails: function () {
      return this.$store.getters.getBagDetails
    },
    deliveryChangeDeadline: function () {
      return (
        (this.$date(this.bagDetails.canChangeDeliveryTime.deadlineAt).unix() -
          this.$date(new Date()).unix()) *
        1000
      )
    },
    isDeliveryChangePossible() {
      return (
        this.bagDetails !== null &&
        typeof this.bagDetails !== 'undefined' &&
        this.bagDetails.canChangeDeliveryTime.possible
      )
    }
  },
  methods: {
    changeDeliveryDate(event) {
      if (this.$date(event).diff(this.$date(new Date()), 'days') > 150) {
        this.$swal({
          type: 'success',
          customClass: 'oldNtfyModal',
          title: 'UWAGA',
          showCloseButton: true,
          text: 'Zamówienie można stworzyć maksymalnie na 150 dni do przodu!'
        })

        return
      }

      let date = this.$date(event)
      BagApi.changeDeliveryTime(this.bagDetails.id, date.format('YYYY-MM-DD'))
        .then(() => {
          this.$store.dispatch('changeOrderDate', {
            newDate: date,
            bagId: this.bagDetails.id
          })
          this.$swal({
            type: 'success',
            customClass: 'oldNtfyModal',
            title: 'INFORMACJA',
            showCloseButton: true,
            text: 'Termin został zmieniony.'
          })
        })
        .catch(e => {
          if (
            e.response.hasOwnProperty('data') &&
            e.response.data.hasOwnProperty('message')
          ) {
            this.$swal({
              type: 'success',
              customClass: 'oldNtfyModal',
              title: 'UWAGA',
              showCloseButton: true,
              text: this.$t(e.response.data.message)
            })
          } else {
            this.handleError(e)
          }
        })
    }
  }
}
</script>
