<template>
  <span>
    <template v-if="showFull === false">
      {{ lead }}
      <span
        v-if="appendText"
        @click="showFull = true"
        class="register-page__checkbox-label-expand"
        >(więcej)</span
      >
    </template>
    <template v-else>
      {{ lead }} <span v-html="appendText"></span>
      <span
        @click="showFull = false"
        class="register-page__checkbox-label-expand"
        >(mniej)</span
      >
    </template>
  </span>
</template>

<script>
export default {
  name: 'CollapsedText',
  props: {
    appendText: { type: String },
    lead: { type: String }
  },
  data() {
    return {
      showFull: false
    }
  }
}
</script>
