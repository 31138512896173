<template>
  <div
    class="order_register_steps"
    v-if="$store.getters.getOrderSteps.length > 1"
  >
    <template v-for="(s, index) in $store.getters.getOrderSteps">
      <div
        :key="index"
        class="order_register_stepsStep"
        :class="{
          'order_register_steps-current':
            $store.getters.getOrderStep === parseInt(index),
          'prev-steps': $store.getters.getOrderStep > parseInt(index)
        }"
      >
        <span class="ring">{{ index + 1 }}</span>
      </div>
      <div class="step-label" :key="'1' + index">{{ $t(s.label) }}</div>
      <div class="flex-sep" :key="'0' + index"></div>
    </template>
  </div>
</template>

<script>
import { UPDATE_ORDER_STEPS, UPDATE_ORDER_STEP } from '@/store/mutations_types'
import * as RouteCollection from '@/router/route_collection'
import * as types from '@/store/mutations_types'
import order from '@/services/api/order'

export default {
  name: 'OrderStep',
  async mounted() {
    const steps = []
    const next = this.$emit.bind(this, 'next')

    if (this.$route.path.includes('order')) {
      steps.push({
        label: 'Diet configuration',
        match: /order\/create/
      })
      if (!this.$store.getters.jwtDecoded.firstName) {
        steps.push({
          label: 'My data',
          match: /order\/user\-data/,
          back: () => {
            this.$router.push({ name: RouteCollection.OrderCreate })
          },
          next
        })
      }
    }

    if (
      !this.$route.path.includes('order') ||
      !this.$store.getters.jwtDecoded.hasRequiredAddresses
    ) {
      steps.push({
        label: 'Address data',
        match: /\/address/,
        back: () => {
          this.$router.push({
            name: steps.some(s => s.label === 'label.my_data')
              ? RouteCollection.UserMyData
              : RouteCollection.OrderCreate
          })
        },
        next
      })
      steps.push({
        label: 'label.address_additional',
        match: /\/address/,
        back: () => {
          this.$store.commit(
            types.UPDATE_ORDER_STEP,
            this.$store.getters.getOrderStep - 1
          )
        },
        next
      })
    }

    this.$store.commit(UPDATE_ORDER_STEPS, steps)
    const step = steps.findIndex(s => s.match.test(this.$route.path))
    this.$store.commit(UPDATE_ORDER_STEP, step < 0 ? 0 : step)
  }
}
</script>

<style scoped>
.order_register_steps {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.flex-sep {
  max-width: 100px;
  flex-grow: 1;
  height: 1px;
  overflow: hidden;
  background-color: black;
  margin-top: 1px;
}
.flex-sep:last-child {
  display: none;
}
.step-label {
  margin-bottom: 3px;
}
.order_register_steps {
  min-height: 35px;
  padding: 0;
  text-align: center;
  position: relative;
}
.order_register_stepsStep {
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 2;
}
.order_register_stepsStep:first-child:before {
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.order_register_stepsStep:last-child:before {
  content: '';
  display: block;
  background: #eff4ff;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.order_register_stepsStep:first-child {
  float: left;
}
.order_register_stepsStep:last-child {
  float: right;
}
.order_register_stepsStep .ring {
  border: 1px solid #14171b;
  border-radius: 50%;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 20px;
  background: #eff4ff;
  color: #14171b;
  display: inline-block;
  text-align: center;
  position: relative;
}
.prev-steps span {
  color: #fff;
  background: #1a1a1a;
  border-color: #1a1a1a;
}
.order_register_steps-current .ring {
  background-color: #ff4068;
}
.ring {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 29px;
}
</style>
