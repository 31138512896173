<template>
  <div class="diet-configurator" id="prices">
    <h1 class="diet-configuration__header text-uppercase text-center">
      {{ title }}
    </h1>

    <section>
      <OrderStep />

      <Size />

      <MealType ref="mealTypes" />

      <Variant />

      <diet-length
        ref="dietLength"
        :create-order-on-click="createOrderOnClick"
      />

      <DietPrice
        :create-order-on-click="createOrderOnClick"
        :numberOfMeals="configurator.basketMeals.length"
        :caloriesInDiet="caloriesInDiet"
        :dietLength="configurator.dietLength"
        :variant="configurator.variant"
        @submitForm="createOrder"
      />

      <!--        <div class="row">-->
      <!--          <div class="col-xs-12">-->
      <!--            <button-->
      <!--              type="submit"-->
      <!--              class="button btn-yellow btn-bck-white plg-ni_program_configurator-makeOrder"-->
      <!--            >-->
      <!--              {{ buttonTitle }}-->
      <!--            </button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </form>-->
    </section>
  </div>
</template>

<script>
import Size from './Size'
import Variant from './Variant'
import DietLength from './DietLength'
import DietPrice from './DietPrice'
import MealType from './MealType'
import OrderStep from '../OrderStep'
import * as actionTypes from '../../store/actions_types'
import * as types from '../../store/mutations_types'
import * as RouteCollection from '../../router/route_collection'
import router from '../../router'
import order from '../../services/api/order'
import auth from '../../services/api/auth'
import { dietCalorificMap, dietVariant } from '@/helpers'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    buttonTitle: {
      type: String,
      default: ''
    },
    createOrderOnClick: {
      type: Boolean,
      default: false
    },
    scrollToDietLength: {
      type: Boolean,
      default: false
    }
  },
  name: 'diet-configurator',
  components: {
    OrderStep,
    Size,
    Variant,
    DietLength,
    DietPrice,
    MealType
  },
  mounted() {
    this.$store.dispatch(actionTypes.FETCH_CONFIGURATION).then(
      function () {
        this.$store.dispatch(
          actionTypes.FETCH_DIET_PRICE,
          this.$store.getters.getParamsForCalculateDietPrice
        )
      }.bind(this)
    )
    if (this.scrollToDietLength) {
      setTimeout(() => {
        this.$refs.dietLength.$el.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  },
  computed: {
    configurator() {
      return this.$store.getters.getConfigurator
    },
    basketMeals() {
      return this.$store.getters.getConfiguratorBasketMealsOptions
    },
    basketMealNames() {
      return this.configurator.basketMeals.map(basketMealId => {
        const basketMealName = this.basketMeals.find(
          meal => meal.id === basketMealId
        )
        return basketMealName.mealType.name
      })
    },
    caloriesInDiet() {
      let calories = 0

      this.configurator.basketMeals.forEach(meal => {
        for (let i of this.basketMeals) {
          if (meal === i.id) {
            calories += i.size.calorific
            break
          }
        }
      })

      return calories
    },
    mealDetails() {
      const meals = []
      const variant = dietVariant(
        this.$store.getters.getConfiguratorVariantOptions,
        this.configurator.variant
      )

      this.configurator.basketMeals.forEach(meal => {
        for (let i of this.basketMeals) {
          if (meal === i.id) {
            const meal = {
              name: `${i.mealType.name}_${i.size.nameForClient}`,
              brand: 'RS',
              variant: i.size.nameForClient,
              metric5: i.size.calorific,
              quantity: this.configurator.dietLength,
              category: `${i.mealType.name}/${this.capitalizeFirstLetter(
                variant.name
              )}/${i.size.calorific}`,
              price: this.configurator.dayCost.items[i.size.name].afterDiscount
            }

            meals.push(meal)
          }
        }
      })

      return meals
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    createOrder() {
      // event.preventDefault()
      // event.stopPropagation()

      if (!auth.isAuthorized()) {
        this.$router.push({ name: RouteCollection.Register })
      }

      const preparedMealNames = this.basketMealNames.reduce(
        (prev, curr) => {
          return {
            ...prev,
            [curr]: 'tak'
          }
        },
        {
          Śniadanie: 'nie',
          'Drugie śniadanie': 'nie',
          Obiad: 'nie',
          Podwieczorek: 'nie',
          Kolacja: 'nie'
        }
      )

      const cartDetails = {
        dimension3: 'stary classic',
        dimension4: 'basic',
        dimension5: this.basketMealNames.join(', '),
        dimension6: `${this.caloriesInDiet}/${
          dietCalorificMap[this.configurator.basket]
        }`,
        metric2: this.configurator.dietLength,
        products: this.mealDetails,
        coupon_code: this.configurator.discountCode
          ? 'coupon_code'
          : 'without_coupon',
        skipSteps: this.$store.getters.jwtDecoded.hasRequiredAddresses
      }

      const gtmData = {
        event: 'addToCart',
        dimension3: cartDetails.dimension3,
        dimension4: cartDetails.dimension4,
        dimension5: cartDetails.dimension5,
        dimension6: cartDetails.dimension6,
        metric2: cartDetails.metric2,
        ecommerce: {
          currencyCode: 'PLN',
          add: {
            products: cartDetails.products
          }
        }
      }

      this.$store.dispatch('setCartDetails', cartDetails)
      window.dataLayer.push(gtmData)

      this.baseValidation().then(async ({ isValid, errors }) => {
        if (!isValid) {
          this.$store.commit(types.ORDER_CHANGE_ERRORS, errors)
        } else {
          if (this.createOrderOnClick === true) {
            if (!this.$store.getters.jwtDecoded.firstName) {
              return await router.push({ name: RouteCollection.UserData })
            }
            if (!this.$store.getters.jwtDecoded.hasRequiredAddresses) {
              return await router.push({ name: RouteCollection.OrderAddress })
            }

            order.create()
          } else {
            await router.push({ name: RouteCollection.Register })
          }
        }
      })
    },
    baseValidation() {
      return new Promise((resolve, reject) => {
        let errors = {}

        if (this.$store.getters.getConfiguratorName.length === 0) {
          errors['name'] = { 1: 'Uzupełnij nazwę swojej diety' }
        }

        if (
          this.$store.getters.getConfiguratorVariantObject.minMealsCount >
          this.$store.getters.getConfiguratorBasketMeals.length
        ) {
          errors['basketMeals'] = {
            1: 'Nie wybrano dostatniej ilości posiłków'
          }
          setTimeout(() => {
            this.$refs.mealTypes.$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          })
        }

        resolve({ isValid: Object.keys(errors).length === 0, errors })
      })
    }
  }
}
</script>
