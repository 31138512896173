<template>
  <h1 class="mainTitle text-uppercase text-center">
    {{ title }}
  </h1>
</template>

<script>
export default {
  name: 'HeaderH1',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
