<template>
  <div class="flex" style="flex-direction: column">
    <div v-if="mealTypes.length > 0" class="mb-8">
      <div class="mb-5" v-for="(mealType, key) in mealTypes" :key="key">
        <div>
          <label class="labelInput" v-if="!mealType.hideName">{{
            mealType.name
          }}</label>

          <select-styled
            :value="mealType.selected"
            @input="selectMeal($event, key)"
            :items="mealType.items"
            :disabled="!mealType.editable || disabled"
          ></select-styled>
        </div>
      </div>
    </div>

    <div class="flex">
      <button
        class="button-outlined"
        @click.prevent="save"
        :disabled="disabled"
      >
        zapisz menu
      </button>

      <button
        class="button-red"
        style="margin-left: 20px"
        @click.prevent="gotoAddMeals()"
        :disabled="!canAddMeals"
      >
        Dodaj posiłki
      </button>
    </div>
  </div>
</template>

<script>
import SelectStyled from '../SelectStyled'
import BagApi from '../../services/api/bag'
import * as ActionTypes from '../../store/actions_types'
import * as MutationTypes from '../../store/mutations_types'
import * as RouteCollection from '@/router/route_collection'

export default {
  name: 'MenuPicker',
  components: { SelectStyled },
  props: {
    mealTypes: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    canAddMeals: function () {
      return !!this.bagDetails.canAddElements
    },
    bagDetails: function () {
      return this.$store.getters.getBagDetails
    }
  },
  methods: {
    gotoAddMeals() {
      this.$router.push({ name: RouteCollection.OrderAddMeal })
    },
    selectMeal($event, key) {
      this.$store.commit(MutationTypes.UPDATE_BAG_DETAILS_SELECTED_MEAL, {
        index: key,
        value: $event
      })
    },
    save() {
      BagApi.update(this.bagDetails.id, this.bagDetails.mealTypes)
        .then(() => {
          this.$store.dispatch('changeMenu', this.bagDetails.id)
        })
        .then(() => {
          this.$swal({
            type: 'success',
            customClass: 'oldNtfyModal',
            title: 'INFORMACJA',
            showCloseButton: true,
            text: 'Zmiany zostały zapisane'
          })
        })
        .catch(e => {
          this.handleError(e)
        })
    }
  }
}
</script>
