import * as MutationTypes from '../mutations_types'
import * as ActionTypes from '../actions_types'
import ConfiguratorApi from '../../services/api/configurator'
import dayjs from '@/plugins/dayjs'

function initialState() {
  return {
    selectedDay: null,
    activeDay: null,
    selectedBag: null,
    viewDate: new dayjs(),
    bagDetails: null,
    isLoadingDayDetails: false,
    calendar: null,
    key: null
  }
}

const state = initialState()

const actions = {
  [ActionTypes.SELECT_DIET]({ dispatch, commit, getters }, data) {
    commit(MutationTypes.DIET_SELECTED, data)
    dispatch(ActionTypes.LOAD_CALENDAR)
  },
  [ActionTypes.SELECT_DAY]({ dispatch, commit, getters }, data) {
    commit(MutationTypes.UPDATE_CALENDAR_SELECTED_DAY, data)
    return dispatch(ActionTypes.SELECT_BAG, data.bag)
  },
  [ActionTypes.SELECT_BAG]({ dispatch, commit, getters }, data) {
    commit(MutationTypes.UPDATE_CALENDAR_SELECTED_BAG, data)
    return dispatch(ActionTypes.LOAD_BAG_DETAILS)
  },
  [ActionTypes.LOAD_BAG_DETAILS]({ dispatch, commit, getters }) {
    if (getters.getSelectedBag !== null) {
      commit(MutationTypes.UPDATE_BAG_IS_LOADING_DETAILS, true)
      ConfiguratorApi.getBagDetails(getters.getSelectedBag).then(response => {
        response.data.mealTypes = response.data.mealTypes.sort((el1, el2) => {
          return el1.position - el2.position
        })

        let bagDetails = { ...response.data, ...response.data.bag }
        commit(MutationTypes.UPDATE_BAG_DETAILS, bagDetails)
        commit(MutationTypes.UPDATE_BAG_IS_LOADING_DETAILS, false)
        dispatch(ActionTypes.LOAD_MEAL_BADGES)
      })
    }
  },
  [ActionTypes.LOAD_MEAL_BADGES]({ dispatch, commit, getters }) {
    if (getters.getBagDetails !== null) {
      const mealIds = []
      getters.getBagDetails.mealTypes.forEach(mealType => {
        mealType.items.forEach(item => {
          mealIds.push(item.id)
        })
      })
      ConfiguratorApi.getMealBadges(mealIds).then(({ data }) => {
        if (data?.data && data.data?.includes?.meal_badges?.length) {
          const badges = new Map(
            data?.data?.includes?.meal_badges.map(b => [b.id, b])
          )
          const badgesInfo = new Map(
            data?.data.results.map(result => [result.id, result.meal_badge_ids])
          )
          const detailsWithBadges = getters.getBagDetails
          detailsWithBadges.mealTypes.forEach(mealType => {
            mealType.items.forEach(item => {
              const badgesForMeal = badgesInfo.get(item.id)
              if (badgesForMeal?.length) {
                item.badge = badges.get(badgesForMeal[0])
              }
            })
          })
          commit(MutationTypes.UPDATE_BAG_DETAILS, detailsWithBadges)
        }
      })
    }
  },
  [ActionTypes.LOAD_CALENDAR]({ state, commit, dispatch, getters }) {
    const key =
      getters.getCalendarSelectedDiet +
      getters.getViewDate.format('YYYY-MM') +
      '-01'
    if (state.key !== key) {
      commit(MutationTypes.UPDATE_CALENDAR_KEY, key)
      commit(MutationTypes.UPDATE_BAG_DETAILS, null)

      return ConfiguratorApi.getCalendar(
        getters.getCalendarSelectedDiet,
        getters.getViewDate.format('YYYY-MM') + '-01'
      ).then(async res => {
        const day = dayjs(state.activeDay || res.data.activeDay).format('D') - 1
        commit(MutationTypes.UPDATE_CALENDAR_CALENDAR, res.data)

        if (res.data.days && Object.values(res.data.days).length) {
          if (res.data.days[day] && res.data.days[day].bag !== null) {
            await dispatch(ActionTypes.SELECT_DAY, res.data.days[day])
          }
        }
      })
    }
  },
  changeMenu({ commit }, bagID) {
    commit('CHANGE_MENU', bagID)
  },
  rateMeal({ commit }, bagID) {
    commit('RATE_MEAL', bagID)
  },
  changeOrderDate({ dispatch, commit, getters }, payload) {
    if (payload.newDate.$M !== getters.getViewDate.$M) {
      const key = `${getters.getCalendarSelectedDiet}${payload.newDate.format(
        'YYYY-MM'
      )}-01`
      commit(MutationTypes.UPDATE_CALENDAR_KEY, key)
      commit(MutationTypes.UPDATE_CALENDAR_VIEW_DATE, payload.newDate)
    }
    ConfiguratorApi.getCalendar(
      getters.getCalendarSelectedDiet,
      `${getters.getViewDate.format('YYYY-MM')}-01`
    ).then(async res => {
      commit(MutationTypes.UPDATE_CALENDAR_CALENDAR, res.data)
      const day = res.data.days.find(day => day.bag === payload.bagId)
      commit(MutationTypes.UPDATE_CALENDAR_SELECTED_DAY, day)
      dispatch(ActionTypes.LOAD_BAG_DETAILS)
    })
  }
}

const getters = {
  getSelectedBag(state) {
    return state.selectedBag
  },
  getSelectedDay(state) {
    return state.selectedDay
  },
  getCalendar(state) {
    return state.calendar
  },
  getViewDate(state) {
    return state.viewDate
  },
  isLoadingDayDetails(state) {
    return state.isLoadingDayDetails
  },
  getBagDetails(state) {
    return state.bagDetails
  }
}

const mutations = {
  [MutationTypes.UPDATE_ACTIVE_DAY](state, newVal) {
    state.activeDay = newVal
  },
  [MutationTypes.UPDATE_CALENDAR_VIEW_DATE](state, newVal) {
    state.viewDate = newVal
  },
  [MutationTypes.UPDATE_CALENDAR_INCREMENT_VIEW_DATE](state) {
    state.viewDate = state.viewDate.add(1, 'M')
  },
  [MutationTypes.UPDATE_CALENDAR_DE_INCREMENT_VIEW_DATE](state) {
    state.viewDate = state.viewDate.add(-1, 'M')
  },
  [MutationTypes.UPDATE_CALENDAR_CALENDAR](state, calendar) {
    state.calendar = calendar

    if (state.selectedDay !== null) {
      state.selectedDay = calendar.days[state.selectedDay.day]
    }
  },
  [MutationTypes.UPDATE_CALENDAR_SELECTED_BAG](state, selectedBag) {
    state.selectedBag = selectedBag
  },
  [MutationTypes.UPDATE_CALENDAR_SELECTED_DAY](state, selectedDay) {
    state.selectedDay = selectedDay
  },
  [MutationTypes.UPDATE_BAG_DETAILS](state, payload) {
    state.bagDetails = payload
  },
  [MutationTypes.UPDATE_BAG_DETAILS_MEAL_RATE](state, payload) {
    state.bagDetails.mealTypes[payload.index].selected.rate = payload.value
  },
  [MutationTypes.UPDATE_BAG_DETAILS_MEAL_COMMENT](state, payload) {
    state.bagDetails.mealTypes[payload.index].selected.comment = payload.value
  },
  [MutationTypes.UPDATE_BAG_DETAILS_SELECTED_MEAL](state, payload) {
    let bagItemId = state.bagDetails.mealTypes[payload.index].selected.bagItemId
    state.bagDetails.mealTypes[payload.index].selected = {
      ...payload.value,
      bagItemId
    }
  },
  [MutationTypes.UPDATE_BAG_IS_LOADING_DETAILS](state, value) {
    state.isLoadingDayDetails = value
  },
  [MutationTypes.RESET_CALENDAR](state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  [MutationTypes.UPDATE_CALENDAR_KEY](state, value) {
    state.key = value
  },
  CHANGE_MENU(state, bagID) {
    const dayIndex = state.calendar.days.findIndex(day => {
      return day.bag === bagID
    })

    state.calendar.days[dayIndex].isMenuChanged = true
  },
  RATE_MEAL(state, bagID) {
    const dayIndex = state.calendar.days.findIndex(day => {
      return day.bag === bagID
    })

    state.calendar.days[dayIndex].isRated = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
