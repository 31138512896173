/**
 * Import Dependency
 */
import axios from 'axios'
import store from './store'
import * as types from './store/mutations_types'
import router from './router'
import { LogIn } from './router/route_collection'
import auth from './services/api/auth'
import sentry from './sentry'

/**
 * Config
 */
axios.defaults.timeout = 120000

/**
 * Config
 */
axios.interceptors.request.use(
  config => {
    store.commit(types.LOADING_START)
    config.headers.common['X-BRAND'] = process.env.VUE_APP_BRAND_ID

    if (auth.isAuthorized()) {
      config.headers.common['Authorization'] =
        'Bearer ' + store.state.auth.token
    }
    if (store.getters.isImpersonated === true) {
      config.headers.common['X-IMPERSONATOR'] =
        store.getters.getImpersonatorToken
    }
    // if (process.env.VUE_APP_NODE_ENV !== 'production') {
    //   if (config.url.indexOf('?') > -1) {
    //     config.url = config.url + '&XDEBUG_SESSION_START=PHPSTORM'
    //   } else {
    //     config.url = config.url + '?XDEBUG_SESSION_START=PHPSTORM'
    //   }
    // }

    return config
  },
  error => {
    store.commit(types.LOADING_STOP)
    sentry.captureException(error)

    return Promise.reject(error)
  }
)
let retryUrl
let retryTimeout = 0
axios.interceptors.response.use(
  data => {
    store.commit(types.LOADING_STOP)

    return data
  },
  async error => {
    const errorCallback =
      error.response.config.errorCallback ||
      (error => {
        if (error.response.status === 425) {
          if (error.response.config.url !== retryUrl) {
            retryUrl = error.response.config.url
            retryTimeout = 0
          }
          setTimeout(() => {
            axios(error.response.config)
          }, ++retryTimeout * 1000)
          if (retryTimeout < 30) {
            return true
          } else {
            retryUrl = undefined
          }
        }

        return false
      })

    const prevent = errorCallback(error)
    if (true === prevent) return
    store.commit(types.LOADING_STOP)
    if (false === prevent) {
      sentry.captureException(error)
    }

    if (
      error.response &&
      error.response.status &&
      error.response.status === 401 &&
      !error.response.config.url.includes('login_check')
    ) {
      await store.commit(types.REMOVE_TOKEN)
      await store.commit(types.RESET_AUTH)

      window.location.href = '/user/log-in'
    }

    return Promise.reject(error)
  }
)

export default axios
