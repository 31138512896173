var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-7 calendar_main-calendar-wrapper"},[_c('div',{staticClass:"diet-select__container"},[_c('div',{staticClass:"diet-select__input-wrapper"},[_c('v-select',{attrs:{"label":"name","options":_vm.items,"reduce":function (item) { return item.id; },"clearable":false},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v("Brak pasujących opcji")]},proxy:true}]),model:{value:(_vm.selectedDietId),callback:function ($$v) {_vm.selectedDietId=$$v},expression:"selectedDietId"}})],1),_c('div',{staticClass:"diet-select__variant-info"},[_c('p',[_vm._v("Twoja domyślna dieta to:")]),_c('p',[_vm._v(_vm._s(_vm.variantName))])])]),_c('div',{staticClass:"calendar_main-calendar"},[_c('div',{staticClass:"calendar_main-calendarTitle"},[_c('a',{staticClass:"plg-ni_calendar_Calendar-prev",on:{"click":_vm.prevMonth}},[_c('img',{attrs:{"src":"/img/left.png","alt":""}})]),_c('h3',[_vm._v(_vm._s(_vm.displayData))]),_c('a',{staticClass:"plg-ni_calendar_Calendar-next",on:{"click":_vm.nextMonth}},[_c('img',{attrs:{"src":"/img/right.png","alt":""}})])]),_vm._m(0),(_vm.calendar)?_c('div',{staticClass:"calendar_main-calendarDays clearfix"},[_vm._l((_vm.calendar.pushDays),function(n){return _c('span',{key:n + 236,staticClass:"calendarDay muted plg-ni_calendar_Calendar-day"},[_c('i',{staticClass:"day__number"})])}),_vm._l((_vm.calendar.days),function(day){return _c('span',{key:day.day,staticClass:"calendarDay muted plg-ni_calendar_Calendar-day",class:{
          'menu ': day.bag !== null && !day.isDelivered,
          active:
            day.bag !== null && _vm.$store.getters.getSelectedBag === day.bag,
          rate: day.isDelivered && !day.isRated && _vm.canRateNow(day),
          wait:
            !day.isDelivered &&
            day.isMenuPrepared &&
            !day.isMenuChanged &&
            _vm.dateGt(day.canChangeMenuTo),
          selected:
            !day.isDelivered &&
            (day.isMenuChanged || !_vm.dateGt(day.canChangeMenuTo)),
          eaten: day.isDelivered && (day.isRated || !_vm.canRateNow(day))
        },on:{"click":function($event){return _vm.selectDay(day)}}},[_c('i',{staticClass:"day__number"},[_c('p',[_vm._v(_vm._s(_vm.formatCalendarDay(day.day)))])])])}),_vm._l((_vm.calendar.pushDaysAtEnd),function(n){return _c('span',{key:n + 321,staticClass:"calendarDay muted plg-ni_calendar_Calendar-day"})})],2):_vm._e()]),_c('h4',{staticClass:"subTitle"},[_c('span',{staticClass:"iconDownload"}),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.loadAllergenReport($event)}}},[_vm._v("Pobierz PDF ze składnikami i alergenami")])]),_vm._m(1),_vm._m(2),_vm._m(3),(_vm.showCalendarLegend)?_c('div',{staticClass:"calendar__legend"},[_c('p',[_vm._v(" Poniższe oznaczenia pomogą Ci wybrać menu dopasowane do Twoich potrzeb: ")]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar_main-calendarDayTitle plg-ni_calendar_Calendar-box"},[_c('span',[_vm._v("pon.")]),_c('span',[_vm._v("wt.")]),_c('span',[_vm._v("śr.")]),_c('span',[_vm._v("czw.")]),_c('span',[_vm._v("pt.")]),_c('span',[_vm._v("sob.")]),_c('span',[_vm._v("niedz.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"subTitle"},[_c('span',{staticClass:"iconStar"}),_vm._v("mamy nadzieję, że Ci smakowało, oceń nasze posiłki ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"subTitle"},[_c('span',{staticClass:"iconMeal"}),_vm._v("wybrałeś już menu")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"subTitle"},[_c('span',{staticClass:"iconMealGray"}),_vm._v("menu jest już gotowe, dowolnie konfiguruj swoje posiłki ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('b',[_vm._v("#LG")]),_vm._v(" – Less Gluten – potrawy z ograniczoną ilością glutenu")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('b',[_vm._v("#DF")]),_vm._v(" – Dairy Free – dania wykluczające nabiał")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('b',[_vm._v("#lowIG")]),_vm._v(" – dania z obniżonym indeksem glikemicznym")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('b',[_vm._v("#vege")]),_vm._v(" – dania bez mięsa")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('b',[_vm._v("#vege+fish")]),_vm._v(" – dania wegetariańskie, które zawierają ryby")])}]

export { render, staticRenderFns }