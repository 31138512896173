function parseTime(time) {
  const s = time.split(':')
  return parseInt(s[0], 10) * 60 + parseInt(s[1], 10)
}

function formatTime(timeIM) {
  const o = timeIM % 60
  return `${((timeIM - o) / 60 + '').padStart(2, '0')}:${(o + '').padStart(
    2,
    '0'
  )}`
}

function timeRange(input) {
  const { timeFrom, timeTo, interval, step } = input
  let timeIM = parseTime(timeFrom) + parseTime(interval)
  let timeToIM = parseTime(timeTo)
  let stepIM = parseTime(step)
  const timeArray = []
  while (timeIM <= timeToIM) {
    timeArray.push({
      value: `${formatTime(timeIM)}`,
      label: `Do ${formatTime(timeIM)}`
    })
    timeIM = timeIM + stepIM
  }

  return timeArray
}

export default timeRange
