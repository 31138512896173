<template>
  <div class="page-layout__logged-out">
    <header-index-view :fixed="true"></header-index-view>

    <main class="main center-content">
      <div class="container pop-up" style="max-width: 600px">
        <h3 class="h4 h4--popup m-b-15" style="text-transform: none">
          Republika Smakoszy
        </h3>
        <hr />

        <h3 class="h4 h4--popup m-b-15 title" style="font-weight: bold">
          Wpisz nowe hasło
        </h3>
        <hr />

        <div class="plg-ni_users_remind-formRemind">
          <div class="form-group">
            <input
              title="E-mail"
              placeholder="Hasło"
              class="form-control form-control--main"
              name="passwordFirst"
              type="password"
              v-model="passwordFirst"
            />

            <p
              class="subTitle text-danger plg-ni_order_register_userData-first_name-error"
              v-html="Object.values(errors.password.first).join('<br/> ')"
            ></p>
          </div>
          <div class="form-group">
            <input
              title="E-mail"
              placeholder="Hasło ponownie"
              class="form-control form-control--main"
              name="passwordSecond"
              type="password"
              v-model="passwordSecond"
              @keyup.enter="remind"
            />

            <p
              class="subTitle text-danger plg-ni_order_register_userData-first_name-error"
              v-html="Object.values(errors.password.second).join('<br/> ')"
            ></p>
          </div>
          <p
            class="subTitle text-danger plg-ni_order_register_userData-first_name-error"
            v-html="Object.values(errors.client).join('<br/> ')"
          ></p>

          <button
            type="submit"
            class="btn btn-main btn-main--custom m-b-10 new-btn plg-ni_users_remind-submitBtn"
            @click="remind"
          >
            Zresetuj
          </button>
          <input type="submit" style="display: none" />
        </div>
        <p class="text-white">
          Jeśli masz konto,
          <router-link :to="{ name: 'LogIn' }">zaloguj się.</router-link>
        </p>
      </div>
    </main>

    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderIndexView from '../../components/HeaderIndexView'
import FooterClientView from '../../components/FooterClientView'
import UserApi from '../../services/api/user'

export default {
  components: { HeaderIndexView, FooterClientView },
  name: 'Remind',
  data() {
    return {
      passwordFirst: '',
      passwordSecond: '',
      errorMsg: '',
      errors: {
        password: {
          first: {},
          second: {}
        },
        client: {}
      }
    }
  },
  methods: {
    async remind() {
      this.errors = {
        password: {
          first: {},
          second: {}
        },
        client: {}
      }
      UserApi.remindPasswordChange({
        client: this.$route.params.token,
        password: { first: this.passwordFirst, second: this.passwordSecond }
      })
        .then(res => {
          this.$swal({
            type: 'success',
            customClass: 'oldNtfyModal',
            title: 'UWAGA',
            showCloseButton: true,
            text: 'Twoje hasło zostało zmienione'
          })
        })
        .catch(err => {
          let errors = err.response.data.errors
          errors.password = Object.assign(
            {
              first: {},
              second: {}
            },
            errors.password
          )
          this.errors = Object.assign(
            {
              password: {},
              client: {}
            },
            errors
          )
        })
    }
  }
}
</script>
