import * as types from '../mutations_types'
import jwtDecode from 'jwt-decode'

const actions = {}

function initialState() {
  return {
    token: localStorage.getItem('token'),
    refresh_token: localStorage.getItem('refresh_token'),
    impersonator_token: localStorage.getItem('impersonator_token'),
    error: null,
    redirectAfterAuth: null
  }
}

const state = initialState()

const getters = {
  jwtDecoded: state => {
    let token = state.token || null
    if (token !== null) {
      return jwtDecode(state.token)
    }
  },
  redirectTo: state => state.redirectAfterAuth,
  hasRedirect: state => state.redirectAfterAuth !== null,
  isImpersonated: state =>
    state.impersonator_token !== null &&
    state.impersonator_token.length === 168,
  getImpersonatorToken: state => state.impersonator_token
}

const mutations = {
  [types.UPDATE_TOKEN](state, data) {
    localStorage.setItem('token', data.token)
    localStorage.setItem('refresh_token', data.refresh_token)

    state.token = data.token
    state.refresh_token = data.refresh_token
  },
  [types.UPDATE_REFRESH_TOKEN](state, refreshToken) {
    localStorage.setItem('refresh_token', refreshToken)

    state.refresh_token = refreshToken
  },
  [types.UPDATE_IMPERSONATOR_TOKEN](state, impersonatorToken) {
    localStorage.setItem('impersonator_token', impersonatorToken)

    state.impersonator_token = impersonatorToken
  },
  [types.REMOVE_TOKEN](state, payload) {
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('impersonator_token')

    state.token = null
    state.refresh_token = null
    state.impersonator_token = null
  },
  [types.AUTH_ERROR_CHANGE](state, error) {
    state.error = error
  },
  [types.UPDATE_REDIRECT_AFTER_LOGIN](state, value) {
    state.redirectAfterAuth = value
  },
  [types.RESET_AUTH](state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
