<template>
  <Layout>
    <Component
      :is="UserAddress"
      :initial="initial"
      :default-address="defaultAddress"
    />
  </Layout>
</template>

<script>
import MarginMixin from '@/helpers/mixins'
import Layout from '@/layouts/Layout.vue'
import UserAddress from '@/pages/user/UserAddress.vue'
export default {
  components: {
    UserAddress,
    Layout
  },
  data() {
    return {
      UserAddress
    }
  },
  mixins: [MarginMixin],
  name: 'UserAddressPage',
  props: {
    initial: {
      type: Object
    },
    defaultAddress: {
      type: Object,
      required: false,
      default: () => ({})
    }
  }
}
</script>
