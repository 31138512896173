import axios from '../../../interceptor'
import routes from '../routes'

export default {
  getConfig() {
    return axios.get(routes.configurator)
  },
  getDietPrice(params) {
    return axios.patch(routes.dietPrice, params)
  },
  getSelectedPackage(id) {
    return axios.get(routes.selectedPackage(id))
  },
  getMealsPrice(date) {
    return axios.get(routes.mealsPrice(date))
  },
  getDiscountInfo(discountCode) {
    return axios.patch(routes.checkDiscountCode, {
      discountCode: discountCode
    })
  },
  checkDiscountCodeAvailability(code) {
    return axios.post(routes.checkDiscountCodeAvailability, { code })
  },
  getFirstDeliveryDate() {
    return axios.get(routes.firstDeliveryDate)
  },
  getCalendar(dietId, date) {
    return axios.get(routes.calendar(dietId, date))
  },
  getBagDetails(bagId) {
    return axios.get(routes.bagDetails(bagId))
  },
  getMealBadges(mealsIds) {
    return axios.get(routes.fetchBadges(mealsIds))
  },
  checkDate(date, length) {
    return axios.get(routes.calendarCheckDate(date, length))
  }
}
