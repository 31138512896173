export const DIET_VARIANTS = {
  LOW_IG: 'NISKI IG',
  WEGE: 'WEGETARIAŃSKA',
  WEGE_WITH_FISH: 'WEGE + RYBA',
  NO_GLUTEN: 'MNIEJ GLUTENU',
  OPTIMAL: 'OPTYMALNA',
  SLIM: 'SLIM'
}

export const DIET_VARIANT_WARNINGS = {
  [DIET_VARIANTS.LOW_IG]:
    '#lowIG - tylko tak oznaczone dania maja obniżony indeks glikemiczny',
  [DIET_VARIANTS.WEGE]: '#vege - tylko tak oznaczone posiłki są wegetariańskie',
  [DIET_VARIANTS.WEGE_WITH_FISH]:
    '#vege - tylko tak oznaczone posiłki są wegetariańskie',
  [DIET_VARIANTS.NO_GLUTEN]:
    '#LG - tylko tak oznaczone posiłki ograniczają gluten',
  [DIET_VARIANTS.OPTIMAL]: null,
  [DIET_VARIANTS.SLIM]: null
}
