import * as types from '../mutations_types'
import * as actionTypes from '../actions_types'
import UserApi from '../../services/api/user'

const mapAddresses = el => {
  if (el.deliveryHourFrom === null && el.deliveryHourTo === null) {
    el.hours = ''
  } else if (el.deliveryHourFrom === null) {
    el.hours = 'do ' + el.deliveryHourTo
  } else if (el.deliveryHourTo === null) {
    el.hours = 'od ' + el.deliveryHourFrom
  } else {
    el.hours = el.deliveryHourFrom + ' - ' + el.deliveryHourTo
  }

  return el
}

const actions = {
  [actionTypes.FETCH_CLIENT_DATA_ONCE]({ dispatch, state }) {
    if (!state.actionsUsage.includes(actionTypes.FETCH_CLIENT_DATA)) {
      dispatch(actionTypes.FETCH_CLIENT_DATA)
    }
    state.actionsUsage = [...state.actionsUsage, actionTypes.FETCH_CLIENT_DATA]
  },
  [actionTypes.FETCH_CLIENT_DATA]({ commit }) {
    return UserApi.getMyData().then(
      successResponse => {
        commit(types.UPDATE_CLIENT_FULL_DATA, successResponse.data)

        return successResponse
      },
      failedResponse => {
        console.error(failedResponse)
      }
    )
  },
  [actionTypes.FETCH_CLIENT_ADDRESSES]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      if (getters.getClientAddresses.length > 0) {
        resolve()
        return
      }

      UserApi.fetchAddresses().then(successResponse => {
        const addresses = successResponse.data.reduce(
          (a, c) => {
            a[
              c.isSaturdayByDefault || c.isStandardByDefault
                ? 'defaults'
                : 'other'
            ].push(c)
            return a
          },
          { defaults: [], other: [] }
        )

        commit(
          types.UPDATE_CLIENT_ADDRESSES,
          addresses.defaults.map(mapAddresses)
        )
        commit(
          types.UPDATE_CLIENT_OTHER_ADDRESSES,
          addresses.other.map(mapAddresses)
        )
        resolve()
      })
    })
  }
}

function initialState() {
  return {
    client: null,
    actionsUsage: [],
    orderStep: 0,
    orderSteps: [],
    addresses: [],
    otherAddresses: []
  }
}

const state = initialState()

const getters = {
  getClient: state => state.client,
  getClientAddresses: state => state.addresses,
  getOrderSteps: state => state.orderSteps,
  getOrderStep: state => state.orderStep,
  differentWeekendAddress: state => {
    return !(
      state.addresses.length > 1 &&
      state.addresses[0].city === state.addresses[1].city &&
      state.addresses[0].street === state.addresses[1].street &&
      state.addresses[0].buildingNr === state.addresses[1].buildingNr &&
      state.addresses[0].floorNr === state.addresses[1].floorNr &&
      state.addresses[0].placeNr === state.addresses[1].placeNr
    )
  },
  mgm: state => state.client.mgm
}

const mutations = {
  [types.UPDATE_CLIENT_FULL_DATA](state, value) {
    state.client = value
  },
  [types.UPDATE_CLIENT_ADDRESSES](state, value) {
    state.addresses = value
  },
  [types.UPDATE_CLIENT_OTHER_ADDRESSES](state, value) {
    state.otherAddresses = value
  },
  [types.UPDATE_ORDER_STEP](state, payload) {
    state.orderStep = payload
  },
  [types.UPDATE_ORDER_STEPS](state, payload) {
    state.orderSteps = payload
  },
  [types.RESET_USER](state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
