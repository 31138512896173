<template xmlns:mailto="http://www.w3.org/1999/xhtml">
  <div
    class="alert alert-calendar col-md-8"
    style="font-size: 18px; text-align: center; width: 100%"
  >
    <span
      class="pull-right"
      style="margin-left: 15px; color: black; cursor: pointer; transition: 0.7s"
      onclick="this.parentElement.style.display='none';"
    >
      &times;
    </span>
    <h3 style="text-transform: none" class="center">Cześć!</h3>
    <br />
    <p>
      W Boże Ciało (30.05) nasze biuro obsługi klienta będzie nieczynne. Wracamy już 31.05 o 6:00 :)
    </p>
  </div>
</template>

<script>
export default {
  name: 'NtfyMessageTemporary'
}
</script>
