<template>
  <div class="flex justify-center">
    <button
      type="button"
      class="button-outlined max-w-250"
      v-if="$store.getters.getOrderSteps[$store.getters.getOrderStep].back"
      @click="back"
    >
      wstecz
    </button>
    <button
      type="button"
      class="button-red max-w-250 ml-5"
      v-if="$store.getters.getOrderSteps[$store.getters.getOrderStep].next"
      @click="next"
    >
      dalej
    </button>
  </div>
</template>

<script>
export default {
  name: 'OrderBackNext',
  methods: {
    next() {
      this.$store.getters.getOrderSteps[this.$store.getters.getOrderStep].next()
    },
    back() {
      this.$store.getters.getOrderSteps[this.$store.getters.getOrderStep].back()
    }
  }
}
</script>
