<template>
  <div class="page-layout__logged-out">
    <header-index-view />

    <main class="main center-content">
      <div class="container pop-up form-container">
        <h3
          class="h4 h4--popup mb-5 title text-center"
          style="font-weight: bold"
        >
          Zaloguj się
        </h3>

        <form
          method="post"
          action="#"
          class="plg-ni_users_login-formLogin"
          @submit="login($event)"
        >
          <div class="form-group">
            <input
              placeholder="Adres e-mail"
              class="form-control form-control--main"
              autocomplete="off"
              name="username"
              type="text"
              v-model="username"
            />
          </div>

          <div class="form-group">
            <input
              placeholder="Hasło"
              class="form-control form-control--main"
              autocomplete="off"
              name="password"
              type="password"
              v-model="password"
            />
            <span class="unsuccess__text" v-if="error() !== null">{{
              error()
            }}</span>
          </div>

          <p class="text-white text-center">
            Zapomniałeś hasła?
            <router-link :to="{ name: routeCollection.Remind }"
              >Przypomnij.</router-link
            >
          </p>
          <br />

          <button
            type="submit"
            class="btn btn-main btn-main--custom m-b-10 new-btn login-btn plg-ni_users_login-submitBtn"
          >
            zaloguj się
          </button>

          <a class="plg-ni_users_login-facebookBtn">
            <button
              type="button"
              class="btn btn-main btn-main--custom new-btn fb-btn"
              @click="facebook($event)"
            >
              Zaloguj się przez Facebooka
            </button>
          </a>
          <p class="text-white text-center" style="margin-top: 20px">
            <span class="register">
              Nie masz profilu?
              <router-link :to="{ name: routeCollection.Register }"
                ><b>Zarejestruj się</b>.</router-link
              >
            </span>
          </p>
        </form>
      </div>
    </main>

    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderIndexView from '../../components/HeaderIndexView'
import FooterClientView from '../../components/FooterClientView'
import auth from '../../services/api/auth'
import * as RouteCollection from '../../router/route_collection'

export default {
  components: { HeaderIndexView, FooterClientView },
  name: 'LogIn',
  data() {
    return {
      username: '',
      password: '',
      routeCollection: RouteCollection
    }
  },
  methods: {
    login(event) {
      event.preventDefault()

      auth.login({
        _username: process.env.VUE_APP_BRAND_ID + '_' + this.username,
        _password: this.password
      })
    },
    error() {
      return this.$store.state.auth.error
    }
  }
}
</script>
