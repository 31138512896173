var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"address-form diet-configuration__container mt-8 mb-8 order_register_steps_offset_top"},[_c('div',{staticClass:"diet-configuration__content-wrapper"},[_c('OrderStep',{on:{"next":_vm.next}}),_c('br'),(!this.isDetails)?_c('UserAddress0',{staticClass:"mb-3",attrs:{"form":_vm.form},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}},[_vm._v(" "+_vm._s(_vm.$t((_vm.isEdit ? 'Edit' : 'Add') + ' delivery address'))+" ")]):_vm._e(),(_vm.isDetails || _vm.intentDetails)?_c('UserAddress1',{directives:[{name:"show",rawName:"v-show",value:(!_vm.intentDetails),expression:"!intentDetails"}],staticClass:"mb-3",on:{"intent":_vm.intent},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}},[_vm._v(" "+_vm._s(_vm.$t((_vm.isEdit ? 'Edit' : 'Add') + ' address details'))+" ")]):_vm._e(),(
          !_vm.$store.getters.jwtDecoded.hasRequiredAddresses && !this.isDetails
        )?_c('CheckboxVertical',{staticClass:"justify-center mb-3",attrs:{"form":_vm.form,"label":"Different delivery address on Saturdays and public holidays"},model:{value:(_vm.alt),callback:function ($$v) {_vm.alt=$$v},expression:"alt"}}):_vm._e(),(
          !_vm.$store.getters.jwtDecoded.hasRequiredAddresses && _vm.alt && !_vm.isDetails
        )?_c('UserAddress0',{staticClass:"mb-3",attrs:{"form":_vm.form},model:{value:(_vm.altAddress),callback:function ($$v) {_vm.altAddress=$$v},expression:"altAddress"}},[_vm._v(" "+_vm._s(_vm.$t('Add delivery address on Saturdays and public holidays'))+" ")]):_vm._e(),(
          !_vm.$store.getters.jwtDecoded.hasRequiredAddresses &&
          _vm.alt &&
          (_vm.isDetails || _vm.intentDetails)
        )?_c('UserAddress1',{directives:[{name:"show",rawName:"v-show",value:(!_vm.intentDetails),expression:"!intentDetails"}],staticClass:"mb-3",attrs:{"form":_vm.form},model:{value:(_vm.altAddress),callback:function ($$v) {_vm.altAddress=$$v},expression:"altAddress"}},[_vm._v(" "+_vm._s(_vm.$t('Add address details on Saturdays and public holidays'))+" ")]):_vm._e(),_c('div',{staticClass:"row"},[_c('OrderBackNext',{staticClass:"col-sm-12 center-content mt-8"})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }