<template>
  <div class="page-layout">
    <script
      type="application/javascript"
      src="https://apis.google.com/js/platform.js?onload=renderOptIn"
      async
      defer
    ></script>
    <!--    <header-client-view></header-client-view>-->
    <header-client-view></header-client-view>
    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <div class="container">
        <div class="order_thanks">
          <h1 class="mainTitle text-uppercase text-center">Dziękujemy</h1>
          <h4 class="text-center sub-title">
            Właśnie dodałeś do swojej diety dodatkowy posiłek.
          </h4>
          <h4 class="text-center sub-title">
            Czekamy na potwierdzenie płatności.
          </h4>

          <div class="order_thanks-row clearfix">
            <div class="order_thanks-col">
              <div class="order_thanks-img">
                <img alt="calendar" src="@/assets/svg/delivery-guy.svg" />
              </div>
              <p class="">
                Dodatkowy posiłek zostanie dostarczony w wybranym przez Ciebie
                terminie na wskazany adres.
              </p>
            </div>

            <div class="order order_thanks-col">
              <div class="order_thanks-img">
                <img alt="calendar" src="@/assets/svg/dinner.svg" />
              </div>

              <p class="">
                Pamiętaj, że już teraz możesz wybierać posiłki i zmieniać
                terminy dostaw.
              </p>
            </div>

            <div class="order order_thanks-col">
              <div class="order_thanks-img">
                <img alt="calendar" src="@/assets/svg/calendar_big.svg" />
              </div>

              <p class="">
                Dodatkowy posiłek będzie widoczny na Twoim profilu po otrzymaniu
                płatności, może to chwilę potrwać.
              </p>
            </div>
          </div>
          <div class="text-center">
            <router-link
              :to="{ name: 'CalendarBrowser' }"
              class="button-red max-w-250"
            >
              WRÓĆ DO KALENDARZA
            </router-link>
          </div>
        </div>
      </div>
    </main>

    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '../../components/HeaderClientView'
import FooterClientView from '../../components/FooterClientView'
import * as RouteCollection from '../../router/route_collection'
import * as actionTypes from '../../store/actions_types'
import router from '@/router'
import MarginMixin from '../../helpers/mixins'

export default {
  components: { HeaderClientView, FooterClientView },
  mixins: [MarginMixin],
  name: 'thanks',
  props: {
    id: null
  },
  data() {
    return {
      order: null,
      orderFetched: false,
      orderId: null
    }
  },
  mounted() {
    this.orderId = this.$route.params?.id
    if (this.orderId) {
      this.fetchOrder(this.orderId)
    }
  },
  watch: {
    orderFetched: function () {
      const mealNames = []
      const products = []
      this.order.orderItems.forEach(item => {
        mealNames.push(item.bagItem.mealType.name)
        const data = {
          name: `${item.bagItem.mealType.name}_${item.bagItem.size.nameForClient}`,
          price: item.bagItem.priceBeforeDiscount,
          brand: 'RS',
          category: `${item.bagItem.mealType.name}/${item.bagItem.package.name}/${item.bagItem.variant.name}/${item.bagItem.size.calorific}`,
          variant: item.bagItem.size.nameForClient,
          quantity: 1,
          metric5: item.bagItem.size.calorific
        }

        const index = products.findIndex(product => product.name === data.name)
        if (index >= 0) {
          products[index].quantity += 1
        } else {
          products.push(data)
        }
      })

      const gtmData = {
        event: 'transaction',
        dimension3: 'stary classic',
        dimension4: 'change',
        dimension5: mealNames.join(', '),
        dimension6: this.order.totalSize,
        dimension7: this.order.isWeekendsIncluded
          ? 'weekend_address'
          : 'standard_address',
        metric2: 1,
        metric3: this.order.priceFromMoneybox,
        metric4: this.order.priceDiscount,
        ecommerce: {
          purchase: {
            actionField: {
              id: this.order.id,
              revenue: this.order.priceAfterDiscount,
              coupon: this.order.discountCode
                ? this.order.discountCode
                : 'without_coupon',
              products
            }
          }
        }
      }

      window.dataLayer.push(gtmData)
    }
  },
  methods: {
    fetchOrder(id) {
      this.$store.dispatch(actionTypes.FETCH_ORDER, { id: id }).then(
        order => {
          this.order = order
          this.orderFetched = true
        },
        e => {
          this.handleError(e, false)
        }
      )
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1280px;
}

.sub-title {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.order_thanks-row > .order_thanks-col::after {
  content: none;
}

.btn-yellow {
  border-radius: 30px;
  background: #ff4068;
  font-size: 24px;
}
</style>
