<template>
  <div
    class="component-dialog-content-wrapper flex justify-center align-items-center"
  >
    <div>
      <h2 class="mb-8 text-center">Zweryfikuj swój numer telefonu</h2>
      <p class="text-center">
        Na podany przez Ciebie numer telefonu wysłaliśmy kod weryfikacyjny.
      </p>
      <p class="mb-8 text-center">
        Wprowadź go poniżej by utworzyć konto i aktywować kod promocyjny.
      </p>
      <br />

      <div class="input-wrapper">
        <h3 class="mb-1">Kod weryfikacyjny</h3>
        <input
          type="text"
          :value="value"
          @input="$emit('input', $event.target.value)"
          placeholder="Wprowadź kod"
          class="form-control form-control--main"
          :class="{ error: !!error }"
        />

        <p v-if="error" class="text-danger">
          <small>
            {{ $ta(error) }}
          </small>
        </p>
        <br />

        <button class="btn button-red" @click.prevent="submit">
          Zweryfikuj i utwórz konto
        </button>

        <div v-if="countdown > 0" class="text-center mt-2">
          <template v-if="retried">
            <small v-if="countdown > 30">
              Nowy kod zostat wystany na
              {{ phoneFormatted }}
            </small>
            <small v-else>
              Poczekaj {{ countdown }} sekund by wysłać kolejny kod
            </small>
          </template>
        </div>
        <div v-if="!retried || countdown === 0">
          <small>
            <p class="text-center mt-2">
              Nie masz kodu?
              <b class="cursor-pointer" @click="retry">
                <u>Wyślij ponownie</u>
              </b>
            </p>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerificationCodeForm',
  props: {
    countdown: null,
    phone: {
      type: [String, Object],
      required: true
    },
    value: {
      type: String,
      required: false
    },
    error: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      retried: false,
      phoneFormatted: ''
    }
  },
  methods: {
    submit() {
      this.retried = false
      this.$emit('submit')
    },
    retry() {
      if (this.countdown <= 0) {
        this.$emit('submit', false)
      }
      this.retried = true
    },
    $ta(message) {
      const prefix = 'verificationCode'
      const key = `${prefix}.${message}`
      const translation = this.$t(key)
      return translation === key ? message : translation
    }
  },
  mounted() {
    this.phoneFormatted = this.phone
      .toString()
      .replace(/^(\d{3})(\d{3})(\d{3})$/, '(+48) $1-$2-$3')
    this.$emit('input', '')
  },
  beforeDestroy() {
    this.$emit('input', undefined)
  }
}
</script>

<style scoped>
input.form-control.error {
  box-shadow: 0 0 5px #ff758b;
  border: 1px solid #ff758b;
}
input.form-control.form-control--main {
  height: 50px;
  font-size: inherit;
}
.btn.button-red {
  font-size: inherit;
  height: inherit;
  margin-bottom: inherit;
}
.input-wrapper {
  width: clamp(100px, 300px, 80vw);
  margin: 0 auto;
}
.component-dialog-content-wrapper {
  background: white;
  min-height: 400px;
  margin: 20px 15px;
  padding: 12px 20px;
  border-radius: 4px;
}
@media (max-width: 629px) {
  .component-dialog-content-wrapper {
    width: calc(100vw - 30px);
  }
}
@media (min-width: 630px) {
  .component-dialog-content-wrapper {
    min-width: 600px;
  }
}
</style>
