<template xmlns:mailto="http://www.w3.org/1999/xhtml">
  <div
    class="alert alert-calendar alert-calendar__mobile"
    style="font-size: 18px; text-align: center; width: 100%"
  >
    <span
      class="pull-right"
      style="margin-left: 15px; color: black; cursor: pointer; transition: 0.7s"
      onclick="this.parentElement.style.display='none';"
      >&times;</span
    >
    <h3 class="center">Nowe możliwości w aplikacji mobilnej!</h3>
    <br />
    <p>
      Dodatkowe funkcjonalności ułatwią planowanie diety Twojej i Twoich
      bliskich. Co czeka Cię po pobraniu apki?
    </p>
    <br />
    <p>
      1. Nieograniczona liczba posiłków → Zamawiaj dla siebie, dzieci, rodziny
      albo przyjaciół.
    </p>
    <p>
      2. Wybór kaloryczności poszczególnych posiłków → Dobieraj wielkości potraw
      do indywidualnych potrzeb Twoich bliskich lub np. swojego zmiennego
      zapotrzebowania.
    </p>
    <p>
      3. Personalizacja dni dostaw → Dopasuj kalendarz do swoich dni spędzonych
      na pracy zdalnej, urlopie czy w delegacji.
    </p>
    <br />
    <p>Pobierz aplikację i odkryj udoskonalenia już dziś!</p>
  </div>
</template>

<script>
export default {
  name: 'NtfyExpandOffer'
}
</script>
