import * as types from '../mutations_types'

const actions = {}

function initialState() {
  return {
    selected: null
  }
}

const state = initialState()

const getters = {
  getCalendarSelectedDiet: state => state.selected
}

const mutations = {
  [types.DIET_SELECTED](state, value) {
    state.selected = value
  },
  [types.RESET_DIET](state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
