export default {
  computed: {
    addMarginToMain() {
      return this.$date().isBetween(
        '2021-08-24 00:00:00',
        '2021-09-07 23:59:59'
      )
    }
  }
}
