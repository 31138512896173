<template>
  <div>
    <h4 class="labelSmall"></h4>
    <h4 class="title" v-if="isRated">TWOJE POSIŁKI ZOSTAŁY OCENIONE</h4>
    <h4 class="title" v-else>NIE OCENIŁEŚ POSIŁKÓW</h4>
  </div>
</template>

<script>
export default {
  name: 'DayExpiredForm',
  computed: {
    bagDetails: function () {
      return this.$store.getters.getBagDetails
    },
    isRated() {
      return this.$store.getters.getSelectedDay.isRated
    }
  }
}
</script>
