// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import './assets/frontend/fonts.scss'
import './assets/frontend/style.scss'
import './assets/frontend/front.scss'
import './assets/frontend/fontawesome-stars.css'
import './assets/frontend/jquery-ui.css'
import './assets/frontend/font-awesome.css'
import './assets/frontend/popup.css'
import './assets/frontend/common.scss'
import './assets/frontend/order/common.css'
import './assets/frontend/transaction_history.scss'
import './assets/frontend/order/myData.css'
import './assets/frontend/order/order.css'
import './assets/frontend/order/summary.css'
import './assets/frontend/order/thanks.css'
import './assets/frontend/order/transfer.css'
import './assets/frontend/cookie.css'
import './assets/frontend/main.css'
import './assets/frontend/mobile.css'
import './assets/frontend/own_styles.scss'
import './assets/frontend/configurator_styles.scss'
import './assets/frontend/partner_program.css'
import './assets/frontend/moneybox.scss'
import './assets/frontend/diet-configuration.scss'

import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import i18n from './locales'
/* eslint-disable */
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import UserApi from './services/api/user'
import * as MutationTypes from './store/mutations_types'
import * as RouteCollection from './router/route_collection'
import sentry from './sentry'

import './plugins/dayjs'
import VueMask from 'v-mask'

Vue.use(VueMask)

window.fbAsyncInit = function() {
  FB.init({
    appId            : process.env.VUE_APP_FACEBOOK_APP_ID,
    autoLogAppEvents : true,
    xfbml            : true,
    version          : 'v2.4'
  });
};

(function(d, s, id){
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {return;}
  js = d.createElement(s); js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));


// CALLPAGE
var __cp={"id":"6iBIcMFhN-J3tQ99tr1FJEdeG3hQq2nR4FGzXbo6FbQ","version":"1.1"};(function(window,document){var cp=document.createElement('script');cp.type='text/javascript';cp.async=true;cp.src="++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g,'/').replace(/[=]/g,'.');var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp,s);if(window.callpage){alert('You could have only 1 CallPage code on your website!');}else{window.callpage=function(method){if(method=='__getQueue'){return this.methods;}
    else if(method){if(typeof window.callpage.execute==='function'){return window.callpage.execute.apply(this,arguments);}
    else{(this.methods=this.methods||[]).push({arguments:arguments});}}};window.callpage.__cp=__cp;window.callpage('api.button.autoshow');}})(window,document);
// END CALLPAGE

// ZENDESK
/*<![CDATA[*/
// window.zEmbed||function(e,t){var n,o,d,i,s,a=[],r=document.createElement("iframe");window.zEmbed=function(){a.push(arguments)},window.zE=window.zE||window.zEmbed,r.src="javascript:false",r.title="",r.role="presentation",(r.frameElement||r).style.cssText="display: none",d=document.getElementsByTagName("script"),d=d[d.length-1],d.parentNode.insertBefore(r,d),i=r.contentWindow,s=i.document;try{o=s}catch(e){n=document.domain,r.src='javascript:var d=document.open();d.domain="'+n+'";void(0);',o=s}o.open()._l=function(){var e=this.createElement("script");n&&(this.domain=n),e.id="js-iframe-async",e.src="https://assets.zendesk.com/embeddable_framework/main.js",this.t=+new Date,this.zendeskHost="ntfy.zendesk.com",this.zEQueue=a,this.body.appendChild(e)},o.write('<body onload="document._l();">'),o.close()}();
/*]]>*/
// zE(function(){
//     $zopim(function() {
//     $zopim.livechat.setLanguage('pl');
//     $zopim.livechat.window.setTitle('CZAT');
//     $zopim.livechat.concierge.setName('REPUBLIKA SMAKOSZY');
//     $zopim.livechat.concierge.setTitle('W czym możemy Ci pomóc?');
//     $zopim.livechat.concierge.setAvatar('/favicon.png');
// });
// });
// END ZENDESK

// (function(o){var b="https://nimbleswan.io/anywhere/",t="3d288dd6e81f4b93bf4d4ac3d20e349148a955a0465f46e5874bf9b6654744a3",a=window.AutopilotAnywhere={_runQueue:[],run:function(){this._runQueue.push(arguments);}},c=encodeURIComponent,s="SCRIPT",d=document,l=d.getElementsByTagName(s)[0],p="t="+c(d.title||"")+"&u="+c(d.location.href||"")+"&r="+c(d.referrer||""),j="text/javascript",z,y;if(!window.Autopilot) window.Autopilot=a;if(o.app) p="devmode=true&"+p;z=function(src,asy){var e=d.createElement(s);e.src=src;e.type=j;e.async=asy;l.parentNode.insertBefore(e,l);};y=function(){z(b+t+'?'+p,true);};if(window.attachEvent){window.attachEvent("onload",y);}else{window.addEventListener("load",y,false);}})({});

// Initialize Firebase
// var config = {
//   apiKey: "AIzaSyBYbfzlCQ8m__Bv1u--Lr_9XOGf0WOYqu8",
//   authDomain: "ntfy-mobile.firebaseapp.com",
//   databaseURL: "https://ntfy-mobile.firebaseio.com",
//   projectId: "ntfy-mobile",
//   storageBucket: "ntfy-mobile.appspot.com",
//   messagingSenderId: "1064852823860"
// };
// firebase.initializeApp(config);

Vue.mixin({
  methods: {
    facebook (event) {
      event.preventDefault()

      window.FB.login(response => {
        if (response.authResponse) {
          UserApi.facebook({accessToken: JSON.stringify(response.authResponse.accessToken)})
            .then((res) => {
              this.$store.commit(MutationTypes.UPDATE_TOKEN, res.data)

              // window.dataLayer.push({
              //   event: 'login',
              //   eventCategory: 'Customer',
              //   eventAction: 'Login',
              //   eventLabel: 'Facebook'
              // })

              this.$router.push({name: RouteCollection.OrderCreate})
            })
            .catch(errRes => {
              if (errRes.response.status === 422) {
                this.errors = errRes.response.data.errors
              }
            })
        }
      }, {scope: 'email'})
    },
    handleError (e, showMessage = true) {
      this.$raven.captureException(e)

      if (showMessage) {
        this.$swal({
          type: 'error',
          title: 'Oops...',
          text: 'Coś poszło nie tak!'
        })
      }
    }
  }
})

Vue.use(VueSweetalert2);

Vue.config.productionTip = false

const lang = store.state.language
if (lang) {
  i18n.locale = lang
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
