<template>
  <div></div>
</template>

<script>
import * as MutationTypes from '../store/mutations_types'
import { REDIRECT_SOURCES } from '../consts/redirectSources'
import { OrderCreate } from '../router/route_collection'

export default {
  name: 'Setup',
  mounted() {
    let calorie = this.$route.query.calorie
    let type = this.$route.query.type
    let diet = this.$route.query.diet
    let dietId = this.$route.query.dietId || undefined
    let dietLength = parseInt(this.$route.query?.days || 10)
    let meals0 = parseInt(this.$route.query['meals[0]']) || undefined
    let meals1 = parseInt(this.$route.query['meals[1]']) || undefined
    let meals2 = parseInt(this.$route.query['meals[2]']) || undefined
    let meals3 = parseInt(this.$route.query['meals[3]']) || undefined
    let meals4 = parseInt(this.$route.query['meals[4]']) || undefined

    let meals = [meals0, meals1, meals2, meals3, meals4]
    meals = meals.filter(id => id !== undefined)

    this.$store
      .dispatch(MutationTypes.SET_DATA_FROM_LP, {
        calorie,
        type,
        diet,
        dietId,
        dietLength,
        meals
      })
      .then(() => {
        this.$router.push({
          name: OrderCreate,
          params: { source: REDIRECT_SOURCES.LANDING_PAGE }
        })
      })
  }
}
</script>
