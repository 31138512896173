<template>
  <div class="page-layout">
    <header-client-view></header-client-view>

    <main
      class="main main__partner-program"
      :class="{ 'expanded-navbar': addMarginToMain }"
    >
      <div class="container" v-if="null !== mgm">
        <div class="users_programPartner">
          <div class="partner_program">
            <h1 class="diet-configuration__header text-uppercase text-center">
              Zdobądź punkty
            </h1>

            <div class="white-background p-4">
              <div v-if="mgm">
                <p style="font-size: 20px">
                  <b>1. Polecaj nas znajomym</b>
                </p>
                <br />
                <p style="font-size: 20px; margin-left: 23px">
                  Skopiuj poniższy kod polecający i wyślij osobie, którą chcesz
                  zaprosić. Za każde wydane <b>{{ mgm.threshold }} zł</b> przez
                  tą osobę Ty dostajesz <b>{{ mgm.value }} punkt</b>. Zasada
                  taka obowiązuje przez <b>{{ mgm.period }} miesiące</b> od
                  momentu założenia konta przez tę osobę.
                </p>
                <br />
                <br />
                <div class="row">
                  <div class="col-sm-6">
                    <div class="pp_code text-center" style="user-select: none">
                      <div class="ppc_row" style="margin: 0 auto">
                        <p><b>Twój kod polecający</b></p>
                        <div class="well code" style="user-select: all">
                          {{ mgm.code }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div
                      class="pp_code text-center"
                      style="user-select: none"
                      @click="copyToClipboard"
                    >
                      <div class="ppc_row">
                        <p><b>Bezpośredni link</b></p>
                        <div class="well link">
                          <a style="user-select: all">{{ getRefererUri() }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p style="font-size: 20px">
                  <b>2. Punkty za polecenie </b>
                </p>
                <br />

                <p style="font-size: 20px; margin-left: 23px">
                  Punkty naliczają się raz w tygodniu (z niedzieli na
                  poniedziałek) na podstawie zrealizowanych dostaw.
                </p>
                <br />
                <br />

                <p style="font-size: 20px">
                  <b>3. Punkty za wspólne dostawy</b>
                </p>
                <br />

                <p style="font-size: 20px; margin-left: 23px">
                  Każda wspólna dostawa (z sąsiadem, partnerem, współlokatorem)
                  jest nagradzana. Jeśli na jeden adres dostarczymy 2 dostawy,
                  każdy z Was otrzyma 2 punkty. Jeśli 3 dostawy i więcej to 3
                  punkty.
                </p>
                <br /><br />
              </div>

              <div style="padding: 0 23px; width: 100%">
                <table class="moneybox__table" style="max-width: none">
                  <tbody>
                    <tr>
                      <th>Ilość osób</th>
                      <th>Ilość punktów dla każdej osoby</th>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>3 i więcej</td>
                      <td>3</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!--              <br />-->
              <!--              <br />-->
              <!--              <p style="font-size: 20px; text-align: center">-->
              <!--                Dodatkowe punkty to prosta sprawa!-->
              <!--              </p>-->
              <!--              <p style="font-size: 20px; text-align: center">-->
              <!--                Wystarczy, że udostępnisz znajomym swój indywidualny kod-->
              <!--                rabatowy wielokrotnego użytku. Dzięki temu Twój znajomy-->
              <!--                konfigurując dietę będzie mógł skorzystać z 5% rabatu, a Ty-->
              <!--                zyskasz 1 punkt po każdym wykorzystanym przez niego dniu.-->
              <!--              </p>-->
              <!--              <p style="font-size: 20px; text-align: center">-->
              <!--                Warunkiem koniecznym jest wpisanie i aktywacja kodu przy-->
              <!--                konfiguracji każdej diety.-->
              <!--              </p>-->
              <!--              <p-->
              <!--                style="font-size: 20px; text-align: center; margin-bottom: 50px"-->
              <!--              >-->
              <!--                Im więcej zamówień z Twojego polecenia, tym więcej punktów w-->
              <!--                Twojej skarbonce!-->
              <!--              </p>-->

              <!--              <div class="pp_code">-->
              <!--                <div class="ppc_row">-->
              <!--                  <p>Twój kod</p>-->
              <!--                  <div class="well code">-->
              <!--                    {{ $store.getters.jwtDecoded.recommendedCode }}-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="ppc_row">-->
              <!--                  <p>Bezpośredni link</p>-->
              <!--                  <div class="well link">-->
              <!--                    <a>{{ getRefererUri() }}</a>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="ppc_row fb_share">-->
              <!--                  <div class="well">-->
              <!--                    <div class="center" style="display: block">-->
              <!--                      Udostepnij przez:-->
              <!--                    </div>-->
              <!--                    <a class="ppc_share" @click="copyToClipboard"-->
              <!--                      >Skopiuj do schowka</a-->
              <!--                    >-->
              <!--                    <a class="ppc_share fb-share" @click.prevent="shareFB()">-->
              <!--                      Facebook-->
              <!--                    </a>-->

              <!--                    <a-->
              <!--                      class="ppc_share messanger-share"-->
              <!--                      @click.prevent="shareMessenger()"-->
              <!--                    >-->
              <!--                      Messenger-->
              <!--                    </a>-->

              <!--                    <a class="ppc_share email-share" :href="mailTo"> Email </a>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>

      <!--======== cookesBox ========-->
      <div class="cookseBox" id="cookseBox">
        <a onclick="cookieOff('ntfy', 1, 360);" class="cookesBox__close">
          <i class="fa fa-close"></i>
        </a>

        <p class="cookseBox__content">
          Ciasteczka to jedyna niedietetyczna rzecz u nas. Korzystamy z nich w
          celu świadczenia usług. Zmiana ustawień plików cookie jest możliwa w
          każdym momencie.
        </p>
      </div>
      <!--======== /END cookesBox ========-->
      <div class="dialog" style="display: none; opacity: 0"></div>
      <div
        class="dialog-content"
        style="display: none; opacity: 0; top: -100px; background: #ffe123"
      >
        <a href="javascript:void(0)" class="js-close-button pop-up__close">
          &nbsp;
        </a>

        <!-- content-box -->
        <div class="content-box content-box--mr-none"></div>
      </div>
    </main>
    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '@/components/HeaderClientView'
import FooterClientView from '@/components/FooterClientView'
import MarginMixin from '../../helpers/mixins'
import * as ActionTypes from '@/store/actions_types'

export default {
  components: { HeaderClientView, FooterClientView },
  mixins: [MarginMixin],
  name: 'partner-program',
  data: () => ({
    mgm: null
  }),
  computed: {
    mailTo() {
      return `mailto:?subject=Polecam%20catering%20dietetyczny%20NTFY&body=Cze%C5%9B%C4%87%2C%20mam%20dla%20Ciebie%205%25%20rabatu%20na%20catering%20dietetyczny%2C%20z%20kt%C3%B3rego%20korzystam.%20Jest%20super!%0A%0AZarejestruj%20si%C4%99%20przez%20ten%20link%20${encodeURIComponent(
        this.getRefererUri()
      )}%20i%20zam%C3%B3w%20diet%C4%99%20z%20dostaw%C4%85.`
    }
  },
  async mounted() {
    await this.$store.dispatch(ActionTypes.FETCH_CLIENT_DATA)
    this.mgm = this.$store.getters.mgm
  },
  methods: {
    goToReferer() {
      this.$router.push({
        path: '/polecam/' + this.$store.getters.jwtDecoded.recommendedCode
      })
    },
    getOrigin() {
      return window.location.origin
    },
    getRefererUri() {
      return this.getOrigin() + '/polecam/' + this.mgm.code
    },
    shareMessenger() {
      window.open(
        'https://www.facebook.com/dialog/send?app_id=' +
          process.env.VUE_APP_FACEBOOK_APP_ID +
          '&display=popup&link=' +
          encodeURIComponent(this.getRefererUri()) +
          '&redirect_uri=' +
          encodeURIComponent(window.location.href),
        'messanger',
        'height=400,width=480'
      )
    },
    shareFB() {
      window.open(
        'https://www.facebook.com/dialog/feed?app_id=' +
          process.env.VUE_APP_FACEBOOK_APP_ID +
          '&ref=site&display=page&name=NTFY+catering+dietetyczny+z+wyborem+menu&caption=NTFY+-+Nice+To+Fit+You&description=Hello%21+Mam+dla+Ciebie+5%25+rabatu+na+catering+dietetyczny%2C+z+kt%C3%B3rego+korzystam.+Jest+super%21+%0A+++++++++++++U%C5%BCyj+kodu+mak379+i+przekonaj+si%C4%99+sam%21&link=' +
          encodeURIComponent(this.getRefererUri()),
        'fb',
        'height=600,width=600'
      )
    },
    copyToClipboard() {
      const tempField = document.createElement('textarea')
      document.body.appendChild(tempField)
      tempField.value = this.getRefererUri()
      tempField.select()
      document.execCommand('copy')
      document.body.removeChild(tempField)
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1280px;
}
.ppc_share {
  cursor: pointer !important;
}
</style>
