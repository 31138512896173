<template>
  <div class="page-layout">
    <header-client-view></header-client-view>
    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <Announcement v-if="items.length > 0" />
      <div class="container">
        <div class="calendar__alerts">
          <ntfy-new-app-msg v-if="showNewAppMsg"></ntfy-new-app-msg>
          <ntfy-feedback-msg v-if="showFeedbackMsg"></ntfy-feedback-msg>
          <ntfy-message-temporary v-if="showTempMsg"></ntfy-message-temporary>
        </div>

        <div class="plg-ni_calendar_Main" v-if="items.length > 0">
          <div>
            <div class="calendar_main">
              <div></div>
              <div>
                <div
                  class="row calendar_main-calendarWrapper plg-ni_calendar_Calendar"
                >
                  <div style="display: block">
                    <menu-planner-calendar
                      :items="items"
                      :selected-diet="selectedDiet"
                    ></menu-planner-calendar>
                  </div>
                  <div class="col-md-5 supportPlgContainer">
                    <div
                      class="calendar_main-calendarForm plg-ni_menu_planner_user"
                    >
                      <menu-planner-form :selected-diet="selectedDiet" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plg-ni_calendar_Main" v-else>
          <div>
            <div class="row center">
              <h3>Brak aktywnych diet</h3>
              <span @click="this.configureDiets"
                ><router-link
                  :to="{ name: routeCollection.OrderCreate }"
                  class="button-red mt-5"
                  style="max-width: 250px"
                  >Skonfiguruj dietę
                </router-link></span
              >
            </div>
          </div>
        </div>
      </div>

      <!--======== cookesBox ========-->
      <div class="cookseBox" id="cookseBox">
        <a onclick="cookieOff('ntfy', 1, 360);" class="cookesBox__close">
          <i class="fa fa-close"></i>
        </a>

        <p class="cookseBox__content">
          Ciasteczka to jedyna niedietetyczna rzecz u nas. Korzystamy z nich w
          celu świadczenia usług. Zmiana ustawień plików cookie jest możliwa w
          każdym momencie.
        </p>
      </div>
      <!--======== /END cookesBox ========-->
      <div class="dialog" style="display: none; opacity: 0"></div>
      <div
        class="dialog-content"
        style="display: none; opacity: 0; top: -100px; background: #ffe123"
      >
        <a href="javascript:void(0)" class="js-close-button pop-up__close">
          &nbsp;
        </a>

        <!-- content-box -->
        <div class="content-box content-box--mr-none"></div>
      </div>
    </main>
    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '../../components/HeaderClientView'
import MenuPlannerForm from '../../components/MenuPlannerForm'
import MenuPlannerCalendar from '../../components/MenuPlannerCalendar'
import FooterClientView from '../../components/FooterClientView'
import order from '../../services/api/order'
import * as actions from '../../store/actions_types'
import * as RouteCollection from '../../router/route_collection'
import NtfyNewAppMsg from '../../components/popup/NtfyNewAppMsg'
import MarginMixin from '../../helpers/mixins'
import NtfyFeedbackMsg from '@/components/popup/NtfyFeedbackMsg'
import NtfyMessageTemporary from '../../components/popup/NtfyMessageTemporary'
import Announcement from '@/components/Announcement/Announcement.vue'

export default {
  components: {
    Announcement,
    NtfyMessageTemporary,
    HeaderClientView,
    MenuPlannerForm,
    MenuPlannerCalendar,
    FooterClientView,
    NtfyNewAppMsg,
    NtfyFeedbackMsg
  },
  mixins: [MarginMixin],
  name: 'browser',
  data() {
    return {
      items: [],
      routeCollection: RouteCollection
    }
  },
  mounted() {
    this.load(1)
    // this.$gtm.enable(true)
  },
  computed: {
    showNewAppMsg() {
      return this.$date().isBetween(
        '2021-08-30 00:00:00',
        '2021-09-13 23:59:59'
      )
    },
    showFeedbackMsg() {
      return this.$date().isBetween(
        '2021-08-02 00:00:00',
        '2021-08-04 23:59:59'
      )
    },
    showTempMsg() {
      return this.$date().isBetween(
        '2024-05-27 00:00:00',
        '2024-05-30 23:59:59'
      )
    },
    diet() {
      let diet = null

      Object.values(this.items).some(item => {
        if (this.$store.state.diet.selected === item.id) {
          diet = item
          return true
        }
      })

      return diet
    },
    packageName() {
      if (this.diet) {
        return this.diet.package.name
      }

      return null
    },
    variantName() {
      if (this.diet) {
        return this.diet.defaultVariant.name
      }

      return null
    },
    basketName() {
      if (this.diet.defaultBasket) {
        return this.diet.defaultBasket.name
      }

      return null
    },
    mealTypes() {
      if (this.diet) {
        return this.diet.mealTypes
      }

      return null
    },
    selectedDiet() {
      return (
        this.$store.state.diet.selected &&
        this.items.find(item => item.id === this.$store.state.diet.selected)
      )
    }
  },
  methods: {
    configureDiets() {
      return ''
      // return window.dataLayer.push({ event: 'skonfiguruj-diete-home' })
    },
    selectDiet(diet) {
      this.$store.dispatch(actions.SELECT_DIET, diet)
    },
    formatDate(date) {
      return this.$date(date).format('YYYY-MM-DD')
    },
    load(page) {
      order.fetchDiets(page).then(
        async response => {
          this.items = response.data.items

          if (this.items.length) {
            await this.selectDiet(this.items[0].id)
          }
        },
        e => {
          this.handleError(e, false)
        }
      )
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1280px;
}

.calendar__alerts div:last-child {
  margin-bottom: 80px;
}
</style>
