import axios from '../../../interceptor'
import routes from '../routes'

export default {
  fetchAddresses() {
    return axios.get(routes.fetchAddresses)
  },
  getBagDelivery(bag) {
    return axios.get(routes.bagDelivery(bag))
  },
  patchBagDelivery(bag, data) {
    return axios.patch(routes.bagDelivery(bag), data)
  },
  createAddress(data) {
    return axios.post(routes.createAddress, data)
  },
  deleteAddress(id, errorCallback) {
    return axios.delete(routes.deleteAddress(id), {
      errorCallback
    })
  },
  addressHasPlannedDeliveries(id) {
    return axios.get(routes.addressHasPlannedDeliveries(id))
  },
  updateAddress(id, data) {
    return axios.patch(routes.updateAddress(id), data)
  },
  updateAddressForPlannedDeliveries(id, data) {
    return axios.patch(routes.updateAddressForPlannedDeliveries(id), data)
  },
  updateAddressForDateAndDiet(address, date, diet) {
    return axios.patch(routes.updateAddressForDateAndDiet(address, date, diet))
  },
  addressPackageLeavingOptions() {
    return axios.get(routes.addressPackageLeavingOptions)
  },
  addressUnderTheDoorTypeOptions() {
    return axios.get(routes.fetchAddressUnderTheDoorTypeOptions)
  }
}
