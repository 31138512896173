<template>
  <div class="diet-configuration__container">
    <div class="diet-configuration__content-wrapper">
      <h2 class="text-center mb-8">Wybierz domyślny wariant</h2>

      <div class="meals-grid">
        <div
          v-for="variant in variants"
          :key="variant.id"
          class="box-container"
        >
          <div
            :class="[
              'variant-container',
              selectedVariant && variant.id === selectedVariant.id
                ? 'selected-variant'
                : ''
            ]"
          >
            <div
              class="variant-container__info-section"
              @click="selectVariant(variant)"
            >
              <div>
                <img
                  :src="`/img/${
                    selectedVariant && variant.id === selectedVariant.id
                      ? 'checked.png'
                      : 'unchecked.png'
                  }`"
                  alt=""
                />
              </div>
              <h2 class="text-center">{{ variant.name }}</h2>
              <!--              <div>-->
              <!--                <img-->
              <!--                  :src="`/img/diet_variants/${variantImage(variant.id)}`"-->
              <!--                  alt=""-->
              <!--                />-->
              <!--              </div>-->
              <div>
                <img :src="imageLink(variant.image)" alt="" />
              </div>
              <div class="text-center">
                <div v-show="!showDescription.includes(variant.id)">
                  {{ variant.title }}
                </div>
                <div
                  v-show="showDescription.includes(variant.id)"
                  v-html="variant.clientDescription"
                ></div>
              </div>
            </div>

            <div
              class="info-section-switcher"
              @click="showVariantDescription(variant)"
            >
              <p>
                {{
                  showDescription.includes(variant.id)
                    ? 'wróć'
                    : 'więcej o diecie'
                }}
              </p>
              <img src="/img/arrow-black-circle.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="row">-->
  <!--    <div>-->
  <!--      <div class="row">-->
  <!--        <div class="col-xs-12">-->
  <!--          <h3>Wybierz domyślny wariant:</h3>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="new-variants">-->
  <!--        <ul class="boxes boxes&#45;&#45;grid">-->
  <!--          <template v-for="variant in getVariants">-->
  <!--            <li-->
  <!--              v-if="variant.name === 'greyblock'"-->
  <!--              :key="variant.id"-->
  <!--              class="boxes__item grey-box"-->
  <!--            >-->
  <!--              <div v-if="variant.position === 7">-->
  <!--                <p>-->
  <!--                  W każdej z naszych diet<span>znajdziesz dania z</span-->
  <!--                  ><span>wykluczeniami</span>-->
  <!--                </p>-->

  <!--                <div>-->
  <!--                  <img src="@/assets/svg/ntfy-ikony-vgan.svg" />-->
  <!--                  <p>#VGAN</p>-->
  <!--                  <p>dania roślinne</p>-->
  <!--                </div>-->

  <!--                <div>-->
  <!--                  <img src="@/assets/svg/ntfy-ikony-df.svg" />-->
  <!--                  <p>#DF</p>-->
  <!--                  <p>bez nabiału</p>-->
  <!--                </div>-->
  <!--              </div>-->

  <!--              <div v-if="variant.position === 9">-->
  <!--                <p>-->
  <!--                  Planując swoje codzienne<span>menu szukaj tych</span-->
  <!--                  ><span>oznaczeń</span>-->
  <!--                </p>-->

  <!--                <div>-->
  <!--                  <img src="@/assets/svg/ntfy-ikony-lg.svg" />-->
  <!--                  <p>#LG</p>-->
  <!--                  <p>mniej glutenu</p>-->
  <!--                </div>-->

  <!--                <div>-->
  <!--                  <img src="@/assets/svg/ntfy-ikony-lc.svg" />-->
  <!--                  <p>#LC</p>-->
  <!--                  <p>mniej węglowodanów</p>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </li>-->

  <!--            <li-->
  <!--              v-else-->
  <!--              :key="variant.id"-->
  <!--              class="boxes__item"-->
  <!--              :class="{-->
  <!--                description: showDescription.includes(variant.id),-->
  <!--                infobox: !showDescription.includes(variant.id),-->
  <!--                'active infobox&#45;&#45;active': selected === variant.id,-->
  <!--                'dietVariant-inactive inactive': isDisabledVariant(variant.id)-->
  <!--              }"-->
  <!--            >-->
  <!--              <div-->
  <!--                class="classic-desc"-->
  <!--                v-html="variant.clientDescription"-->
  <!--              ></div>-->
  <!--              <a class="desc-btn" @click="showVariantDescription(variant)"-->
  <!--                >wróć</a-->
  <!--              >-->
  <!--              <div class="infobox__img">-->
  <!--                <img :src="getVariantImage(variant)" />-->
  <!--              </div>-->
  <!--              <div class="boxes__content">-->
  <!--                <h4 class="infobox__title">-->
  <!--                  {{ variant.name }}-->
  <!--                  <div-->
  <!--                    class="bar"-->
  <!--                    @click="showVariantDescription(variant)"-->
  <!--                  ></div>-->
  <!--                </h4>-->
  <!--                <p class="infobox__text">{{ variant.title }}</p>-->
  <!--                <div-->
  <!--                  class="btn btn&#45;&#45;small"-->
  <!--                  :disabled="isDisabledVariant(variant.id)"-->
  <!--                  :id="'variant_' + variant.id"-->
  <!--                  @click="selected = variant.id"-->
  <!--                >-->
  <!--                  wybierz-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </li>-->
  <!--          </template>-->
  <!--        </ul>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import * as actionTypes from '../../store/actions_types'

export default {
  data() {
    return {
      showDescription: [],
      selectedVariant: null
    }
  },
  watch: {
    variants: {
      handler(newVariants) {
        if (newVariants.length > 0 && !this.selectedVariant) {
          // Check if selectedVariant is not set and variants are available
          const configVariant = this.$store.state.configurator.options.variants.find(
            el => el.id === this.$store.state.configurator.configurator.variant
          )

          this.selectedVariant = configVariant ?? newVariants[0]
        }
      },
      immediate: true // This ensures the handler runs immediately on component mount
    }
  },
  computed: {
    variants() {
      return this.$store.state.configurator.options.variants
    }
    // getVariants() {
    //   let newVariants = [...this.$store.state.configurator.options.variants]
    //   if (
    //     this.$store.state.configurator.options.showVariantHashtags &&
    //     newVariants.length > 0
    //   ) {
    //     newVariants.splice(newVariants.length - 1, 0, {
    //       id: 9998,
    //       position: 7,
    //       name: 'greyblock'
    //     })
    //     newVariants.push({ id: 9999, position: 9, name: 'greyblock' })
    //   }
    //
    //   return newVariants
    // }
  },
  methods: {
    selectVariant(variant) {
      this.$store.dispatch(actionTypes.CHANGE_CONFIGURATOR_VARIANT, variant.id)
      if (this.selectedVariant && this.selectedVariant.id === variant.id) {
        this.selectedVariant = null
      } else {
        this.selectedVariant = variant
      }
    },
    getBoxWith() {
      return 100 / this.$store.state.configurator.options.variants.length
    },
    // isDisabledVariant(variantId) {
    //   return this.$store.getters.getConfiguratorDisabledVariants.includes(
    //     variantId
    //   )
    // },
    // getVariantImage(variant) {
    //   if (typeof variant.image === 'undefined' || variant.image === null) {
    //     return 'https://www.freeiconspng.com/uploads/no-image-icon-6.png'
    //   }
    //
    //   return process.env.VUE_APP_IMAGE_DIR + variant.image
    // },
    showVariantDescription(variant) {
      if (this.showDescription.includes(variant.id)) {
        let index = this.showDescription.indexOf(variant.id)
        this.showDescription.splice(index, 1)
      } else {
        this.showDescription.push(variant.id)
      }
    },
    imageLink(imageName) {
      return `${process.env.VUE_APP_API_URL}/upload/photos/${imageName}`
    },
    variantImage(variantId) {
      switch (variantId) {
        case 22:
          return 'standard.png'
        case 23:
          return 'wege.png'
        case 24:
          return 'fit.png'
        case 25:
          return 'glutenfree.png'
        case 26:
          return 'vegefish.png'
        case 27:
          return 'lowig.png'
        default:
          return ''
      }
    }
  },
  name: 'variant'
}
</script>
