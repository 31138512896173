<template>
  <div></div>
</template>

<script>
import * as MutationTypes from '../store/mutations_types'
import * as RouteCollection from '../router/route_collection'

export default {
  name: 'RefererProgram',
  mounted() {
    if (typeof this.$route.params.code !== 'undefined') {
      this.$store.commit(
        MutationTypes.UPDATE_CONFIGURATOR_MGM_CODE,
        this.$route.params.code
      )
    }
    this.$store.commit(
      MutationTypes.UPDATE_REDIRECT_AFTER_LOGIN,
      RouteCollection.OrderCreate
    )
    this.$router.push({ name: RouteCollection.Register })
  }
}
</script>

<style scoped>
.container {
  max-width: 1280px;
}
</style>
