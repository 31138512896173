export const validateFields = (fields, ctx) => {
  const tempErrors = {};
  let hasErrors = false;

  for (const fieldName in fields) {
    const field = fields[fieldName];

    if (field.required && !field.value) {
      hasErrors = true;
      tempErrors[fieldName] = {
        1: ctx.$t("commonErrors.field_required", {
          fieldName: field.fieldName,
        }),
      };
    } else if (field.validator) {
      const error = field.validator(field.value, ctx);

      if (error !== undefined) {
        hasErrors = true;
        tempErrors[fieldName] = error;
      }
    }
  }
  if (hasErrors) {
    return tempErrors;
  }
  return undefined;
};

export const getEmailErrors = (email, ctx) => {
  const emailPattern = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  );
  const isValid = emailPattern.test(email);
  if (!isValid) {
    return { 1: ctx.$t("commonErrors.invalid_email") };
  }
  return undefined;
};

export const getPhoneErrors = (phone, ctx) => {
  const phonePattern = new RegExp("^\\d{3}-\\d{3}-\\d{3}$");
  const isValid = phonePattern.test(phone);
  if (!isValid) {
    return { 1: ctx.$t("commonErrors.invalid_phone") };
  }
  return undefined;
};
