import axios from '../../../interceptor'
import routes from '../routes'

export default {
  getMoneyboxHistory(page, perPage) {
    return axios.patch(routes.moneyboxHistory, {
      page: page,
      perPage: perPage
    })
  },
  fetchMoneyboxHistory(page, limit) {
    return axios.get(
      routes.fetchMoneyboxHistory + '?page=' + page + '&limit=' + limit
    )
  },
  withdrawal(data) {
    return axios.post(routes.moneyboxWithdrawal, data)
  },
  replenishment(data) {
    return axios.post(routes.moneyboxReplenishment, data)
  }
}
