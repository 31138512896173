<template>
  <div class="page-layout">
    <header-client-view></header-client-view>

    <main class="main" :class="{ 'expanded-navbar': addMarginToMain }">
      <div class="container">
        <!-- Cennik -->
        <diet-configurator
          :title="$t('KONFIGURACJA DIETY')"
          :button-title="$t('Dalej')"
          :create-order-on-click="true"
          :scroll-to-diet-length="redirectedFromLandingPage"
        ></diet-configurator>
      </div>
    </main>
    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderClientView from '../../components/HeaderClientView'
import FooterClientView from '../../components/FooterClientView'
import DietConfigurator from '../../components/DietConfigurator/DietConfigurator'
import * as RouteCollection from '@/router/route_collection'
import MarginMixin from '../../helpers/mixins'
import { REDIRECT_SOURCES } from '../../consts/redirectSources'

export default {
  components: { HeaderClientView, FooterClientView, DietConfigurator },
  mixins: [MarginMixin],
  name: 'create',
  mounted() {
    // this.$gtm.enable(true)
  },
  computed: {
    redirectedFromLandingPage() {
      return this.$route.params.source === REDIRECT_SOURCES.LANDING_PAGE
    }
  },
  beforeRouteLeave(to, from, next) {
    const isGoingToConfirm = to.name === RouteCollection.OrderConfirm
    const isGoingToMyData = to.name === RouteCollection.UserData
    const isGoingToOrderAddress = to.name === RouteCollection.OrderAddress
    if (isGoingToConfirm || isGoingToMyData || isGoingToOrderAddress) {
      next()
      return
    }
    const answer = window.confirm(
      'Czy na pewno chcesz opuścić stronę bez zapisania zmian?'
    )
    next(answer)
  }
}
</script>

<style scoped>
.container {
  max-width: 1280px;
}

.closer {
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: bold;
  z-index: 1000000000000;
}

.closer:hover {
  cursor: pointer;
}

#secondPopup {
  z-index: 100000;
}

#content {
  font-size: 15px;
  width: 60%;
  margin-top: 82px;
  margin-bottom: -110px;
  transition: margin 1s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  #content {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  #content {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #content {
    width: 100%;
  }
}

@media (min-width: 721px) and (max-width: 767px) {
  #content {
    width: 100%;
  }
}

@media (max-width: 720px) {
  #content {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .separate {
    min-height: 5vh;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .dietwithoutweekends {
    text-align: right;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .separate2 {
    min-height: 10vh;
  }
}

@media (max-width: 768px) {
  .first_delivery_popup_hide {
    margin-bottom: 48px;
  }
}

.popup_main_box {
  position: relative;
  background: #ff4068;
  background: radial-gradient(
    circle at top left,
    #ff4068,
    #ff4068 25%,
    #ff4068 25%,
    #ff4068 35%,
    #ff4068 35%,
    #ff4068
  );
  /*box-shadow: 2px 2px 20px 2px;*/
  margin-top: 20px;
  z-index: 100;
  padding: 1px 20px 20px 20px;
  margin-bottom: -272px;
}

.first_delivery_popup_hide {
  position: relative;
  margin-top: 20px;
  z-index: 100;
  padding: 1px 20px 20px 20px;
}

#popup_p {
  display: none;
  font-size: 14px;
  text-align: justify;
}

.popup_main_box:before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 70%;
  top: 30%;
  left: 0;
  box-shadow: -5px 12px 30px -15px;
  z-index: 50;
}

.popup_main_box:after {
  content: ' ';
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  box-shadow: 9px 2px 30px -15px;
  z-index: 50;
}

.popup_main_box h3,
.first_delivery_popup_hide h3 {
  margin-top: 2px;
  margin-left: 3.3em;
  margin-right: 0.6em;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: bold;
}

.icon_info {
  font-size: 1.7em;
  font-weight: 600;
  position: absolute;
  left: -10px;
  top: -10px;
  padding: 0.2em 0.5em 0.2em 0.5em;
  background: #ff4068;
  border: 10px solid #fff;
  border-radius: 100%;
}

.first_delivery_popup_hide p {
  margin-left: 28%;
  margin-top: 5%;
  font-weight: bold;
}

.hr {
  border-bottom: solid black 2px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 2.6em;
  margin-right: 1em;
}

.icon_info_hide:hover,
.p_info_hide:hover {
  cursor: pointer;
}

.blur {
  filter: blur(3px);
}

@media (max-width: 767px) {
  .blur2 {
    filter: blur(3px);
  }

  .popup_main_box {
    margin-bottom: -242px;
  }
}

@media (min-width: 769px) and (max-width: 1199px) {
  .popup_main_box {
    margin-bottom: -265px;
  }
}

@media (max-width: 1199px) {
  .blur3 {
    filter: blur(3px);
  }
}

.tableVariant.table-row:nth-child(n + 3) label {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dietVariant-inactive {
  background: #c4c4c4 !important;
  color: #8d8d8d;
  border-top: 3px solid #c4c4c4;
  border-right: 3px solid #c4c4c4;
  border-bottom: 3px solid #c4c4c4;
  border-left: 0;
}

.dietVariant-inactive:hover {
  cursor: default !important;
  opacity: 1 !important;
}

.div-inactive {
  cursor: default !important;
  border: none !important;
}

.div-inactive:hover {
  cursor: default !important;
  opacity: 1 !important;
}
</style>
