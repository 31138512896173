<template>
  <nav class="navbar__logged-out">
    <div
      style="
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <a :href="lpUrl"><img src="/img/logo_rs.png" style="height: 43px" /></a>
      <a :href="'tel:' + $t('menu.phone')">{{ $t('menu.phone') }}</a>
    </div>
    <!--    <ul class="menu">-->
    <!--      <li class="nav-logo">-->
    <!--        <a :href="lpUrl">-->
    <!--          <img src="/img/logo_rs.png" style="height: 43px"-->
    <!--        /></a>-->
    <!--      </li>-->
    <!--      <li :class="['link', { visible: showMenu }]">-->
    <!--        <a :href="lpUrl">{{ $t('menu.homepage') }}</a>-->
    <!--      </li>-->
    <!--      <li :class="['link', { visible: showMenu }]">-->
    <!--        <a :href="lpUrl + '/o-nas'">O nas</a>-->
    <!--      </li>-->
    <!--      <li :class="['link', { visible: showMenu }]">-->
    <!--        <a :href="lpUrl + '/diety'">Diety</a>-->
    <!--      </li>-->
    <!--      <li :class="['link', { visible: showMenu }]">-->
    <!--        <a :href="lpUrl + '/gdzie-jestesmy'">Gdzie jesteśmy?</a>-->
    <!--      </li>-->
    <!--      <li :class="['link', { visible: showMenu }]">-->
    <!--        <a :href="lpUrl + '/kategoria/blog'">Blog</a>-->
    <!--      </li>-->
    <!--      <li :class="['link', { visible: showMenu }]">-->
    <!--        <a :href="lpUrl + '/faq'">FAQ</a>-->
    <!--      </li>-->
    <!--      <li :class="['link', { visible: showMenu }]">-->
    <!--        <a :href="lpUrl + '/kalkulator-diet'">Kalkulator diet</a>-->
    <!--      </li>-->
    <!--      <li :class="['link', { visible: showMenu }]">-->
    <!--        <a :href="'tel:' + $t('menu.phone')">{{ $t('menu.phone') }}</a>-->
    <!--      </li>-->
    <!--      <li class="toggle" @click="showMenu = !showMenu">-->
    <!--        <div class="hamburger"></div>-->
    <!--      </li>-->
    <!--    </ul>-->
  </nav>
</template>

<script>
export default {
  name: 'HeaderIndexView',
  props: {
    fixed: {
      default: true
    }
  },
  data() {
    return {
      // visible: false,
      // menuCollapsed: false,
      lpUrl: this.getLpUrl(),
      showMenu: false
    }
  },
  // computed: {
  //   menuCollapsedDisplay: function () {
  //     return this.menuCollapsed ? 'block' : 'none'
  //   }
  // },
  methods: {
    // isVisible() {
    //   return this.fixed || this.visible
    // },
    getLpUrl() {
      return process.env.VUE_APP_LANDING_PAGE_URL
    }
  }
}
</script>
