<template>
  <div class="container">
    <div v-for="([component, show], index) in list" :key="component.name">
      <div class="announcement-wrapper" v-if="show()">
        <i class="fa fa-close i-close" @click="list.splice(index, 1)"></i>
        <component :is="component" />
      </div>
    </div>
  </div>
</template>

<script>
import AnnouncementDec2023 from '@/components/Announcement/AnnouncementDec2023.vue'

export default {
  name: 'Announcement',
  components: { AnnouncementDec2023 },
  data() {
    return {
      list: [
        [
          AnnouncementDec2023,
          () => {
            const currentDate = new Date()
            const endDate = new Date('2024-01-02')

            return currentDate <= endDate
          }
        ]
      ]
    }
  }
}
</script>

<style scoped>
.announcement-wrapper .i-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.announcement-wrapper {
  margin-bottom: 10px;
  position: relative;
  background-color: #ff4068;
  width: 100%;
  padding: 20px 30px;
  text-align: center;
}
</style>
