var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[(!_vm.isLoading())?_c('div',{key:_vm.state,staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-10 col-sm-offset-1"},[_c('div',{staticClass:"flex justify-content-space-between mb-8"},[_c('h2',[_vm._v(_vm._s(_vm.$t('My addresses')))]),_c('button',{staticClass:"btn btn-md btn-outline btn-xsmall",attrs:{"type":"submit"},on:{"click":function () { return this$1.$router.push({
                  name: _vm.UserAddress,
                  params: { step: 'main' }
                }); }}},[_vm._v(" "+_vm._s(_vm.$t('add_address'))+" ")])]),(_vm.addresses.length > 0)?[_c('div',{staticClass:"flex-container"},[_vm._l((_vm.addresses.entries()),function(ref){
                var i = ref[0];
                var address = ref[1];
return [_c('div',{key:i,staticClass:"flex-item-6 mb-8"},[_c('UserAddressCard',{key:address.id,attrs:{"address":address,"remount":_vm.remount}})],1)]})],2)]:_c('div',{staticClass:"col-sm-12 text-center empty-addresses-block"},[_c('h2',{staticClass:"mb-3"},[_vm._v("Ale tu pusto!")]),_c('div',{staticClass:"text-muted font-smaller"},[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('Add your address and order your meals.'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('Saved addresses will appear here.')))])])])],2)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }