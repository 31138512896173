<template>
  <div class="row">
    <div class="col-sm-12">
      <h2 class="title">
        <slot />
      </h2>
    </div>

    <InputFormField
      class="col-sm-12 mb-3"
      label="Street"
      name="street"
      required
      placeholder="Enter the street"
      v-model="model.street"
    />

    <Clearfix />

    <InputFormField
      class="col-sm-6 mb-3"
      label="Building number"
      name="buildingNr"
      placeholder="Enter a building number"
      required
      v-model="model.buildingNr"
    />

    <InputFormField
      class="col-sm-6 mb-3"
      label="Apartment number"
      name="placeNr"
      placeholder="Enter an apartment number"
      v-model="model.placeNr"
    />

    <div class="clearfix"></div>

    <InputFormField
      class="col-sm-6 mb-3"
      label="Postal code"
      name="postCode"
      placeholder="Enter a postal code"
      required
      pattern="\d{2}-\d{3}"
      :modify="postCodeModify"
      v-model="model.postCode"
    />

    <InputFormField
      class="col-sm-6 mb-3"
      label="City"
      name="city"
      placeholder="Enter a city"
      required
      v-model="model.city"
    />
  </div>
</template>

<script>
import InputFormField from '@/components/Address/InputFormField.vue'
import Clearfix from '@/components/Address/Clearfix.vue'

export default {
  name: 'UserAddress0',
  components: { Clearfix, InputFormField },
  props: {
    value: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean
    },
    isAlt: {
      type: Boolean
    }
  },
  data() {
    return {
      model: this.value
    }
  },
  methods: {
    postCodeModify(value) {
      return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{1})/, '$1-$2')
        .slice(0, 6)
    },
    input(property, value) {
      const model = {
        ...this.model,
        [property]: value
      }

      this.model = model
      this.$emit('input', model)
    }
  }
}
</script>
