import * as mutationTypes from '../mutations_types'
import * as actionTypes from '../actions_types'
import userApi from '../../services/api/user'

const actions = {
  [actionTypes.FETCH_MONEYBOX_HISTORY]({ commit, getters }, { page, perPage }) {
    return new Promise((resolve, reject) => {
      userApi.getMoneyboxHistory(page, perPage).then(
        successResponse => {
          commit(mutationTypes.MONEYBOX_HISTORY_UPDATE, successResponse.data)
          resolve()
        },
        failedResponse => {
          console.error(failedResponse)
        }
      )
    })
  },
  [actionTypes.FETCH_MONEYBOX_WITHDRAWAL]({ commit }, data) {
    return userApi.withdrawal(data).then(response => {
      commit(mutationTypes.MONEYBOX_HISTORY_UPDATE, response.data)

      return response.data
    })
  },
  [actionTypes.FETCH_MONEYBOX_REPLENISHMENT]({ commit }, data) {
    return userApi.replenishment(data).then(response => {
      commit(mutationTypes.MONEYBOX_HISTORY_UPDATE, response.data)

      return response.data
    })
  }
}

function initialState() {
  return {
    history: [],
    currentPage: 1,
    totalPages: 1,
    perPage: 20
  }
}

const state = initialState()

const getters = {
  getMoneyboxHistory: state => state.history,
  getCurrentPage: state => state.currentPage,
  getTotalPages: state => state.totalPages,
  getPerPage: state => state.perPage
}

const mutations = {
  [mutationTypes.MONEYBOX_HISTORY_UPDATE](state, data) {
    state.history = data.history
    state.totalPages = Math.ceil(data.totalRecords / state.perPage)
  },
  [mutationTypes.MONEYBOX_HISTORY_UPDATE_CURRENT_PAGE](state, data) {
    state.currentPage = data
  },
  [mutationTypes.RESET_MONEYBOX](state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
