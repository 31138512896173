import axios from '../../interceptor'
import routes from './routes'

export default {
  update(bagId, data) {
    return axios.post(routes.bagUpdate(bagId), { data: JSON.stringify(data) })
  },
  saveRating(bagId, data) {
    return axios.post(routes.bagRating(bagId), { data: JSON.stringify(data) })
  },
  changeDeliveryTime(bagId, date) {
    return axios.patch(routes.changeBagDeliveryTime(bagId, date))
  }
}
