import Vue from 'vue'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import duration from 'dayjs/plugin/duration'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import 'dayjs/locale/pl'

dayjs.locale('pl')
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)
dayjs.extend(duration)
dayjs.extend(isSameOrAfter)

Vue.prototype.$date = dayjs

export default dayjs
