<template>
  <div class="page-layout">
    <header-index-view :fixed="true"></header-index-view>

    <main class="main" style="height: 500px">
      <div class="container pop-up form-container">
        <h3 class="h4 h4--popup m-b-15" style="text-transform: none">
          Nice To Fit You
        </h3>
        <hr />

        <h3 class="h4 h4--popup m-b-15 title" style="font-weight: bold">
          Autoryzacja
        </h3>
        <hr />
      </div>
    </main>

    <footer-client-view></footer-client-view>
  </div>
</template>

<script>
import HeaderIndexView from '../../components/HeaderIndexView'
import FooterClientView from '../../components/FooterClientView'
import auth from '../../services/api/auth'
import router from '../../router'
import store from '../../store'
import * as RouteCollection from '../../router/route_collection'
import * as types from '../../store/mutations_types'

export default {
  components: { HeaderIndexView, FooterClientView },
  name: 'Authorize',
  props: {
    token: null,
    impersonatorToken: null
  },
  mounted() {
    store.commit(types.UPDATE_REFRESH_TOKEN, this.token)
    store.commit(types.UPDATE_IMPERSONATOR_TOKEN, this.impersonatorToken)

    auth.refresh().then(
      response => {
        router.push({ name: RouteCollection.CalendarBrowser })
      },
      e => {
        this.handleError(e, false)
      }
    )
  }
}
</script>
