import Vue from 'vue'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'

if (process.env.VUE_APP_SENTRY_DSN) {
  Raven.config(process.env.VUE_APP_SENTRY_DSN, {
    environment: process.env.VUE_APP_SENTRY_ENV
  })
    .addPlugin(RavenVue, Vue)
    .install()

  Vue.config.errorHandler = function (err, vm, info) {
    Raven.captureException(err)
  }
}

Vue.prototype.$raven = Raven

export default Raven
