<template>
  <div class="phone-input">
    <input
      name="phone"
      class="form-control form-control"
      :value="value"
      placeholder="Podaj numer telefonu"
      @input="input"
      type="text"
    />
  </div>
</template>

<script>
import FormField from '@/services/form/field'

export default {
  name: 'PhoneInput',
  props: {
    value: {
      type: [String, Object, Number],
      default: ''
    }
  },
  mounted() {
    let value = ''
    if (null !== this.value) {
      value = this.value.toString()
    }
    this.$emit('input', new FormField(value))
  },
  beforeDestroy() {
    this.$emit('input', new FormField(this.value.toString()))
  },
  methods: {
    modify(value) {
      return value.replace('+48', '').replace(/\D/g, '').slice(0, 9)
    },
    input(event) {
      let value = event.target.value
      if (this.modify) {
        value = this.modify(value)
      }
      this.$emit('input', this.value.assign(value))
    }
  }
}
</script>

<style scoped>
.phone-input input {
  padding-left: 80px;
}
.phone-input:after {
  content: '+48';
  margin-top: -46px;
  margin-left: 23px;
  width: 100px;
  height: 50px;
  display: block;
  font-size: 27px;
}
@media (max-width: 991px) {
  .phone-input input {
    padding-left: 52px;
  }
  .phone-input:after {
    font-size: 20px;
    margin-left: 14px;
    margin-top: -34px;
  }
}
</style>
