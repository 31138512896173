export const pathsWithLeavingWarning = [
  '/order/create',
  '/order/confirm',
  '/order/user-data',
  '/order/address/main',
  '/order/address/details',
  '/user/address/main',
  '/user/address/details',
  '/addresses/check'
]
